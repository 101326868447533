import React, { useEffect, useState } from "react";
import { useAppContext } from "../../Context/AppContext";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { useLocation } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/svg/ArrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../assets/svg/ArrowLeft.svg";
import { ReactComponent as FilterIcon } from "../../assets/svg/filter.svg";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import Select from "react-select";
import { Button, PageButton } from "../shared/Button";
import { SortIcon, SortUpIcon, SortDownIcon } from "../shared/Icons";
import { classNames } from "../shared/Utils";

function Table({
  columns,
  data,
  onClick,
  initialState,
  setLimit,
  limit,
  setOffset,
  count,
  setSortBy,
  setFilterBy,
  stopSortFilterManually,
}) {
  const context = useAppContext();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const { pathname } = useLocation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    gotoPage,
    pageCount,
    state: { sortBy, pageIndex, filters },
  } = useTable(
    {
      columns,
      data,
      initialState,
      autoResetPage: false,
      pageCount: Math.ceil(count / limit),
      manualPagination: true,
      manualSortBy: stopSortFilterManually ? true : false,
      manualFilters: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setOffset(pageIndex * limit);
    if (
      pathname.split("/")[2] === "users" ||
      pathname.split("/")[2] === "users-profile"
    ) {
      localStorage.setItem("PAGE_KEY", pageIndex);
    } else {
      localStorage.clear();
    }
  }, [pageIndex, pathname, limit, setOffset]);
  useEffect(() => {
    if (context.keyword.length || filters?.length || sortBy?.length)
      gotoPage(0);
  }, [context.keyword, filters, sortBy]);

  useEffect(() => {
    if (stopSortFilterManually) setSortBy(sortBy);
  }, [sortBy, stopSortFilterManually]);
  useEffect(() => {
    if (stopSortFilterManually) setFilterBy(filters);
  }, [filters, stopSortFilterManually]);
  return (
    <>
      <div className="-mt-4 flex flex-row justify-start items-start gap-2  -mt-8 mb-4">
        <div className="flex flex-row items-center gap-2">
          {/* <span className="text-main-text text-base font-bold">Filter:</span> */}
          <div
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
            style={{
              backgroundColor: isFiltersOpen
                ? "rgb(15,40,55)"
                : "rgb(236,239,239)",
              color: isFiltersOpen ? "#fff" : "",
            }}
            className="cursor-pointer min-w-[40px] min-h-[40px] w-[40px] h-[40px] px-2 py-1 rounded-[4px]"
          >
            <FilterIcon className="w-full h-full" />
          </div>
        </div>
        <div
          className={classNames(
            "flex-row flex-wrap items-center gap-4 px-4 py-3 rounded-md bg-white shadow-table-head-dark -mt-6",
            isFiltersOpen ? "flex" : "hidden"
          )}
        >
          <span className="text-main-text text-base font-bold">Filters:</span>
          <div className="flex flex-row flex-wrap items-center gap-2">
            {headerGroups?.map((headerGroup) =>
              headerGroup?.headers?.map((column) =>
                column?.Filter ? (
                  <div key={column?.id}>{column?.render("Filter")}</div>
                ) : null
              )
            )}
          </div>
          <div
            onClick={() => setAllFilters([])}
            className="flex flew-row flex-nowrap justify-center items-center gap-1 px-2 py-1 text-white text-xs font-bold bg-primary-four hover:bg-primary-four/70 rounded-full cursor-pointer"
          >
            Clear <Close className="w-[12px] h-[12px]" />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="align-middle inline-block min-w-full">
            <div className="overflow-hidden">
              <table
                {...getTableProps()}
                className="px-2 pb-10 min-w-full bg-transparent border-separate border-spacing-y-2 custom-table"
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          scope="col"
                          className="px-2 text-center text-xs font-bold"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="whitespace-nowrap flex items-center justify-center px-6 py-4 bg-white rounded-[5px] shadow-table-head-dark group">
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 ml-2" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 ml-2" />
                                )
                              ) : column.canSort ? (
                                <SortIcon className="w-4 h-4 ml-2 opacity-100" />
                              ) : (
                                <span></span>
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-white">
                  {page.map((row, i) => {
                    prepareRow(row);
                    let cc = "";
                    if (context.mainColor === "#FFB65E") {
                      cc =
                        " hover:bg-primary-two hover:shadow-table-line-hovered-two";
                    } else if (context.mainColor === "#6E1946") {
                      cc =
                        " hover:bg-primary-three hover:shadow-table-line-hovered-three";
                    } else if (context.mainColor === "#0F2837") {
                      cc =
                        " hover:bg-primary-four hover:shadow-table-line-hovered-four";
                    } else {
                      cc = " hover:bg-primary-one";
                    }
                    const style =
                      "group even:bg-table-line-two odd:bg-table-line rounded-[4px] border-none cursor-pointer hover:text-white" +
                      cc;
                    return (
                      <tr
                        onClick={() => onClick(row.original)}
                        className={style}
                        {...row?.getRowProps()}
                      >
                        {row?.cells?.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="py-2 px-2 text-center mx-2 text-xs font-bold whitespace-nowrap"
                              role="cell"
                            >
                              {cell.column.Cell.name === "defaultRenderer" ? (
                                // text render
                                <div className="text-xs text-center font-bold px-4 py-2">
                                  {cell.render("Cell")}
                                </div>
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 flex items-center justify-between">
        <div className="flex-1 flex justify-between sm:hidden">
          <Button
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            Previous
          </Button>
          <Button
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            Next
          </Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex gap-x-2 items-baseline">
            <div className="shadow-dark rounded-lg">
              <Select
                className="text-xs"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: 0,
                    boxShadow: "none",
                  }),
                }}
                isSearchable={false}
                defaultValue={
                  localStorage.getItem("PAGE_LIMIT")
                    ? {
                        value: localStorage.getItem("PAGE_LIMIT"),
                        label: `Show ${localStorage.getItem(
                          "PAGE_LIMIT"
                        )} results`,
                      }
                    : { value: 10, label: "Show 10 results" }
                }
                onChange={(e) => {
                  setPageSize(e.value);
                  setLimit(e.value);
                  localStorage.setItem("PAGE_LIMIT", e.value);
                }}
                options={[
                  { value: 5, label: "Show 5 results" },
                  { value: 10, label: "Show 10 results" },
                  { value: 20, label: "Show 20 results" },
                ]}
              />
            </div>
          </div>
          <div>
            <span className="text-sm text-main-text">
              <span className="font-medium">{pageIndex}</span> of{" "}
              <span className="font-medium">{pageCount - 1}</span>
            </span>
            <nav
              className="relative ml-2 z-0 inline-flex rounded-md space-x-1"
              aria-label="Pagination"
            >
              <PageButton
                className={canPreviousPage ? "opacity-100" : "opacity-50"}
                onClick={() => {
                  previousPage();
                  localStorage.setItem("PAGE_KEY", pageIndex);
                }}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ArrowLeft className="h-4 w-3" />
              </PageButton>
              <PageButton
                className={canNextPage ? "opacity-100" : "opacity-50"}
                onClick={() => {
                  nextPage();
                  localStorage.setItem("PAGE_KEY", pageIndex);
                }}
                disabled={!canNextPage}
              >
                <span className="sr-only">Next</span>
                <ArrowRight className="h-4 w-3" />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Table;
