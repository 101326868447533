import { Loading } from "../../../components/Loading";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { changeOverlayState } from "../../../store/app/overlaySlice";
import TransitionOverlay from "../../../components/transitionOverlay/TransitionOverlay";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";

function LoadingPage() {
  const [finishloading, setFinishloading] = useState(false);
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        changeOverlayState({
          isActive: true,
        })
      );
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (searchParam.get("token"))
      Cookies.set("token", searchParam.get("token"));
  }, [searchParam.get("token")]);

  useEffect(() => {
    setTimeout(() => {
      if (searchParam.get("to") === "admin") {
        return navigate("/dashboard/home", { replace: true });
      } else {
        return navigate("/home", { replace: true });
      }
    }, 3800);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFinishloading(true);
    }, 3500);
  }, [finishloading]);

  return (
    <div className=" w-screen bg-white  h-screen   flex justify-center items-center">
      <TransitionOverlay style={{ bottom: 0, right: 0 }} />
      {!finishloading && <Loading />}
    </div>
  );
}

export default LoadingPage;