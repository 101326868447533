import { createPortal } from "react-dom";

export default function FormModal({ children, showModal, setShowModal }) {
  if (!showModal) return null;
  return createPortal(
    <>
      <div
        onClick={() => setShowModal(false)}
        className="opacity-25 fixed w-screen h-screen inset-0 z-50 bg-black bg-modal-bg "
      />
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 z-50 outline-none focus:outline-none">
        <div className="relative w-[590px] z-500  my-6 mx-auto">
          {/*content*/}
          <div className="border-0 p-[60px] rounded-[10px] shadow-dark-2 relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {children}
          </div>
        </div>
      </div>
    </>,
    document.getElementById("root-portals")
  );
}
