import { useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import { ReactComponent as Polygon } from "../../assets/svg/polygon.svg";
import laptop from "../../assets/images/laptop.png";
import prevBadge from "../../assets/images/hlel.png";
import goldBadge from "../../assets/images/silverHlel.png";
import menTrophy from "../../assets/images/men-trophy.png";
import badgeImg from "../../assets/images/badge.png";
import axios from "axios";
import { ReactComponent as LinkIcon } from "../../assets/svg/link.svg";

import blueBadge from "../../assets/images/blueBadge.png";
import peopleRushing from "../../assets/images/people-rushing.png";
import successCup from "../../assets/images/success-cup.png";
import SuccessNotif from "../../components/SuccessNotif";
import NoDataPsst from "../../components/NoDataPsst";
import { classNames } from "../../components/shared/Utils";
import avatar from "../../assets/images/avatar.png";
import avatar2 from "../../assets/images/avatar2.png";
import { ReactComponent as Facebook } from "../../assets/svg/facebook.svg";
import bottomLine from "../../assets/images/bottomLine.png";
import { toast } from "react-toastify";

import { useScreenshot } from "use-react-screenshot";
import { FacebookShareButton } from "react-share";
import { Loading } from "../../components/Loading";
import {
  useEventsQuery,
  useStatsQuery,
  useTopUsersRanksQuery,
  useActivitiesQuery,
  useAddPointsMutation,
} from "../../store/user/userApi";

function UserPannel() {
  const appId = "1456678281530149";
  const appSecret = "063958c5d53258df46132c4b47c19b5a";
  const context = useAppContext();
  const formatDate = (dateStr) => {
    const date = dateStr.split(" ")[0];
    const [year, month, day] = date.split("/");
    let newDate = `${month}/${year}/${day}`;
    return newDate;
  };
  const {
    data: userStats,
    isLoading: statLoading,
    refetch: refetchStats,
  } = useStatsQuery();
  const { data: userEvents, isLoading: eventsLoading } = useEventsQuery();
  const [addPoints, { isSuccess, isError, error, isLoading }] =
    useAddPointsMutation();
  const {
    data: topUsers,
    isLoading: usersLoading,
    refetch: refetchTopUsers,
  } = useTopUsersRanksQuery();
  const { data: userActivities } = useActivitiesQuery();
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(function () {
      isMessageVisible && setIsMessageVisible(false);
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isMessageVisible]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchTopUsers();
      refetchStats();
    }, 300000);
    return () => clearInterval(interval);
  }, []);
  const [shareCount, setShareCount] = useState();

  const [dataUrl, setDataUrl] = useState("");
  console.log(shareCount);
  //extract share facebook post count to send to backend
  const getFacebookShareCount = () => {
    const endpoint = `https://graph.facebook.com/?id=${dataUrl}&fields=engagement&access_token=${appId}|${appSecret}`;
    try {
      axios.get(endpoint).then(function (resp) {
        return setShareCount(resp?.data?.engagement?.share_count);
      });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });
  const ref = createRef(null);
  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
  };

  useEffect(() => {
    if (ref.current) takeScreenShot(ref.current).then(download);
  }, [takeScreenShot, ref]);

  useEffect(() => {
    if (image) {
      var file = new File([image], "fileName.png", {
        type: "image/png",
        lastModified: new Date(),
      });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "nehtah");

      axios
        .post(
          "https://api.cloudinary.com/v1_1/dq1i1g9th/image/upload",
          formData
        )
        .then((res) => {
          setDataUrl(res.data.secure_url);
        })
        .catch((err) => {
          alert("error");
        });
    }
  }, [image]);
  useEffect(() => {
    if (shareCount >= 0) {
      axios
        .post("https://core.verse-stg.tam.run/gs/api/add_points", {
          email: userStats?.info && userStats?.info?.email,
          token: "gvtjLF5y",
          code: "b0deOsC0",
        })
        .then((res) => res.json())
        .catch((err) => {
          toast.error(err?.response?.data?.msg);
        });
    }
  }, [shareCount]);
  if (statLoading || usersLoading || eventsLoading) {
    return <Loading />;
  }
  return (
    <div className="w-full h-full py-10 px-6">
      {isMessageVisible && (
        <SuccessNotif
          setIsMessageVisible={setIsMessageVisible}
          message="You just earned 5 points for logging in after a few days"
        />
      )}
      <div className="flex flex-row items-start mb-8">
        <img
          src={
            userStats && userStats?.current_badge
              ? userStats?.current_badge?.icon
              : "https://i.postimg.cc/QdJbgGXP/silver-badge-2.png"
          }
          className="mr-4 w-[46px] h-[57px]"
          alt="badge"
        />
        <div>
          <p className="font-bold text-base lg:text-2xl font">
            Welcome Back,{" "}
            <span style={{ color: context.mainColor }}>
              {userStats?.info && userStats?.info?.first_name
                ? userStats?.info?.first_name
                : userStats?.info?.username}
              !
            </span>
          </p>
          <p className="text-xs lg:text-tiny">
            It is good to see you again!! Here is a quick update on your profile
          </p>
        </div>
      </div>

      <div className="flex flex-row flex-wrap 3lg:flex-nowrap gap-5">
        <div className="flex-auto min-h-[226px] h-fit flex flex-col-reverse sm:flex-row justify-between rounded-[4px] bg-[#E9E7FA] shadow-brand">
          <div className="w-full sm:w-[60%] max-w-[400px] px-8 py-6">
            <p className="text-xl font-bold mb-5">
              Have you heard about our upcoming Mega-Event?
            </p>
            <p className="text-tiny mb-5">
              This is a sample of a generated text that can be used in this
              specific placeholder in the future..
            </p>
            <Link
              to={"/home/events"}
              className="block w-fit h-fit px-4 py-3 text-xs text-white font-bold rounded-[4px] shadow-orange-shadow bg-gradient-side-nav"
            >
              Discover More
            </Link>
          </div>
          <div className="w-full sm:max-w-[230px] h-[190px] mt-6 mb-3 mx-8 sm:mx-3">
            <img src={laptop} className="w-full h-full" alt="laptop" />
          </div>
        </div>

        <div className="flex-auto relative flex flex-col justify-center p-5 min-w-[190px] min-h-[226px] max-w-[300px] rounded-[4px] bg-[#5E45FF] shadow-brand">
          <img
            className={classNames(
              "w-[110px] h-[110px] absolute top-[26px] right-0",
              userStats?.number_of_badges > 0 ? "opacity-100" : "opacity-50"
            )}
            src={menTrophy}
            alt="trophy"
          />
          <div>
            <p className="text-lg font-bold text-white">
              You
              <br />
              {userStats?.number_of_badges > 0 ? "have" : "haven't"}
              <br />
              earned
              <br />
              {userStats?.number_of_badges > 0 ? (
                <>
                  <span className="text-3xl text-[#FF9619]">
                    {userStats?.number_of_badges}
                  </span>
                  <br />
                  Badges!
                </>
              ) : (
                <>
                  a badge
                  <br />
                  yet..
                </>
              )}
            </p>
            {userStats?.number_of_badges > 0 ? (
              <p className="text-xs text-[#FF9619]">
                Hooray! Way to go {userStats?.info.first_name}!
              </p>
            ) : (
              <p className="text-xs text-[#FF9619]">
                Go ahead and join your first event!
              </p>
            )}
          </div>
        </div>

        <div className="flex-auto relative flex flex-col justify-center p-5 min-w-[190px] min-h-[226px] max-w-[300px] rounded-[4px] bg-[#0028B3] shadow-brand">
          <img
            className={classNames(
              "w-[100px] h-[100px] absolute top-[18px] right-2",
              userStats?.number_of_badges > 0 ? "opacity-100" : "opacity-50"
            )}
            src={peopleRushing}
            alt="people rushing"
          />
          <div>
            <p className="text-lg font-bold text-white">
              You
              <br />
              {userStats?.info && userStats?.info?.points > 0
                ? "have"
                : "haven't"}
              <br />
              earned
              <br />
              {userStats?.info && userStats?.info?.points > 0 ? (
                <>
                  <span className="text-3xl text-[#FAC39B]">
                    {userStats?.info?.points}
                  </span>
                  <br />
                  Points!
                </>
              ) : (
                <>
                  a points
                  <br />
                  yet..
                </>
              )}
            </p>
            {userStats?.points_earned > 0 ? (
              <p className="text-xs text-[#FAC39B]">
                Redeem and claim your rewards!
              </p>
            ) : (
              <p className="text-xs text-[#FAC39B]">
                Go ahead and join your first event!
              </p>
            )}
          </div>
        </div>

        <div className="flex-auto relative flex flex-col justify-center p-5 min-w-[190px] min-h-[226px] max-w-[300px] rounded-[4px] bg-[#FDB236] shadow-orange-shadow">
          <img
            className={classNames(
              "w-[114px] h-[129px] absolute top-[18px] right-0",
              userStats?.number_of_rewards > 0 ? "opacity-100" : "opacity-50"
            )}
            src={successCup}
            alt="success cup"
          />
          <div>
            <p className="text-lg font-bold text-white">
              You
              <br />
              {userStats?.number_of_rewards > 0 ? "have" : "haven't"}
              <br />
              {userStats?.number_of_rewards > 0 ? "won" : "won a"}
              <br />
              {userStats?.number_of_rewards > 0 ? (
                <>
                  <span className="text-3xl text-main-text">
                    {userStats?.number_of_rewards}
                  </span>
                  <br />
                  Rewards
                </>
              ) : (
                <>
                  a reward
                  <br />
                  yet..
                </>
              )}
            </p>
            {userStats?.number_of_rewards >= 10 ? (
              <p className="text-xs text-main-text">
                Keep the good work {userStats?.info.first_name}!
              </p>
            ) : (
              <p className="text-xs text-main-text max-w-[170px]">
                Earn more points to be able to exchange them for rewards
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="w-full h-[1px] bg-[rgba(207,212,215,0.3)] my-10"></div>
      {topUsers?.top_users_ranks.length > 0 ? (
        <div>
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            Leaderboard
          </p>
          <div className="flex-1 w-full min-h-[300px] flex flex-col 2lg:flex-row rounded-[10px] bg-white ">
            <div className="flex-1 flex-col  w-full 2lg:w-[50%] flex items-start mt-4">
              <p className="text-tiny lg:text-base font-bold mb-[20px]">
                Top Performer
              </p>
              <section className="leaderBoard w-full h-full px-4 1sm:px-6 md:px-10 py-5 flex flex-col-reverse 1sm:flex-row justify-around items-center gap-4 xl:gap-10 rounded-[10px] bg-white bg-no-repeat bg-leaderBoard max-h-[271px] shadow-dark">
                {" "}
                <div className="flex flex-row items-center">
                  <div className="w-[114px] h-[114px] relative">
                    <img
                      src={userStats?.info.icon || avatar2}
                      alt=""
                      className="h-full w-full rounded-full"
                    />
                    <img
                      src={userStats?.current_tier?.icon || blueBadge}
                      alt="prev badge"
                      className="absolute w-11 h-11 bottom-[-20px] right-1/2 translate-x-1/2 rounded-full bg-white"
                    />
                  </div>
                  <div className="ml-4 xl:ml-10">
                    {/* <p className="text-base font-bold">Leaderboard</p> */}
                    <p className="text-tiny">Your Rank and Points</p>
                    {/* <p className="text-tiny">Your current placement is:</p> */}
                    <p className="text-2xl font-bold">
                      {userStats?.info?.first_name || userStats?.info?.last_name
                        ? userStats?.info?.first_name +
                          " " +
                          userStats?.info?.last_name
                        : userStats?.info?.username}
                    </p>
                    <div className="text-lg font-bold text-[#5E45FF]">
                      You have {userStats && userStats.info.points} points{" "}
                    </div>
                    <p className="text-lg">
                      You are in the{" "}
                      <span className="font-bold">
                        {userStats && userStats.user_rank}
                        {userStats?.user_rank === 1
                          ? "st "
                          : userStats?.user_rank === 2
                          ? "nd "
                          : userStats?.user_rank === 3
                          ? "rd "
                          : "th "}
                      </span>
                      rank
                    </p>
                  </div>
                </div>
                <div className="flex flex-row flex-nowrap items-end">
                  {topUsers?.top_users_ranks?.length > 0 && (
                    <>
                      <div className="z-30 flex flex-col items-center">
                        <div className="flex flex-col justify-between items-center py-2 w-[5.3125rem] h-[4.375rem] trapezoid-clip-path bg-gradient-to-t from-[#5E45FF26] to-transparent">
                          <img
                            className="rounded-full w-9 h-9"
                            src={topUsers.top_users_ranks[0]["icon"] || avatar}
                            alt="user-profile"
                          />
                          <p className="font-bold text-[10px]">
                            {topUsers.top_users_ranks[0]["first_name"]}
                          </p>
                        </div>
                        <div
                          // style={{ backgroundColor: getBarColor().bar1 }}
                          className="p-1 text-main-text text-center shadow-dark-6 rounded-[4px] rounded-br-[0px] w-[4.6875rem] min-h-[10.0625rem] bg-[#5E45FF]"
                        >
                          <span className="text-[10px] font-bold rounded-[2px] px-[16px] py-[1px] bg-[#BBB1FB]">
                            1st
                          </span>
                          <p className="text-white text-sm mt-1">
                            {topUsers.top_users_ranks[0]["points"]} pts
                          </p>
                        </div>
                      </div>
                      <div className="ml-[-12px] z-20 flex flex-col items-center">
                        <div className="flex flex-col justify-between items-center py-2 w-[85px] h-[70px]">
                          <img
                            className="rounded-full w-9 h-9"
                            src={topUsers.top_users_ranks[1]["icon"] || avatar}
                            alt="user-profile"
                          />
                          <p className="font-bold text-[10px]">
                            {topUsers.top_users_ranks[1]["first_name"]}
                          </p>
                        </div>
                        <div className="p-1 text-center shadow-dark-6 rounded-tr-[4px] w-[75px] min-h-[132px] bg-[#7D69FE]">
                          <span className="text-[10px] font-bold rounded-[2px] px-[16px] py-[1px] bg-[#BBB1FB]">
                            2nd
                          </span>
                          <p className="text-white text-sm mt-1">
                            {topUsers.top_users_ranks[1]["points"]}pts
                          </p>
                        </div>
                      </div>
                      <div className="ml-[-12px] z-0 flex flex-col items-center">
                        <div className="flex flex-col justify-between items-center py-2 w-[85px] h-[70px]">
                          <img
                            className="rounded-full w-9 h-9"
                            src={topUsers.top_users_ranks[2]["icon"] || avatar}
                            alt="user-profile"
                          />
                          <p className="font-bold text-[10px]">
                            {topUsers.top_users_ranks[2]["first_name"]}
                          </p>
                        </div>
                        <div className="bg-[#AC9FFC] p-1 text-center rounded-r-[4px] w-[75px] min-h-[120px]">
                          <span className="text-[10px] font-bold rounded-[2px] px-[16px] py-[1px] bg-[#5E45FF] text-white">
                            3rd
                          </span>
                          <p className="text-white text-sm mt-1">
                            {topUsers.top_users_ranks[2]["points"]}pts
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </section>
            </div>

            <div className="2lg:flex-1 w-full 2lg:w-[50%] 2lg:min-h-[300px] h-full pt-4 overflow-hidden flex flex-col rounded-bl-[10px] rounded-br-[10px] 2lg:rounded-bl-[0px] 2lg:rounded-r-[10px] bg-white">
              <p className="text-tiny lg:text-base font-bold pl-[28px] mb-[10px]">
                Top Users
              </p>
              <table className="no-border-table">
                <thead>
                  <tr>
                    <th className="w-[10%]"></th>
                    <th className="w-[10%]"></th>
                    <th className="w-[65%]"></th>
                    <th className="w-[10%]"></th>
                  </tr>
                </thead>
                <tbody>
                  {topUsers &&
                    topUsers.top_users_ranks.slice(3).map((el, index) => (
                      <>
                        <tr
                          key={index}
                          className={
                            el.id === userStats?.info?.id
                              ? "bg-gradient-primary !text-white"
                              : ""
                          }
                        >
                          <td
                            style={
                              el.id === userStats?.id
                                ? { color: "#ffffff" }
                                : { color: "#6F7E87" }
                            }
                            className="text-center text-xs font-bold pl-6 py-[10px]"
                          >
                            {index + 4}th
                          </td>
                          <td className="py-[10px]">
                            <img
                              className="rounded-full w-6 h-6 lg:w-8 lg:h-8 mx-auto"
                              src={el.icon || avatar}
                              alt="user-profile"
                            />
                          </td>
                          <td className="text-tiny font-bold py-[10px]">
                            {el.first_name || el.last_name
                              ? ` ${el.first_name}  ${el.last_name}`
                              : el.username}
                          </td>
                          <td
                            style={
                              el.id === userStats?.id
                                ? { color: "#ffffff" }
                                : { color: "#6F7E87" }
                            }
                            className="text-right text-xs font-bold pr-4 py-[10px]"
                          >
                            {el.points}pts
                          </td>
                        </tr>
                        {!(index === topUsers.top_users_ranks.length - 1) && (
                          <tr>
                            <td>
                              <div className="w-[calc(100%-14px)] h-[1px] ml-[16px] bg-[rgb(207,212,215,0.3)]"></div>
                            </td>
                            <td>
                              <div className="w-[calc(100%+2px)] h-[1px] bg-[rgb(207,212,215,0.3)]"></div>
                            </td>
                            <td>
                              <div className="w-[calc(100%+2px)] h-[1px] bg-[rgb(207,212,215,0.3)]"></div>
                            </td>
                            <td>
                              <div className="w-[calc(100%-14px)] h-[1px] mr-[16px] bg-[rgb(207,212,215,0.3)]"></div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  {/* <tr
                  style={{
                    backgroundColor: context.mainColor,
                    color:
                      context.mainColor === "#6E1946" ||
                      context.mainColor === "#0f2837"
                        ? "#ffffff"
                        : "#0f2837",
                  }}
                >
                <td className="text-center text-xs text-main-text font-bold pl-6 py-[10px]">
                  {user && user.rank}th
                </td>
                <td className="py-[10px]">
                  <img
                    className="rounded-full min-w-[24px] min-h-[24px] w-[24px] h-[24px] lg:w-[32px] lg:h-8 mx-auto"
                    src={user && user.profile_image}
                    alt="user-profile"
                  />
                </td>
                <td className="text-tiny font-bold py-[10px]">
                  {user && user.firstname + " " + user.lastname}
                </td>
                <td className="text-right text-xs text-main-text font-bold pr-4 py-[10px]">
                  {user && user.points}pts
                </td>
              </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-row flex-wrap mx-[-10px]">
          <div className="w-[100%] 2lg:w-[50%] px-[10px] mb-[20px] flex flex-col justify-between">
            <p className="text-tiny lg:text-base font-bold mb-[20px]">
              Leaderboard
            </p>
            <NoDataPsst
              mainText={"The leaderboard is not active yet"}
              hasBackground={true}
              secondText={
                "Earn more points so that we can calculate your ranking.."
              }
            />
          </div>
          <div className="w-[100%] 2lg:w-[50%] px-[10px] mb-[20px]">
            <p className="text-tiny lg:text-base font-bold mb-[20px]">
              All Users
            </p>
            <NoDataPsst
              mainText={"Once we have more data we will display it here"}
              secondText={
                "Until then, keep on thriving and keep on earning more points!"
              }
            />
          </div>
        </div>
      )}

      <div className="w-full h-[1px] bg-[rgba(207,212,215,0.3)] my-10"></div>

      <div className="flex flex-row flex-wrap mx-[-10px]">
        <div className="w-[100%] 3lg:w-[50%] px-[10px] mb-[20px] flex flex-col justify-between">
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            Points To Your Upcoming Tier
          </p>
          {userStats && userStats?.next_tier !== null ? (
            <div className="flex-1 w-full min-h-[300px] p-5 rounded-[10px] bg-white shadow-dark">
              <p className="text-xl font-bold mb-2 text-primary-one">
                Are you ready to earn your next Tier?
              </p>
              <p className="text-tiny mb-4">
                You are close to unlocking and earning your next Tier! Only a
                few points are separating you from your next achievement.. Here
                is how to earn them:
              </p>
              <div className="flex flex-col 1sm:flex-row gap-5 items-center">
                <div className="flex-1">
                  <div className="w-fit mt-5 1sm:mt-0 flex flex-col items-center justify-between">
                    <div className="flex flex-row flex-nowrap">
                      <div className="flex flex-col justify-center items-center gap-[8px]">
                        <div className="w-[85px] h-[100px]">
                          <img
                            src={userStats?.current_tier?.icon || prevBadge}
                            className="w-full h-full"
                            alt="current Tier"
                          />
                        </div>
                        <p className="text-tiny font-bold text-[#8C7A79]">
                          Tier {userStats?.current_tier?.tier_name || "A"}
                        </p>
                      </div>
                      <div className="w-[48px] h-[90px] ml-[28px] mr-[20px] mt-[4px]">
                        <Polygon className="w-full h-full" />
                      </div>
                      <div className="flex flex-col justify-center items-center gap-[8px]">
                        <div className="w-[85px] h-[100px]">
                          <img
                            src={userStats?.next_tier?.icon || goldBadge}
                            className="w-full h-full"
                            alt="next Tier"
                          />
                        </div>
                        <p className="text-tiny font-bold text-[#6B9BAA]">
                          Tier {userStats?.next_tier?.tier_name || "B"}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col gap-1 items-center text-[10px] mb-[16px]">
                      <span className="text-primary-one text-[12px] font-bold">
                        {userStats?.next_tier?.points - userStats?.info?.points}
                        pts remaining
                      </span>
                      <div
                        style={{ backgroundColor: "#E9E7FA" }}
                        className="flex flex-row items-center w-[280px] h-5 rounded-md"
                      >
                        <div
                          id="progressBar"
                          style={{
                            width:
                              ((userStats?.info?.points || 0) * 100) /
                                userStats?.next_tier?.points +
                              "%",
                          }}
                          className="bg-gradient-primary relative h-full text-[14px] rounded-md font-bold"
                        >
                          <span
                            style={
                              ((userStats?.info?.points || 0) * 100) /
                                userStats?.next_tier?.points >=
                              80
                                ? { right: "12%" }
                                : { right: "-42px" }
                            }
                            className="absolute top-[2%] w-[40px]"
                          >
                            {userStats?.info?.points || 0}/
                            {userStats?.next_tier?.points || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="min-w-[170px] max-w-[220px] h-fit px-5 py-4 bg-gradient-blue rounded-[4px]">
                  <p className="text-lg font-bold mb-2">
                    Have you heard about our upcoming Mega-Event?
                  </p>
                  <div className="text-right">
                    <Link
                      to={"/home/events"}
                      className="text-tiny underline text-primary-one"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex-1 w-full min-h-[300px] p-5 rounded-[10px] bg-white shadow-dark-1">
              <p className="text-xl font-bold mb-2">
                Are you ready to earn your next badge?
              </p>
              <p className="text-tiny mb-2">
                You are close to unlocking and earning your next badge! Only a
                few points are separating you from your next achievement.. Here
                is how to earn them:
              </p>
              <div className="flex flex-col 1sm:flex-row gap-5 items-center">
                <div className="flex-1">
                  <div className="w-fit mt-6 ml-2 1sm:mt-0 flex flex-col items-center justify-between">
                    <div className="flex flex-row flex-nowrap items-center">
                      <div className="flex flex-col justify-center items-center">
                        <div className="max-w-[100px] max-h-[114px] mt-4">
                          <img
                            src={prevBadge}
                            alt="prev badge"
                            className="h-full w-full"
                          />
                        </div>
                        <p className="font-bold text-xl">Tier 1.A</p>
                      </div>
                      <div className="max-w-[48px] max-h-[90px] ml-[28px] mr-[20px]">
                        <Polygon className="w-full h-full" />
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <div className="max-w-[116px] max-h-[133px]">
                          <img
                            src={goldBadge}
                            className="w-full h-full drop-shadow-gold"
                            alt="next badge"
                          />
                        </div>
                        <p className="font-bold text-xl">Tier 1.B</p>
                      </div>
                    </div>
                    <div className="w-full flex justify-center items-center flex-col gap-1">
                      <span className="font-bold">10pts remaining</span>
                      <div className="w-full rounded-md overflow-hidden h-5 bg-[#E9E7FA]">
                        <span className="block font-bold leading-5 w-1/2 h-full text-right bg-gradient-primary">
                          120/130
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="min-w-[170px] max-w-[220px] h-fit px-5 py-4 bg-gradient-blue rounded-[4px]">
                  <p className="text-lg font-bold mb-2">
                    Have you heard about our upcoming Mega-Event?
                  </p>
                  <div className="text-right">
                    <Link to={"/home/events"} className="text-tiny underline">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          ref={ref}
          className="w-[100%] 3lg:w-[50%] px-[10px] mb-[20px] flex flex-col justify-between"
        >
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            Share and earn
          </p>
          <div className="relative flex-1 w-full min-h-[300px] flex flex-col 1sm:flex-row gap-6 rounded-[10px] bg-white shadow-card-shadow">
            <img
              src={bottomLine}
              alt="graph"
              className="absolute bottom-0 w-full"
            />
            <div className="flex items-center justify-between px-12 pb-7 w-full">
              <div className="flex flex-col items-start">
                <h1 className="text-2xl font-bold text-primary-four max-w-[354px]">
                  Share your rank with your friends and earn
                  <span style={{ color: context.mainColor }}> 10 Points!</span>
                </h1>
                <p className="text-[#9FA9AF] text-tiny mt-6 pb-4">
                  Share it through :
                </p>
                <div className="flex mb-6">
                  <FacebookShareButton
                    url={dataUrl}
                    onShareWindowClose={() => getFacebookShareCount()}
                  >
                    <div className="flex items-center justify-center w-40 h-14 bg-[#FCFCFC] border-1 border-[#E7E9EB] rounded-lg mr-4">
                      <Facebook />
                      <p className="ml-2 text-primary-four text-tiny font-bold">
                        Facebook
                      </p>
                    </div>
                  </FacebookShareButton>
                </div>
                <button
                  className="flex items-center"
                  style={{ color: context.mainColor }}
                >
                  <p className="mr-2 font-bold text-base">Copy sharing link</p>
                  <LinkIcon />
                </button>
              </div>
              <div className="flex flex-col justify-center items-center">
                <img
                  className="rounded-full w-20 h-20 lg:w-[123px] lg:h-[123px] mx-auto"
                  src={avatar}
                  alt="user-profile"
                />
                <p
                  style={{ color: context.mainColor }}
                  className="mt-4 text-xs font-bold"
                >
                  {userStats && userStats?.user_rank}
                  {userStats?.user_rank === 1
                    ? "st "
                    : userStats?.user_rank === 2
                    ? "nd "
                    : userStats?.user_rank === 3
                    ? "rd "
                    : "th "}
                  rank
                </p>
                <p className="font-bold text-tiny text-primary-four mt-1">
                  {userStats?.info?.first_name || ""}{" "}
                  {userStats?.info?.last_name || ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <section className="w-[100%] 3lg:w-[50%] px-[10px] mb-[20px] flex flex-col justify-between">
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            Your Activities Log
          </p>
          <div className="flex-1 w-full min-h-[300px] flex flex-col 1sm:flex-row gap-6 rounded-[10px] bg-white shadow-brand">
            {userStats?.number_of_badges > 0 ? (
              <div className="w-full 1sm:w-[27%] min-w-[206px] flex-1 flex flex-col justify-center items-center p-4 text-center rounded-[10px] shadow-dark-1">
                <img
                  src={
                    userStats && (userStats?.current_badge?.icon || silverBadge)
                  }
                  className="w-[115px] h-[145px] mb-3"
                  alt="badge earned"
                />
                <p className="text-tiny font-bold text-[#91B9B4]">
                  {userStats?.current_badge?.badge_name}
                </p>
                <p className="text-tiny font-bold w-[103%]">
                  You earned this badge on
                </p>
                <p className="text-tiny">{userStats?.current_badge?.date}</p>
              </div>
            ) : (
              <div className="w-full 1sm:w-[27%] min-w-[196px] flex-1 flex flex-col justify-center items-center p-4 text-center rounded-[10px] shadow-dark">
                <img
                  src={badgeImg}
                  className="w-[117px] h-[158px] drop-shadow-silver2 mb-3"
                  alt="badge earned"
                />

                <p className="text-tiny font-bold text-primary-one">
                  Novice Writer
                </p>
                <p className="text-tiny font-bold">
                  Earn more points to earn this badge
                </p>
              </div>
            )}
            <div className="w-full 1sm:w-[73%] flex-2 pl-4 1sm:pl-0 pt-1 pb-4 flex flex-col justify-between">
              {userActivities?.results?.length > 0 ? (
                <div>
                  {userStats &&
                    [...userActivities?.results]
                      .sort(
                        (a, b) =>
                          new Date(formatDate(b.date)).getTime() -
                          new Date(formatDate(a.date)).getTime()
                      )
                      .slice(0, 5)
                      .map((el, index) => (
                        <div key={index}>
                          {typeof el.reward !== "string" ? (
                            <div className="flex flex-col justify-center min-h-[70px] pr-4">
                              <p className="text-tiny font-bold">
                                {el.activity_name}
                              </p>
                              <p className="text-[#9FA9AF] text-xs">
                                {el.date}
                              </p>
                            </div>
                          ) : (
                            <div className="flex justify-between items-center min-h-[70px] pr-4">
                              <div>
                                <p className="text-tiny font-bold">
                                  {el.activity_name}
                                </p>
                                <p className="text-[#9FA9AF] text-xs">
                                  {el.date}
                                </p>
                              </div>
                              <img
                                className="drop-shadow-iron w-[40px] h-[50px]"
                                src={el.reward}
                                alt="badge"
                              />
                            </div>
                          )}
                          <div className="w-full h-[1px] bg-[rgba(207,212,215,0.3)]"></div>
                        </div>
                      ))}
                </div>
              ) : (
                <p
                  style={{ color: context.mainColor }}
                  className="text-center p-[10px] font-bold m-auto"
                >
                  No data to show
                </p>
              )}
              <div className="w-full flex justify-end items-end px-4 mt-4">
                <Link
                  to={"/home/user-profile"}
                  className="block w-fit h-fit px-4 py-3 text-xs text-white font-bold rounded-[4px] shadow-dark bg-gradient-button"
                >
                  View All Activities
                </Link>
              </div>
            </div>
          </div>
        </section> */}
      </div>
      <div className="w-full h-[1px] bg-[rgba(207,212,215,0.3)] my-10"></div>
      <p className="text-tiny lg:text-base font-bold mb-5">Upcoming Events</p>
      <div className="flex flex-row flex-wrap mx-[-10px]">
        {userEvents?.results?.length > 0 ? (
          userEvents?.results?.map((el, index) => (
            <div
              key={index}
              className="w-[100%] md:w-[50%] lg:w-[33.3333%] 1xl:w-[25%] h-[300px] px-[10px] mb-[20px]"
            >
              <a href={el.url} target="blank">
                <div className="w-full h-full rounded-[10px] overflow-hidden transition-all duration-100 hover:scale-105">
                  <img
                    className="h-full w-full object-cover"
                    src={el.icon}
                    alt="events"
                  />
                </div>
              </a>
            </div>
          ))
        ) : (
          <p
            style={{ color: context.mainColor }}
            className="text-center p-[10px] font-bold"
          >
            No data to show
          </p>
        )}
      </div>
    </div>
  );
}

export default UserPannel;
