import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../components/input";
import SuccessDialog from "./SuccessDialog";

const SupportForm = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const {
    handleSubmit,
    register,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const submitHandler = (data) => {
    console.log(data);
    setShowSuccess(true);
  };

  return (
    <>
      {!showSuccess ? (
        <form
          onSubmit={handleSubmit(submitHandler)}
          className="w-[90%] md:w-[70%] bg-white p-6 shadow-formShaddow rounded-lg"
        >
          <p className="text-center font-bold text-lg pb-4">
            Please fill in the following data:
          </p>
          <div className="flex flex-col gap-3">
            <Input
              label={"Full Name"}
              type="text"
              placeholder={"Enter your full name.."}
              {...register("fullname")}
            />
            <div className="flex gap-2 w-full">
              <Input
                label={"Email Address"}
                type="email"
                placeholder={"Enter your email.."}
                {...register("email")}
              />
              <Input
                label={"Mobile Number"}
                type="text"
                placeholder={"Enter the number.."}
                {...register("phone")}
              />
            </div>
            <Input
              label={"Message"}
              type="textArea"
              placeholder={"Leave your message..."}
              {...register("message")}
            />
            <button className="px-8 place-self-end py-4 bg-gradient-to-r from-[#5E45FF] to-[#0028B3] text-white rounded-md">
              Send Message
            </button>
          </div>
        </form>
      ) : (
        <SuccessDialog />
      )}
    </>
  );
};

export default SupportForm;
