import {
  Navbar,
  ScrollArea,
  Image,
  Tooltip,
  UnstyledButton,
  Popover,
} from "@mantine/core";
import { LinksGroup } from "./LinksGroup";
import { NavLink, useLocation } from "react-router-dom";
import VerseIcons from "../components/VerseIcons";
import { ReactComponent as UnCollapsMenu } from "../assets/svg/Menu.svg";
import { ReactComponent as CollapsMenu } from "../assets/svg/menuCollapse.svg";

export default function SideNav({
  Navlinks,
  IsNavCollapsed,
  setIsNavCollapsed,
}) {
  const location = useLocation();
  const links = Navlinks.map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));

  const mainLinks = Navlinks.map((link) => {
    return (
      <div key={link.label}>
        {link.links && link.links.length > 0 ? (
          <Popover position="right" withArrow shadow="md">
            <Popover.Target>
              <Tooltip
                label={link.label}
                position="right"
                className="block p-3 text-center font-normal  text-white/50 hover:text-superNova active:text-superNova "
                withArrow
                transitionDuration={0}
                key={link.label}
              >
                <UnstyledButton>
                  <VerseIcons className="h-6 w-6" icon={link.Icon} />
                </UnstyledButton>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown className="border-0 bg-gradient-secondary">
              <div className="flex flex-col gap-2 px-4 text-sm font-normal">
                {link?.links?.map((link) => (
                  <NavLink
                    style={
                      location.pathname.split("/dashboard/")[1] === link.href ||
                      link.href.split("users")[1] === location.search
                        ? activeStyle
                        : { color: "rgb(255 255 255 / 0.5)" }
                    }
                    className="block  cursor-pointer p-1 font-medium hover:text-superNova "
                    to={link.href}
                    key={link.label}
                  >
                    {link.label}
                  </NavLink>
                ))}
              </div>
            </Popover.Dropdown>
          </Popover>
        ) : (
          <Tooltip
            label={link.label}
            position="right"
            className="block p-3 text-center font-normal  text-white/50 hover:text-superNova active:text-superNova "
            withArrow
            transitionDuration={0}
            key={link.label}
          >
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to={link.href}
              key={link.label}
            >
              <VerseIcons className="h-6 w-6" icon={link.Icon} />
            </NavLink>
          </Tooltip>
        )}
      </div>
    );
  });

  return (
    <>
      {IsNavCollapsed ? (
        <Navbar
          className={`bg-gradient-primary text-white z-50 transition-all duration-300 ease-in-out  ${
            IsNavCollapsed ? "w-[100px]" : "w-[240px]"
          }`}
        >
          <Navbar.Section grow className="flex w-full justify-center">
            <div className="flex flex-col items-center ">
              <div className="py-8">
                <Image
                  src="/images/Tam_Logo_sidenav.svg"
                  alt="TAM Logo"
                  height={15}
                />
              </div>
              {mainLinks}
            </div>
          </Navbar.Section>
          <Navbar.Section className="group -mx-4 flex justify-center border-t border-white py-2">
            <UnstyledButton
              className="mt-auto text-xs font-semibold text-white "
              onClick={(e) => setIsNavCollapsed(false)}
            >
              <CollapsMenu className=" stroke-white  group-hover:stroke-[#FDB236]" />
            </UnstyledButton>
          </Navbar.Section>
        </Navbar>
      ) : (
        <Navbar
          className={`bg-gradient-primary p-4 text-white transition-all duration-300  ease-in-out ${
            IsNavCollapsed ? "w-[100px]" : "w-[240px]"
          }`}
        >
          <Navbar.Section className="mt-8 flex justify-center">
            <Image
              src="/images/Tam_Logo_sidenav.svg"
              alt="TAM Logo"
              width={80}
              height={25}
            />
          </Navbar.Section>

          <Navbar.Section grow className="-mx-4" component={ScrollArea}>
            <div className="py-8 text-white ">{links}</div>
          </Navbar.Section>

          <Navbar.Section className="group -mx-4 flex justify-center border-t border-white pt-4">
            <UnstyledButton
              className="mt-auto text-xs font-semibold text-white "
              onClick={(e) => setIsNavCollapsed(true)}
            >
              <UnCollapsMenu className=" stroke-white  group-hover:stroke-[#FDB236]" />
            </UnstyledButton>
          </Navbar.Section>
        </Navbar>
      )}
    </>
  );
}

let activeStyle = {
  color: "#FDB236",
};
