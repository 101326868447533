import * as AllAppIcons from "../../assets/images/icons";

const VerseIcons = ({ icon, className }) => {
  const { ...icons } = AllAppIcons;
  const TheIcon = icons[icon];
  if (!TheIcon) {
    return null;
  }
  return <TheIcon className={className} aria-hidden="true" />;
};

export default VerseIcons;
