/*  eslint-disable  camelcase */
export const initialState = {
  vars: {},
  fetchVars: {},
};

export function reducer(state = initialState, action) {
  const filter = ({ searchFilter, sortBy, filterBy, locationFilter, vars }) => {
    let formatVars = vars;
    if (searchFilter?.length > 0) {
      formatVars = {
        ...formatVars,
        user__first_name: searchFilter,
      };
    }
    if (sortBy?.length) {
      const firstPartSort = sortBy[0]?.id?.split(".")[0];

      const userInfoSortBy =
        firstPartSort?.replace(firstPartSort, "user__") +
        sortBy[0]?.id.split(".")[1];
      const sorting = {
        sort_by: sortBy[0]?.desc
          ? sortBy[0].id === "user_info.gender"
            ? "-" + sortBy[0].id.split(".")[1]
            : sortBy[0].id === "user_info.last_login" ||
              sortBy[0].id === "user_info.date_joined"
            ? "-" + userInfoSortBy
            : "-" + sortBy[0]?.id
          : sortBy[0].id === "user_info.gender"
          ? sortBy[0].id.split(".")[1]
          : sortBy[0].id === "user_info.last_login" ||
            sortBy[0].id === "user_info.date_joined"
          ? userInfoSortBy
          : sortBy[0]?.id,
      };
      formatVars = {
        ...formatVars,
        ...sorting,
      };
    }

    if (filterBy?.length) {
      // const object = Object.assign({}, filterBy);
      console.log(filterBy?.map((yy) => yy));
      const filtering = filterBy?.map((ii) => ({
        [ii.id === "user_info.gender" ? "gender" : ii.id]: ii.value,
      }));
      const object = Object.assign({}, ...filtering);
      console.log(object);
      formatVars = {
        ...formatVars,
        ...object,
      };
    }
    if (locationFilter) {
      formatVars = {
        ...formatVars,
        city: locationFilter,
      };
    }
    return formatVars;
  };

  switch (action.type) {
    case "search": {
      const { sortBy, filterBy, locationFilter } = state;
      const vars = filter({
        sortBy,
        filterBy,
        locationFilter,
        searchFilter: action.payload.value,
        vars: state?.fetchVars,
      });
      return {
        ...state,
        vars,
        searchFilter: action.payload.value,
      };
    }

    case "sortBy": {
      const { searchFilter, filterBy, locationFilter } = state;
      const vars = filter({
        searchFilter,
        filterBy,
        locationFilter,
        sortBy: action.payload.value,
        vars: state?.fetchVars,
      });

      return {
        ...state,
        vars,
        sortBy: action.payload.value,
      };
    }

    case "filterBy": {
      const { searchFilter, sortBy, locationFilter } = state;
      const vars = filter({
        filterBy: action.payload.value,
        searchFilter,
        locationFilter,
        sortBy,
        vars: state?.fetchVars,
      });
      return {
        ...state,
        vars,
        filterBy: action.payload.value,
      };
    }
    case "locationFilter": {
      const { searchFilter, sortBy, filterBy } = state;
      const vars = filter({
        locationFilter: action.payload.value,
        searchFilter,
        filterBy,
        sortBy,
        vars: state?.fetchVars,
      });
      return {
        ...state,
        vars,
        locationFilter: action.payload.value,
      };
    }

    default:
      return {
        state,
      };
  }
}
