import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../../Context/AppContext";
import { ReactComponent as ArrowDownIcon } from "../../../assets/svg/ArrowDown.svg";
import blacklistImage from "../../../assets/images/warning.png";
import Dropdown from "../../../components/Dropdown";
import Modal from "../../../components/Modal";
import ToolTip from "../../../components/ToolTip";
import ToolTip2 from "../../../components/ToolTip2";
import eyes from "../../../assets/images/eyes.png";
import { Loading } from "../../../components/Loading";
import {
  useUserActivitiesByIdQuery,
  useUserBadgesByIdQuery,
  useUserStatsByIdQuery,
} from "../../../store/admin/adminApi";
import avatar from "../../../assets/images/avatar.png";
import silverHelal from "../../../assets/images/silverHlel.png";

import {
  useBlockUserMutation,
  useSendEmailMutation,
  useGetUsersQuery,
} from "../../../store/admin/usersApi";
import congratsImage from "../../../assets/images/congrats.png";
import redeemImage from "../../../assets/images/redeemimg.png";
import { toast } from "react-toastify";
function UserProfile() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const context = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const [showModalBlacklist, setShowModalBlacklist] = useState(false);
  const [showModalSendEmail, setShowModalSendEmail] = useState(false);

  const [blockUser, { isSuccess, isError, error }] = useBlockUserMutation();
  const [
    sendEmail,
    { isSuccess: isSuccessEmail, isError: isErrorEmail, error: errorEmail },
  ] = useSendEmailMutation();
  const { refetch } = useGetUsersQuery();
  const { data: userActivities, isLoading: activitiesLoading } =
    useUserActivitiesByIdQuery({ id: state?.user });
  const { data: tobeEarnedBadges, isLoading: badgesLoading } =
    useUserBadgesByIdQuery({ id: state?.user });
  const { data: stats, isLoading: statsLoading } = useUserStatsByIdQuery({
    id: state?.user,
  });
  useEffect(() => {
    refetch();
    if (isSuccess) {
      setShowModalBlacklist(false);
      context.setAndShowModalData({
        title: "User Blocked Successfully!!",
        subtitle: ``,
        description: "",
        image: congratsImage,
      });
      navigate("/dashboard/users");
    }
    if (isError) {
      toast.error((error?.data && error?.data?.msg) || "something went wrong!");
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    refetch();
    if (isSuccessEmail) {
      setShowModalSendEmail(false);
      context.setAndShowModalData({
        title: "Email Send Successfully!!",
        subtitle: ``,
        description: "",
        image: congratsImage,
      });
      navigate("/dashboard/users");
    }
    if (isError) {
      toast.error(
        (errorEmail?.data && errorEmail?.data?.msg) || "something went wrong!"
      );
    }
  }, [isSuccessEmail, isErrorEmail]);
  if (activitiesLoading || badgesLoading || statsLoading) {
    return <Loading />;
  }

  const formatDate = (hh) => {
    const date = hh.split(" ")[0];
    const [year, month, day] = date.split("/");
    let newDate = `${month}/${year}/${day}`;
    return newDate;
  };

  return (
    <div className="min-h-screen flex flex-col p-6 2lg:p-14">
      <div className="w-full   text-main-text">
        <p className="text-xs mb-2">
          <Link to={"/dashboard/users"}>Users</Link> &gt;{" "}
          <span>
            {state.user_info.first_name || state.user_info.last_name
              ? state.user_info.first_name + " " + state.user_info.last_name
              : state.user_info.username}
          </span>
        </p>
        <div className="flex flex-row w-full justify-between ">
          <div className="flex flex-nowrap justify-center content-center items-center gap-2 cursor-pointer p-1">
            <img
              className="rounded-full w-6 h-6 lg:w-8 lg:h-8"
              src={state.icon ? state.icon : avatar}
              alt="user-profile"
            />
            <p className="flex flex-nowrap justify-center content-center items-center gap-2">
              <p className="font-bold text-tiny lg:text-2xl">
                {state.user_info.first_name || state.user_info.last_name
                  ? state.user_info.first_name + " " + state.user_info.last_name
                  : state.user_info.username}
                ’s Profile
              </p>
            </p>
          </div>
          <div className="relative">
            <button
              onClick={() => setIsOpen(true)}
              style={{
                border: `2px solid ${context.mainColor}`,
                backgroundColor: context.mainColor,
              }}
              className="flex flex-row flex-nowrap justify-center items-center rounded-[4px] h-[38px] w-[135px] px-4 py-2 ml-3 text-white font-bold text-center text-xs lg:text-tiny outline-none"
            >
              <span>New Action </span>
              <ArrowDownIcon
                style={{ color: "white" }}
                className="w-[10px] h-[8px] ml-1"
              />
            </button>
            <Dropdown
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              minWidth="135px"
              minHeight="35px"
              top="44px"
              right="0px"
              items={[
                <div
                  onClick={() => {
                    setIsOpen(false);
                    setShowModalBlacklist(true);
                  }}
                >
                  Blacklist User
                </div>,
                <div
                  onClick={() => {
                    setIsOpen(false);
                    setShowModalSendEmail(true);
                  }}
                >
                  Send Email
                </div>,
              ]}
            />
            <Modal
              isBlacklist={true}
              showModal={showModalBlacklist}
              setShowModal={setShowModalBlacklist}
              image={blacklistImage}
              title={"Blacklisting User"}
              isActive={state.is_active}
              id={state.user}
              blockUser={blockUser}
              subtitle={
                <span>
                  {state?.is_active
                    ? "Are you sure you want to blacklist "
                    : "This User is already blocked "}
                  <span className="text-warning">
                    {state.user_info?.first_name || state.user_info?.last_name
                      ? state.user_info?.first_name +
                        " " +
                        state.user_info?.last_name
                      : state.user_info?.username}
                  </span>
                  ?
                </span>
              }
              description={`${
                state?.is_active
                  ? "Blacklisting user will prevent them from accessing their accounts and participating in any future activities, however their data will be saved in the system."
                  : ""
              }`}
            />
            <Modal
              isBlacklist={false}
              showModal={showModalSendEmail}
              setShowModal={setShowModalSendEmail}
              sendEmail={sendEmail}
              image={redeemImage}
              isActive={state.is_active}
              id={state.user}
              title={"Send Reminder Email"}
              subtitle={
                <span>
                  You are about to send Email to{" "}
                  {state.user_info?.first_name +
                    " " +
                    state.user_info?.last_name || "Mohammed Ahmed"}
                </span>
              }
              // description={`Redeeming the points will bring Mohammed one step closer to their next reward. The redeemded points will not affect the total points earned by Mohammed.`}
            />
          </div>
        </div>
        <div className="flex flex-row mt-[10px] gap-[8px] ml-[40px]">
          <div className="h-6 w-6 rounded-full bg-primary-one" />
          <div className="h-6 w-6 rounded-full bg-primary-four" />
          <div className="h-6 w-6 rounded-full bg-primary-three" />
          <div className="h-6 w-6 rounded-full bg-primary-two" />
          <div className="h-6 w-6 rounded-full bg-[#A52C38]" />
          <div className="h-6 w-6 rounded-full bg-[#91B9B4]" />
          <div className="h-6 w-6 rounded-full bg-[#FAC39B]" />
          <div className="h-6 w-6 rounded-full bg-[#9BC368]" />
          <div className="h-6 w-6 rounded-full bg-[#FFCA8C]" />
          <div className="h-6 w-6 rounded-full bg-[#F7BDB1]" />
          <div className="h-6 w-6 rounded-full bg-[#87949B]" />
          <div className="h-6 w-6 rounded-full bg-[#B78CA2]" />
        </div>
        <div className="w-full h-[1px] mb-9 mt-9 border-devider-color border-b-1"></div>
        <div className="flex flex-row gap-[30px]">
          <div className="flex flex-col justify-center text-[16px] font-bold p-[28px] h-[112px] bg-[#C8DCDA] rounded-[8px] ">
            <p className="text-primary-one">{state.accumulated_points}</p>
            <p> Points Earned</p>
          </div>
          <div className="flex flex-col justify-center text-[16px] font-bold p-[28px] h-[112px] bg-[#F5AD9E] rounded-[8px] ">
            <p className="text-[#fff]">{state.number_of_badges}</p>
            <p> Badges Earned</p>
          </div>
          <div className="flex flex-col justify-center text-[16px] font-bold p-[28px] h-[112px] bg-[#FFCA8C] rounded-[8px] ">
            <p className="text-primary-one">{state.number_of_rewards}</p>
            <p> Rewards Earned</p>
          </div>
          <div className="flex flex-col justify-center text-[16px] font-bold p-[28px] h-[112px] bg-[#B78CA2] rounded-[8px] ">
            <p className="text-[#fff]">{state.accumulated_points}</p>
            <p> Unredeemed Points</p>
          </div>
        </div>
      </div>

      <div className="flex flex-row flex-wrap mx-[-10px] mt-8">
        <div className="w-[100%] 3lg:w-[50%] px-[10px] mb-[20px] flex flex-col justify-between">
          <p className="text-tiny lg:text-base font-bold mb-2.5">
            Your Activities Log
          </p>

          <div
            className={`flex-1 flex flex-col w-full min-h-[300px] max-h-[450px]  ${
              userActivities?.results?.length > 0
                ? "overflow-auto"
                : "overflow-hidden"
            } rounded-[10px] bg-white shadow-dark`}
          >
            <div className="py-2 px-[25px] shadow-blue-shadow-light text-center  text-xs flex gap-[10px] justify-between h-[54px] text-white items-center bg-gradient-button rounded-t-lg font-bold whitespace-nowrap">
              <p className="flex-[2] min-w-0 text-[14px] font-bold text-left">
                Activity name
              </p>
              <p className="flex-1 text-[14px] font-bold  min-w-0  pr-[8px]">
                Date
              </p>
              <p className="flex-1 text-[14px] font-bold  min-w-0 text-right">
                Points
              </p>
            </div>
            {userActivities?.results?.length > 0 ? (
              [...userActivities?.results]
                .sort(
                  (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                )
                .map((el, index) =>
                  typeof el.reward !== "string" ? (
                    <div key={index}>
                      <div className="py-[15px] px-6 text-center mx-2  flex gap-[10px]  justify-between whitespace-nowrap">
                        <p className="flex-[2] text-[14px] text-main-text font-bold overflow-ellipsis whitespace-nowrap text-left overflow-hidden min-w-0 ">
                          {el.activity_name}
                        </p>
                        <p className="flex-1 overflow-ellipsis text-[14px] text-[#9FA9AF] whitespace-nowrap overflow-hidden min-w-0 ">
                          {el?.date}
                        </p>
                        <p className="flex-1 overflow-ellipsis whitespace-nowrap overflow-hidden min-w-0 text-right text-[16px] text-main-text   ">
                          {el.points} pts
                        </p>
                      </div>
                      <div className="px-[20px] w-full">
                        <div className="w-full h-[1px]  bg-[rgb(207,212,215,0.3)] "></div>
                      </div>
                    </div>
                  ) : (
                    <div key={index}>
                      <div className="py-[8px] px-6 text-center mx-2  flex gap-[10px] items-center justify-between whitespace-nowrap">
                        <p className="flex-[2] text-[14px] text-main-text font-bold overflow-ellipsis whitespace-nowrap text-left overflow-hidden min-w-0 ">
                          {el.activity_name}
                        </p>
                        <p className="flex-1 overflow-ellipsis text-[14px] text-[#9FA9AF] whitespace-nowrap overflow-hidden min-w-0 ">
                          {el?.data}
                        </p>
                        <div className="drop-shadow-iron flex-1 overflow-ellipsis whitespace-nowrap overflow-hidden min-w-0  flex justify-end">
                          <img
                            src={el.icon}
                            alt="rewards"
                            className="w-[40px] h-[50px]"
                          />
                        </div>
                      </div>
                      <div className="px-[20px] w-full">
                        <div className="w-full h-[1px]  bg-[rgb(207,212,215,0.3)] "></div>
                      </div>
                    </div>
                  )
                )
            ) : (
              <div className="flex flex-col w-full min-hk-[300px] my-auto px-4 1sm:px-6 md:px-10 py-5 justify-center items-center">
                <div className="flex flex-row">
                  <p className="text-[#CFD4D7] font-bold text-[24px] mr-[10px]">
                    Psst..
                  </p>
                  <img src={eyes} alt="eyes" />
                </div>
                <p
                  className="font-bold text-[24px] text-center"
                  style={{ color: context.mainColor }}
                >
                  Participate in more activities to view them here..
                </p>
                <p className="font-bold text-[16px] text-center text-main-text">
                  The more you participate in activities the more points &
                  badges you will earn and view here...
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="w-[100%] pb-2 3lg:w-[50%] px-[10px]  overflow-auto mb-[20px] flex flex-col justify-between">
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            All Badges
          </p>
          <div className="flex-1 w-full pb-2 min-h-[300px] flex flex-col 1sm:flex-row gap-6 p-[20px] rounded-[10px] bg-white shadow-dark">
            <div>
              <div>
                <div>
                  <p className="text-[#232323] text-[16px] font-bold mb-[15px]">
                    Earned Badges
                  </p>
                  <div className="flex flex-row flex-wrap gap-[40px]">
                    {stats?.all_earned_badges?.length > 0 ? (
                      stats?.all_earned_badges?.map((el, index) => (
                        <ToolTip
                          key={index}
                          el={{ name: el.name, date: el.earned_date }}
                          item={
                            <img
                              src={el.icon || silverHelal}
                              alt="badge"
                              className="h-[50px] w-[40px]"
                            />
                          }
                        />
                      ))
                    ) : (
                      <p
                        style={{ color: context.mainColor }}
                        className="text-center p-[10px] font-bold"
                      >
                        No data to show
                      </p>
                    )}
                  </div>
                  <div className="w-full h-[1px] my-[20px] bg-[rgb(207,212,215,0.3)]"></div>
                </div>
              </div>
              <div>
                <p className="text-[#232323] text-[16px] font-bold mb-[15px]">
                  To be Earned
                </p>
                <div className="flex flex-row flex-wrap gap-[40px]">
                  {tobeEarnedBadges?.results?.length ? (
                    tobeEarnedBadges?.results?.map((el, index) => (
                      <ToolTip2 key={index} el={el} />
                    ))
                  ) : (
                    <p
                      style={{ color: context.mainColor }}
                      className="text-center p-[10px] font-bold"
                    >
                      No data to show
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[100%] pb-2 3lg:w-[50%] px-[10px] overflow-auto mb-[20px] flex flex-col justify-between">
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            Earned Tiers
          </p>
          <div className="flex-1 w-full pb-2 min-h-[200px]  flex items-center flex-col 1sm:flex-row gap-6 p-[20px] rounded-[10px] bg-white shadow-dark">
            <div className="flex flex-row flex-wrap gap-[40px]">
              {Object.entries(stats?.all_earned_tiers)?.length ? (
                Object.entries(stats?.all_earned_tiers)?.map(([key, value]) => (
                  <ToolTip
                    el={{ name: key }}
                    item={
                      <img
                        src={value || silverHelal}
                        alt="badge"
                        className="h-[50px] w-[40px] rounded-md"
                      />
                    }
                  />
                ))
              ) : (
                <p
                  style={{ color: context.mainColor }}
                  className="text-center p-[10px] font-bold"
                >
                  No data to show
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-[100%] pb-2 3lg:w-[50%] px-[10px] overflow-auto mb-[20px] flex flex-col justify-between">
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            Claimed Rewards
          </p>
          <div className="flex-1 w-full pb-2 min-h-[200px] flex items-center flex-col 1sm:flex-row gap-6 p-[20px] rounded-[10px] bg-white shadow-dark">
            <div className="flex flex-row flex-wrap gap-[40px]">
              {stats?.all_earned_rewards?.length > 0 ? (
                stats?.all_earned_rewards?.map((el, index) => (
                  <ToolTip
                    key={index}
                    el={{ name: el.name }}
                    item={
                      <img
                        src={el.icon || silverHelal}
                        alt="badge"
                        className="h-[50px] w-[60px] rounded-md"
                      />
                    }
                  />
                ))
              ) : (
                <p
                  style={{ color: context.mainColor }}
                  className="text-center p-[10px] font-bold"
                >
                  No data to show
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
