import React, { useState } from "react";
import { HeaderSearch } from "./TopBar";
import SideNav from "./SideNav";
import BackToVerse from "../components/BackToVerse";

function Layout({ children }) {
  const [IsNavCollapsed, setIsNavCollapsed] = useState(true);
  const Navlinks = [
    {
      label: "Dashboard",
      href: "home",
      Icon: "ReceiptItem",
    },

    {
      label: "Users",
      href: "users",
      Icon: "Users",
      // links: [
      //   {
      //     label: "All Users",
      //     href: "users?filter=all",
      //     type: "query",
      //   },
      // {
      //   label: "Most Active Users",
      //   href: "users?filter=active",
      //   type: "query",
      // },
      // {
      //   label: "Most Earned Points",
      //   href: "users?filter=most-earned-points",
      //   type: "query",
      // },
      // {
      //   label: "Most Earned Badges",
      //   href: "users?filter=most-earned-badges",
      //   type: "query",
      // },
      // ],
    },
    {
      label: "Badges",
      href: "badges",
      Icon: "Badges",
    },
    {
      label: "Tiers",
      href: "tiers",
      Icon: "Badges",
    },
    {
      label: "Rewards",
      href: "rewards",
      Icon: "Rewards",
    },
    {
      label: "Activities",
      href: "activities",
      Icon: "People",
    },
    {
      label: "Systems",
      href: "systems",
      Icon: "System",
    },
    {
      label: "Settings",
      href: "edit-profile",
      Icon: "Settings",
      // links: [
      //   {
      //     label: "Edit Profile",
      //     href: "edit-profile",
      //     type: "path",
      //   },
      // {
      //   label: "Configuration",
      //   href: "configuration",
      //   type: "path",
      // },
      // ],
    },
  ];

  return (
    <div className="flex h-screen bg-[#FAFAFF]">
      <BackToVerse />
      <SideNav
        Navlinks={Navlinks}
        IsNavCollapsed={IsNavCollapsed}
        setIsNavCollapsed={setIsNavCollapsed}
      />
      <div className=" h-full max-h-screen w-full overflow-y-scroll bg-ghostWhite">
        {/* top bar */}
        <HeaderSearch />
        <div>{children}</div>
      </div>
    </div>
  );
}

export default Layout;
