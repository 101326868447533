import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_HEADERS, BASE_URL } from "../../configs/DataService";

export const adminDashboardApi = createApi({
  reducerPath: "adminDashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/gs/api/admin`,
    prepareHeaders: BASE_HEADERS,
  }),
  tagTypes: ["stats"],
  endpoints: ({ query, mutation }) => ({
    adminStats: query({
      query: () => ({
        url: "stats",
      }),
      providesTags: ["stats"],
    }),
    usersStats: query({
      query: (type) => ({
        url: `current_users_stats_${type}`,
      }),
      providesTags: ["usersStats"],
    }),
    topEarnedBadges: query({
      query: () => ({
        url: `top_earned_badges`,
      }),
      providesTags: ["earnedBadges"],
    }),
    cardStats: query({
      query: (endpoint) => ({
        url: `${endpoint}`,
      }),
      providesTags: ["cardStats"],
    }),
  }),
});

export const {
  useAdminStatsQuery,
  useUsersStatsQuery,
  useTopEarnedBadgesQuery,
  useCardStatsQuery,
} = adminDashboardApi;
