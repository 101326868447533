import React, { useState } from "react";
import { ReactComponent as NotificationIcon } from "../../../assets/svg/Ring.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";


import Hero from "./Hero";
import RewardsFilters from "./RewardsFilters";
import RewardCard from "./RewardCard";
import RewardDetailsModal from "./RewardDetailsModal";
import { useRewardsQuery, useStatsQuery } from "../../../store/user/userApi";
import ModalCongrats from "../../../components/ModalCongrats";
import { Loading } from "../../../components/Loading";

function Rewards() {
  const { data: rewardsReq, isLoading } = useRewardsQuery();
  const { data: userStats } = useStatsQuery();
  const [close, setClose] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState({ search: "", range: "", type: "" });
  const [selectedReward, setSelectedReward] = useState();

  const [showCongratsModal, setShowCongratsModal] = useState(false);

  return (
    <div className="w-full h-full md:p-10 p-5 main-bg font-Effra">
      <Hero />
      {showModal && (
        <RewardDetailsModal
          showModal={showModal}
          setShowModal={setShowModal}
          reward={selectedReward}
          setShowCongratsModal={setShowCongratsModal}
        />
      )}
      {
        <ModalCongrats
          showModal={showCongratsModal}
          setShowModal={setShowCongratsModal}
          // congratsImage={congratsImage}
          title="Congrats!! You have redeemed your points!"
          subtitle="You have just earned the Apple Watch 8th Generation"
          description="One of our representatives will reach out to you to deliver your reward.
        Your remaining points are 1200pt"
        />
      }
      {!close && (
        <>
          <div className="flex w-full items-center justify-between my-10 text-[#1E860F] bg-[#E7F4E5] rounded py-[10px] px-4 ">
            <div className="flex items-center">
              <NotificationIcon className="stroke-current sm:w-[17px] sm:h-[20px] w-4 h-4" />
              <p className="1sm:text-base sm:text-xs text-[10px] font-bold sm:ml-4 ml-3">
                You currently have{" "}
                <span className="font-black text-user-screen">
                  {userStats?.info?.points}
                </span>{" "}
                pts that can be redeemed and exchanged for valuable rewards
              </p>
            </div>
            <Close
              className="stroke-current cursor-pointer"
              onClick={() => setClose((prev) => !prev)}
            />
          </div>
          <div className="border-b-1 border-devider-color" />
        </>
      )}
      <div className="flex flex-col  mt-10">
        <p className="font-bold text-lg lg:text-2xl mb-4">All Rewards</p>
      </div>
      <RewardsFilters setFilter={setFilter} filter={filter} />
      <div className="w-full grid gap-8 min-h-[250px] 1lg:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 1sm:grid-cols-3 sm:grid-cols-2 grid-cols-1 ">
        {isLoading ? (
          <Loading />
        ) : rewardsReq?.results?.length &&
          rewardsReq?.results?.filter(
            (item) =>
              item.name.match(new RegExp(filter.search, "i")) &&
              (item.status === filter.type || !filter.type) &&
              (!filter.range[1] ||
                (item.points >= +filter.range[0] &&
                  item.points <= +filter.range[1]))
          ).length > 0 ? (
          rewardsReq?.results
            ?.filter(
              (item) =>
                item.name.match(new RegExp(filter.search, "i")) &&
                (item.status === filter.type || !filter.type) &&
                (!filter.range[1] ||
                  (item.points >= +filter.range[0] &&
                    item.points <= +filter.range[1]))
            )
            ?.map((reward) => (
              <RewardCard
                key={reward.id}
                reward={reward}
                setShowModal={setShowModal}
                setSelectedReward={setSelectedReward}
              />
            ))
        ) : (
          <h1 className="text-main-text my-5 font-bold text-lg">
            No Rewards Found
          </h1>
        )}
      </div>
    </div>
  );
}

export default Rewards;
