export const RegisteredUserMonths = [
    {
      id: 1,
      label: "Jan",
      value: 0,
    },
    {
      id: 2,
      label: "Feb",
      value: 0,
    },
    {
      id: 3,
      label: "Mar",
      value: 0,
    },
    {
      id: 4,
      label: "Apr",
      value: 0,
    },
    {
        id: 5,
        label: "May",
        value: 0,
      },
      {
        id: 6,
        label: "Jun",
        value: 0,
      },
      {
        id: 7,
        label: "Jul",
        value: 0,
      },
      {
        id: 8,
        label: "Aug",
        value: 0,
      },
      {
        id: 9,
        label: "Sep",
        value: 0,
      },
      {
        id: 10,
        label: "Oct",
        value: 0,
      },
      {
        id: 11,
        label: "Nov",
        value: 0,
      },
      {
        id: 12,
        label: "Dec",
        value: 0,
      },
  ];
  export const RegisteredUserDataYears = [
    {
      id: 1,
      label: "2016",
      value: 0,
    },
    {
      id: 2,
      label: "2017",
      value: 0,
    },
    {
      id: 3,
      label: "2018",
      value: 0,
    },
    {
      id: 4,
      label: "2019",
      value: 0,
    },
    {
        id: 5,
        label: "2020",
        value: 0,
      },
      {
        id: 6,
        label: "2021",
        value: 0,
      },
      {
        id: 7,
        label: "2022",
        value: 0,
      },
      {
        id: 8,
        label: "2023",
        value: 0,
      },
      {
        id: 9,
        label: "2024",
        value: 0,
      },
      {
        id: 10,
        label: "2025",
        value: 0,
      },
      {
        id: 11,
        label: "2026",
        value: 0,
      },
      {
        id: 12,
        label: "2027",
        value: 0,
      },
  ];
  export const UsersStatisticsMonths = [
    {
      id: 1,
      label: "Total Users",
      value: 100,
    },
    {
      id: 2,
      label: "Active Users",
      value: 70,
    },
    {
      id: 3,
      label: "Inactive Users",
      value: 30,
    },
    {
      id: 4,
      label: "Users with Badges",
      value: 20,
    },
    {
        id: 5,
        label: "Users with Rewards",
        value: 10,
      },
      {
        id: 6,
        label: "Blacklisted Users",
        value: 5,
      },

  ];


  export const UsersStatisticsYears = [
    {
      id: 1,
      label: "Total Users",
      value: 75,
    },
    {
      id: 2,
      label: "Active Users",
      value: 120,
    },
    {
      id: 3,
      label: "Inactive Users",
      value: 235,
    },
    {
      id: 4,
      label: "Users with Badges",
      value: 46,
    },
    {
        id: 5,
        label: "Users with Rewards",
        value: 72,
      },
      {
        id: 6,
        label: "Blacklisted Users",
        value: 135,
      },

  ];

