import "./App.css";
import AppContext from "./Context/AppContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  sidebarRoutes,
  authRoutes,
  dashboardRoutes,
  usersRoutes,
} from "./utils/Routes";
import LoadingPage from "./screens/auth/loadingPage/LoadingPage";
import Dashboard from "./screens/dashboardScreens/Dashboard";
import { AuthProvider } from "./Context/AuthContext";
// import { RequireAuth } from "./components/auth/RequireAuth";
import { DeniedToAuthUsers } from "./components/auth/DeniedToAuthUsers";
import Home from "./screens/dashboardScreens/Home";
// import RequireAdmin from "./components/auth/RequireAdmin";
import HomePage from "./screens/usersScreens/HomePage";
import UserPannel from "./screens/usersScreens/UserPannel";
// import RequireUser from "./components/auth/RequireUsers";
import ScrollToTop from "./helpers/ScrollToTop";
import Provider from "react-redux/es/components/Provider";
import store from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const getUsersRoutes = () => {
    return usersRoutes.map((route) => {
      return (
        <Route key={route.path} path={route.path} element={route.component} />
      );
    });
  };

  const getDashboardRoutes = () => {
    return dashboardRoutes.map((route) => {
      return (
        <Route key={route.path} path={route.path} element={route.component} />
      );
    });
  };

  const getSidebarRoutes = () => {
    return sidebarRoutes.map((route) => {
      return (
        <Route key={route.path} path={route.path} element={route.component} />
      );
    });
  };

  const getRoutes = () => {
    return authRoutes.map((route) => {
      return (
        <Route
          key={route.path}
          path={route.path}
          element={<DeniedToAuthUsers>{route.component}</DeniedToAuthUsers>}
        />
      );
    });
  };

  return (
    <Provider store={store}>
      <AuthProvider>
        <AppContext>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path={"loading"} element={<LoadingPage />} />
              <Route path={"home"} element={<HomePage />}>
                <Route index element={<UserPannel />} />
                {getUsersRoutes()}
              </Route>
              {getRoutes()}
              <Route
                path="*"
                element={<Navigate to="dashboard/home" replace />}
              />

              <Route path={"dashboard"} element={<Dashboard />}>
                <Route index element={<Home />} />
                {getDashboardRoutes()}
                {getSidebarRoutes()}
              </Route>
            </Routes>
            <ToastContainer />
          </BrowserRouter>
        </AppContext>
      </AuthProvider>
    </Provider>
  );
}

export default App;
