import React from "react";

function CloseIcon({ className, onClick }) {
	return (
		<svg
			width="32"
			height="36"
			viewBox="0 0 32 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className + " cursor-pointer"}
			onClick={onClick}
		>
			<path
				d="M16 32.3327C23.3638 32.3327 29.3333 26.3631 29.3333 18.9993C29.3333 11.6356 23.3638 5.66602 16 5.66602C8.6362 5.66602 2.66667 11.6356 2.66667 18.9993C2.66667 26.3631 8.6362 32.3327 16 32.3327Z"
				stroke="#5E45FF"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M20 15L12 23"
				stroke="#5E45FF"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12 15L20 23"
				stroke="#5E45FF"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default CloseIcon;
