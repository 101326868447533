import React from "react";
import { useAppContext } from "../Context/AppContext";

function Card2({ icon, name, points, badges, rewards }) {
  const context = useAppContext();

  return (
    <div className="w-[100%] sm:w-[50%] lg:w-[100%] 2lg:w-[50%] flex-initial px-[7px] mt-[15px]">
      <div
        style={{
          borderLeft: `8px solid ${context.mainColor}`,
          boxShadow: `0 4px 32px 0px rgb(15,40,55,0.1)`,
        }}
        className="flex items-center w-full h-[62px] bg-white rounded-[8px] cursor-pointer"
      >
        <div className="flex flex-nowrap items-center pl-[15px] p-1">
          <img
            className="rounded-full w-[28px] h-[28px] lg:w-[40px] lg:h-[40px]"
            src={icon}
            alt="user-profile"
          />
          <div className="flex flex-col ml-[8px]">
            <p className="font-bold text-xs lg:text-tiny xl:text-base text-main-text">
              {name}
            </p>
            <p
              style={{ color: context.mainColor }}
              className="font-bold text-xxs xl:text-xs inline-flex items-center"
            >
              <span>{points}pt</span>
              <span className="rounded-full h-1 w-1 bg-primary-four mx-1" />
              <span>{badges} Badge</span>
              <span className="rounded-full h-1 w-1 bg-primary-four mx-1" />
              <span>{rewards} Rewards</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card2;
