import { useState } from "react";
import { Group, Collapse, UnstyledButton } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons";
import VerseIcons from "../components/VerseIcons";
import { NavLink, useLocation } from "react-router-dom";

export function LinksGroup({ Icon, label, href, initiallyOpened, links }) {
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const location = useLocation();
  const items = (hasLinks ? links : []).map((link) => (
    <NavLink
      style={
        location.pathname.split("/dashboard/")[1] === link.href ||
        link.href.split("users")[1] === location.search
          ? activeStyle
          : { color: "rgb(255 255 255 / 0.5)" }
      }
      className="ml-10 block cursor-pointer p-2 pl-8 text-sm font-normal text-white/50 hover:text-superNova active:text-superNova "
      to={link.href}
      key={link.label}
    >
      {link.label}
    </NavLink>
  ));

  return (
    <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        className={`w-full py-4 px-4 text-sm font-normal text-white/50 hover:font-semibold hover:text-superNova `}
      >
        <Group position="apart" spacing={0}>
          <div className="flex items-center">
            {hasLinks ? (
              <>
                <VerseIcons className="h-6 w-6" icon={Icon} />
                <div className="ml-6">{label}</div>
              </>
            ) : (
              <NavLink
                className=" flex"
                to={href}
                key={label}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <VerseIcons className="mr-6 h-6 w-6" icon={Icon} />
                {label}
              </NavLink>
            )}
          </div>
          {hasLinks && (
            <IconChevronRight
              className={`transform transition-all duration-200 ease-in-out ${
                opened && "rotate-90"
              }  `}
              size={14}
              stroke={1.5}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

let activeStyle = {
  color: "#FDB236",
};
