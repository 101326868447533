import { Input } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import NotificationComp from "./NotificationComp";
import UserMenu from "./UserMenu";
import { useAppContext } from "../Context/AppContext";
import { useLocation } from "react-router-dom";

export function HeaderSearch() {
  const context = useAppContext();
  const { pathname } = useLocation();
  const notifications = [];
  const user = {};
  const { state } = useLocation();
  console.log(state);
  return (
    <header className="flex h-[68px] items-center justify-between bg-white px-8 ">
      <div className="flex w-full items-center justify-between  ">
        <div className="hidden text-xl font-bold text-dark sm:inline-block">
          Verse{" "}
          <span className="hidden font-normal text-dark/70 md:inline-block">
            | Gamification System
          </span>
        </div>

        <div className="flex gap-4">
          {pathname.split("/")[2] !== "edit-profile" &&
          pathname.split("/")[2] !== "home" &&
          !state?.city ? (
            <Input
              placeholder="Search for Users, Tiers, Rewards, etc..."
              classNames={{
                input:
                  "bg-dark/10 lg:w-[410px] w-[210px] max-w-[410px] h-12 placeholder:text-dark/60 !text-dark focus:border-primary ",
                icon: "text-dark/60",
              }}
              onChange={(e) => context.setKeyword(e.target.value)}
              icon={<IconSearch size={16} stroke={1.5} />}
            />
          ) : null}
          {/* <NotificationComp notifications={notifications} /> */}
          <UserMenu user={user} />
        </div>
      </div>
    </header>
  );
}
