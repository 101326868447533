import { useEffect } from "react";
import { useState } from "react";
import Select, { components } from "react-select";
import { SortDownIcon } from "../../components/shared/Icons";
import { useEventsQuery } from "../../store/user/userApi";
import { useAppContext } from "../../Context/AppContext";
import { Loading } from "../../components/Loading";
import QRCode from "react-qr-code";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SortDownIcon className="text-[18px] text-main-text mb-[6px]" />
    </components.DropdownIndicator>
  );
};

function Events() {
  const context = useAppContext();
  const { data: eventsList, isLoading } = useEventsQuery();
  const [cityOptions, setCityOptions] = useState([{ value: "", label: "All" }]);
  const [filter, setFilter] = useState({ city: "", eventType: "" });
  const [eventTypeOptions, setEventTypeOptions] = useState([
    { value: "", label: "All" },
  ]);
  const eventTypes = eventsList?.results.reduce((acc, item) => {
    if (!acc.includes(item?.type)) {
      acc.push(item?.type);
    }
    return acc;
  }, []);
  const eventCities = eventsList?.results?.reduce((acc, item) => {
    if (!acc.includes(item?.city)) {
      acc.push(item?.city);
    }
    return acc;
  }, []);
  useEffect(() => {
    if (eventsList?.results) {
      setCityOptions((prev) => [
        ...cityOptions,
        ...eventCities?.map((i) => ({
          value: i,
          label: i,
        })),
      ]);
      setEventTypeOptions((prev) => [
        ...eventTypeOptions,
        ...eventTypes?.map((i) => ({
          value: i,
          label: i,
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsList]);
  if (isLoading) return <Loading />;

  return (
    <div className="w-full h-full">
      <div
        style={{
          backgroundImage: `${
            eventsList?.results?.length > 0
              ? `url(${eventsList?.results[0].icon})`
              : ""
          }`,
          backgroundRepeat: "round",
          backgroundSize: "contain",
        }}
        className={`w-full ${
          eventsList?.results?.length > 0 ? "" : "bg-eventbg"
        } h-[calc(26vh-55px)] lg:h-[calc(77vh-86px)] max-h-[1024px]`}
      ></div>
      <div className="w-full h-full py-12 px-8">
        <p className="text-xl lg:text-2xl font-bold mb-8">All Events</p>
        <div className="flex flex-row flex-wrap gap-4 mb-6">
          <div className="shadow-dark-5 rounded-[4px] w-fit">
            <Select
              className="text-center text-xs font-bold"
              name="eventType"
              defaultValue={{ value: "", label: "Event Type" }}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
              styles={{
                control: (base) => ({
                  ...base,
                  border: 0,
                  padding: "0px 4px",
                  boxShadow: "none",
                }),
              }}
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, eventType: e.value }))
              }
              options={eventTypeOptions}
            />
          </div>
          {/* <div className="shadow-dark-5 rounded-[4px] w-fit">
            <Select
              className="text-center text-xs font-bold"
              name="date"
              defaultValue={{ value: "", label: "Date" }}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
              styles={{
                control: (base) => ({
                  ...base,
                  border: 0,
                  padding: "0px 4px",
                  boxShadow: "none",
                }),
              }}
              options={dateOptions}
            />
          </div> */}
          <div className="shadow-dark-5 rounded-[4px] w-fit">
            <Select
              className="text-center text-xs font-bold"
              name="city"
              defaultValue={{ value: "", label: "City" }}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
              styles={{
                control: (base) => ({
                  ...base,
                  border: 0,
                  padding: "0px 4px",
                  boxShadow: "none",
                }),
              }}
              options={cityOptions}
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, city: e.value }))
              }
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap mx-[-10px]">
          {isLoading ? (
            <Loading />
          ) : eventsList?.results?.length &&
            eventsList?.results?.filter(
              (i) =>
                (!filter.city || i.city === filter.city) &&
                (!filter.eventType || i.type === filter.eventType)
            ).length > 0 ? (
            eventsList?.results
              .filter(
                (i) =>
                  (!filter.city || i.city === filter.city) &&
                  (!filter.eventType || i.type === filter.eventType)
              )
              .map((el) => (
                <div className="w-[100%]  md:w-[50%] lg:w-[33.3333%] 1xl:w-[25%] h-[300px] px-[10px] mb-[20px]">
                  <a href={el.url} target="blank">
                    <div className="relative w-full h-full rounded-[10px] overflow-hidden transition-all duration-100 hover:scale-105">
                      <img
                        className="h-full w-full object-cover "
                        src={el.icon}
                        alt="events"
                      />
                      <div className="hover:w-32 hover:h-32 h-16 w-16 absolute p-1.5 bottom-[3%] left-[2%] bg-white rounded-[4px] ">
                        <QRCode
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={el?.url}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </div>
                  </a>
                </div>
              ))
          ) : (
            <p
              style={{ color: context.mainColor }}
              className="text-center p-[10px] font-bold"
            >
              No Events Found
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Events;
