import React, { memo, useMemo, useRef, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { ReactComponent as Upload } from "../assets/svg/upload.svg";
import checked from "../assets/images/checked.png";
import { useAppContext } from "../Context/AppContext";
function ImageUpload({ setData, data, image, className }) {
  const [loading, setLoading] = useState(false);
  const hiddeninput = useRef(null);
  const context = useAppContext();

  const uploadImg = async (files) => {
    const uploadUrl = await axios
      .get("https://core.verse-stg.tam.run/gs/api/presigned_url", {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`, //the token is a variable which holds the token
        },
      })
      .then((res) => {
        console.log(res);
        return res?.data;
      });

    setLoading(true);
    const file = files[0];
    if (uploadUrl) {
      await fetch(uploadUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file?.type,
          "Content-Disposition": "inline",
        },
        body: file,
      })
        .then((res) => {
          console.log(res.url.split("?")[0]);
          setData({ ...data, image: res.url.split("?")[0] });
        })
        .catch((err) => {
          alert("error");
        });
    }
    setLoading(false);
  };

  return (
    <div
      style={{ boxShadow: "0px 4px 40px 0px rgba(15, 40, 55, 0.1)" }}
      className={`w-[245px] flex items-center justify-center self-center mt-[10px] rounded-[8px]  h-[105px] bg-[#fff] ${className}`}
    >
      {loading === false ? (
        <span
          className="flex flex-col items-center  hover:cursor-pointer"
          onClick={() => {
            hiddeninput.current.click();
          }}
        >
          <Upload
            className="h-[26px] w-[26px]"
            style={{ color: context.mainColor }}
          />
          <p className="text-[10px] text-[#9FA9AF]">Upload Symbol</p>
          {image?.length > 0 && (
            <img
              className="h-[15px] w-[15px] mt-[10px]"
              src={checked}
              alt="upload"
            />
          )}
          <input
            ref={hiddeninput}
            style={{ display: "none" }}
            type="file"
            onChange={(e) => {
              uploadImg(e.target.files);
            }}
          />
        </span>
      ) : (
        <Oval height="40" width="40" color="grey" ariaLabel="loading" />
      )}
    </div>
  );
}
export default ImageUpload;
