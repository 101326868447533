import React, { useEffect, useState } from "react";
import { useAppContext } from "../../Context/AppContext";
import Switch from "react-switch";

import ImageUpload from "../../utils/ImageUpload";
import congratsImage from "../../assets/images/congrats.png";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import ModalSkeleton from "../ModalSkeleton";
import FormModalBody from "../FormModalBody";
import Input from "../input";
import SelectComponent from "../select";
import {
  useAddRewardsMutation,
  useUpdateAdminRewardsMutation,
  useAdminTeirsQuery,
} from "../../store/admin/adminApi";
const schema = yup.object().shape({
  name: yup.mixed().test("required", "You need to provide a name", (value) => {
    return value && value.length;
  }),
  label: yup
    .mixed()
    .test("required", "You need to provide a label", (value) => {
      return value && value.length;
    }),
  points: yup
    .mixed()
    .test("required", "You need to provide points", (value) => {
      return value;
    }),

  description: yup
    .mixed()
    .test("required", "You need to provide a description", (value) => {
      return value && value.length;
    }),

  url: yup.mixed().test("required", "You need to provide url", (value) => {
    return value;
  }),
});
function RewardModal({ reFetch, title, showModal, setShowModal, itemToEdit }) {
  const context = useAppContext();

  const [mydata, setData] = useState({
    image: itemToEdit?.icon ? itemToEdit?.icon : "",
  });
  const [tiersOptions, setTiersOptions] = useState([]);

  const [addRewards, { isSuccess, isError, error }] = useAddRewardsMutation();
  const [
    updateAdminRewards,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = useUpdateAdminRewardsMutation();
  const { data: adminTier, isLoading } = useAdminTeirsQuery();

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const vals = getValues();
  useEffect(() => {
    if (adminTier?.results) {
      setTiersOptions(() => [
        ...tiersOptions,
        ...adminTier?.results?.map((i) => ({
          value: i.id,
          label: i.name,
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminTier?.results]);

  const onSubmit = async (data) => {
    if (itemToEdit)
      return updateAdminRewards({
        ...data,
        icon: mydata?.image || itemToEdit?.icon,
        id: itemToEdit?.id,
        status: watch("status") ? "active" : "inactive",
      });

    addRewards({
      ...data,
      icon: mydata.image,
      status: watch("status") ? "active" : "inactive",
    });
  };

  useEffect(() => {
    if (itemToEdit) {
      setData({ ...mydata, image: itemToEdit?.icon });
      reset({
        ...itemToEdit,
      });
    }
  }, [itemToEdit, reset]);

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) setShowModal(false);

    if (isSuccess)
      toast.success(
        reFetch(),
        setShowModal(false),
        context.setAndShowModalData({
          title: "Congrats!! Your Reward Is Up & Live!!",
          subtitle: `You have just created a  reward!`,
          description:
            "Users can now redeem their points to obtain this reward. You can edit or deactivate this reward at any point in time.",
          image: congratsImage,
        })
      );

    if (isUpdateSuccess)
      toast.success(
        reFetch(),
        setShowModal(false),
        context.setAndShowModalData({
          title: "Reward    Edited Successfully!!",
          subtitle: ``,
          description: "",
          image: congratsImage,
        })
      );

    if (isError || isUpdateError || updateError)
      toast.error(
        updateError?.data?.url[0] ||
          (error?.data && error?.data?.url[0]) ||
          "something went wrong!"
      );
  }, [isError, isSuccess, isUpdateSuccess, isUpdateError, updateError, error]);

  return (
    <>
      <ModalSkeleton showModal={showModal} setShowModal={setShowModal}>
        <ToastContainer />

        <FormModalBody title={title}>
          <form
            className="text-center flex flex-col   items-start "
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex w-full flex-col gap-10">
              <div className="flex w-full  gap-10">
                <div className="w-1/2">
                  <Input
                    type="text"
                    label="Reward Name"
                    placeholder="Enter the Reward name..."
                    id="name"
                    register={register}
                  />
                  {errors.name && (
                    <p className="mt-1 text-sm text-left text-red">
                      {errors.name.message}
                    </p>
                  )}
                </div>
                <div className="w-1/2">
                  <Input
                    type="number"
                    label="Points to be earned"
                    placeholder="Enter the points to be earned.."
                    id="points"
                    register={register}
                  />
                  {errors?.points && (
                    <p className="mt-1 text-sm text-left text-red">
                      {errors?.points.message}
                    </p>
                  )}
                </div>
                <div className="w-1/2">
                  <Input
                    type="text"
                    label="Label"
                    placeholder="Enter the Label"
                    id="label"
                    register={register}
                  />
                  {errors?.label && (
                    <p className="mt-1 text-sm text-left text-red">
                      {errors.label.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full mt-6">
              <Input
                className="w-full"
                type="textArea"
                label="Reward Description"
                placeholder="Enter the Reward Description.."
                id="description"
                {...register("description")}
              />
              {errors.description && (
                <p className="mt-1 text-sm text-left text-red">
                  {errors.description.message}
                </p>
              )}
            </div>
            <div className="flex-initial w-1/2 mt-4">
              <Input
                type="url"
                label="Reward Url"
                id="url"
                register={register}
              />
              <div>
                {errors?.url && (
                  <p className="mt-1 text-sm text-center text-red">
                    {errors?.url?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col w-full items-start mt-4">
              <p className="font-medium">Reward Platform</p>
              <SelectComponent
                id="tier"
                className="text-xs  mt-[15px] w-1/2 border-1 border-input-color rounded-md "
                placeholder="Select Tier..."
                isSearchable={false}
                defaultValue={
                  itemToEdit?.tier && {
                    label: adminTier?.results
                      .filter((ii) => ii.id === itemToEdit?.tier)
                      .map((it) => it.name),
                    value: itemToEdit.tier,
                  }
                }
                onChange={(e) => setValue("tier", e.value)}
                options={tiersOptions}
              />
            </div>
            <div className="flex flex-col items-start mt-4">
              <p className="font-medium mb-2">Status</p>
              <div className="flex flex-row items-center mt-[7px] gap-[10px] justify-center">
                {watch("status") ? (
                  <div className="flex flex-row items-center mt-[5px]">
                    <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#9BC368]" />
                    <p className="text-[#9BC368] text-[10px] font-bold">
                      Active
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-row flex-nowrap items-center mt-[5px]">
                    <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#A52C38] " />
                    <p className="text-[#A52C38] text-[10px] font-bold">
                      Inactive
                    </p>
                  </div>
                )}

                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, value, name } }) => (
                    <Switch
                      activeBoxShadow={""}
                      uncheckedIcon={false}
                      checkedIcon={true}
                      checked={value}
                      name={name}
                      onChange={onChange}
                      onColor={context.mainColor}
                      offColor={context.secondaryColor}
                    />
                  )}
                />
              </div>
            </div>
            <div className="mt-6 w-full">
              <ImageUpload
                image={mydata.image || itemToEdit?.icon}
                data={mydata}
                setData={setData}
                className="w-1/2"
              />{" "}
              {/* {watch["image"] && (
                <p className="text-left text-[12px] text-[#cc0000]">
                  You must upload an image
                </p>
              )} */}
            </div>

            <div className="flex items-center justify-end mt-7 w-full pt-5 border-t-1 border-solid border-color rounded-b">
              <button
                style={{
                  border: `2px solid ${context.mainColor}`,
                  color: context.mainColor,
                }}
                className="flex flex-row flex-nowrap justify-center items-center whitespace-nowrap min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-xs lg:text-tiny outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                style={{
                  color: "#fff",
                  border: `2px solid ${context.mainColor}`,
                  backgroundColor: context.mainColor,
                }}
                className="flex whitespace-nowrap ml-[15px] flex-row flex-nowrap justify-center items-center min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-xs lg:text-tiny outline-none focus:outline-none ease-linear transition-all duration-150"
                type="submit"
              >
                {itemToEdit ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </FormModalBody>
      </ModalSkeleton>
    </>
  );
}

export default RewardModal;
