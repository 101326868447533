import { useRef } from "react";
import { useOnClickOutside } from "../hooks";
import { Link } from "react-router-dom";
import { useAppContext } from "../Context/AppContext";

function NavMenu({ display, setVisible, title, items }) {
  const wrapperRef = useRef();
  const context = useAppContext();

  useOnClickOutside(wrapperRef, () => setVisible(false));
  const menuStyle = `
  text-base
  z-[100]
  min-w-[150px]
  ${title === "Notifications" ? "lg:min-w-[300px]" : "lg:min-w-[150px]"}
  min-h-[150px]
  absolute
  top-[55px]
  right-[-10px]
  py-[5px]
  px-[10px]
  lg:py-[10px]
  lg:px-[20px]
  bg-white
  rounded-[10px]
  duration-500
  shadow-dark
  before:bg-white
  before:absolute
  content-['']
  before:w-[20px]
  before:h-[20px]
  before:top-[-5px]
  before:right-[18px]
  before:rotate-45`;

  return (
    <div
      ref={wrapperRef}
      style={{ display: !display ? "none" : "" }}
      className={menuStyle}
    >
      <h3 className="w-full pt-[5px] lg:pt-[10px] pb-[15px] lg:pb-[20px] text-main-text text-tiny lg:text-lg text-center font-bold">
        {title}
      </h3>
      {title === "Notifications" ? (
        <div className={`${items?.length > 0 ? "h-52" : ""} overflow-y-auto`}>
          {items?.length > 0 ? (
            [...items]
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              .map((el, index) => (
                <div
                  key={index}
                  className="border-t-1 py-3 px-2 cursor-pointer border-color max-w-2xl"
                >
                  <p className="flex-[2] capitalize text-[14px] text-main-text  text-left overflow-hidden min-w-0 ">
                    {el.text}
                  </p>
                  <p className="flex-1 mt-2 overflow-ellipsis text-[12px] text-[#9FA9AF] whitespace-nowrap overflow-hidden min-w-0 ">
                    {new Date(el.created)?.toLocaleDateString("en-GB")}
                    {""} {new Date(el.created)?.toLocaleTimeString("en-GB")}
                  </p>
                </div>
              ))
          ) : (
            <p
              style={{ color: context.mainColor }}
              className="text-center p-[10px] border-t-1  border-color"
            >
              No data to show
            </p>
          )}
        </div>
      ) : (
        <ul>
          {items &&
            items.map((el, index) => (
              <li
                onClick={el.onClick}
                key={index}
                className="w-full flex justify-center border-t-1 border-color"
              >
                <Link
                  to={el.url}
                  className="block text-xxs lg:text-base w-full h-full py-[5px] lg:py-[10px] text-main-text bg-white hover:bg-input-background"
                >
                  {el.name}
                </Link>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

export default NavMenu;
