import React, { useState, useEffect } from "react";
import { useAppContext } from "../Context/AppContext";
import { ReactComponent as Points } from "../assets/svg/points.svg";
import {
  useDeleteAdminRewardMutation,
  useAdminTeirsQuery,
} from "../store/admin/adminApi";
import placeholderImg from "../assets/images/mobile.png";

import { useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown";
import Modal from "./Modal";
import { toast } from "react-toastify";
import congratsImage from "../assets/images/congrats.png";
import warning from "../assets/images/warning.png";

function RewardCard({ element, setItemToEdit, setShowModal }) {
  const context = useAppContext();
  const navigate = useNavigate();
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [
    deleteAdminReward,
    { isError, isSuccess, error, isLoading: isDeleteLoading },
  ] = useDeleteAdminRewardMutation();
  function handleDeleteReward() {
    if (element.id) {
      deleteAdminReward({ id: element.id });
    }
  }
  const { data: adminTier, isLoading } = useAdminTeirsQuery();

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
      context.setAndShowModalData({
        title: "Reward Deleted Successfully!!",
        subtitle: ``,
        description: "",
        image: congratsImage,
      });
      navigate("/dashboard/rewards");
    }
    if (isError) {
      toast.error((error?.data && error?.data?.msg) || "something went wrong!");
    }
  }, [isSuccess, isError]);
  return (
    <div className="w-[100%] 2lg:w-[50%] mt-[16px] px-[8px]">
      <div className="relative w-full h-full flex-initial flex flex-row bg-white shadow-dark items-center rounded-[16px]">
        <div className="h-full w-[129px] rounded-l-2xl object-cover">
          <img
            src={element.icon ? element.icon : placeholderImg}
            alt="reward"
            className="h-full w-full object-cover  rounded-l-2xl"
          />
        </div>
        <div className="w-full h-full flex flex-col justify-center p-4">
          <div className="flex flex-row justify-between items-center">
            <p className="text-[10px] text-[rgb(15,40,55,0.5)]">
              ID: {element.id}
            </p>
            <Points
              style={{ color: context.mainColor }}
              onClick={() => setIsOpen(true)}
              className="cursor-pointer"
            />
            <Dropdown
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              minWidth="135px"
              minHeight="30px"
              top="44px"
              right="0px"
              items={[
                <div
                  onClick={() => {
                    setShowModal(true);
                    setItemToEdit(element);
                  }}
                >
                  Edit
                </div>,
                <div
                  onClick={() => {
                    setShowModalDelete(true);
                    setIsOpen(false);
                  }}
                >
                  Delete
                </div>,
              ]}
            />
          </div>
          <div className="flex flex-row justify-between items-center mt-[5px]">
            <p className="text-[16px] text-[#0f2837] font-bold">
              {element.name}
            </p>
            <p
              style={{ color: context.mainColor }}
              className="text-[10px] font-bold"
            >
              {element.points}pts
            </p>
          </div>
          <div className="flex flex-row justify-between items-center mt-[5px]">
            <p className="text-[12px] text-[#0f2837] ">
              Earned By:{" "}
              <span style={{ color: context.mainColor }} className="font-bold">
                {element.earned_by} Users
              </span>
            </p>
            <p className="text-[12px] text-[#9FA9AF] mr-[16px] ">
              Created On:{" "}
              <span className="font-bold">{element.created_at}</span>
            </p>
          </div>
          <p className="text-[12px] text-[#0f2837] mt-[5px]">
            {element.description}
          </p>
          {element.tier && (
            <p className="text-[12px] text-[#0F2837] mt-[5px]">
              Tier:{" "}
              {adminTier?.results
                .filter((ii) => ii.id === element.tier)
                .map((it) => it.name)}
            </p>
          )}
          {element.status.toLowerCase() === "active" ? (
            <div className="flex flex-row items-center mt-[5px]">
              <div className="h-[9px] w-[9px] rounded-full mr-[5px] bg-[#9BC368]" />
              <span className="text-[#9BC368] text-[10px] font-bold">
                Active
              </span>
            </div>
          ) : (
            <div className="flex flex-row items-center mt-[5px]">
              <div className="h-[9px] w-[9px] rounded-full mr-[5px] bg-[#A52C38] " />
              <span className="text-[#A52C38] text-[10px] font-bold">
                Inactive
              </span>
            </div>
          )}
        </div>
        <Modal
          isDelete={true}
          showModal={showModalDelete}
          setShowModal={setShowModalDelete}
          image={warning}
          isActive={true}
          title={"Delete Reward"}
          isLoading={isDeleteLoading}
          deleteItem={handleDeleteReward}
          subtitle={
            <span>
              Are you sure you want to delete
              <span className="text-warning font-bold"> {element.name} </span>
              reward
            </span>
          }
        />
      </div>
    </div>
  );
}

export default RewardCard;
