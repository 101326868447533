import axios from "axios";
// eslint-disable-next-line no-unused-vars
import Cookies from "js-cookie";

export const BASE_URL = process.env.REACT_APP_API;

// const token = `Bearer ${Cookies.get("access_token")}`;

// const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjc0NzI3NTI3LCJpYXQiOjE2NzQ2NDExMjcsImp0aSI6IjNmYTM1YjFiNWVkODRiZWU4NzQ3MTRiNTA5MmUxNjZlIiwidXNlcl9pZCI6NTN9.GkVR0QbgscPOXKtb7TRhyWFr_tQebGMfRJR9X0k23tQ`;
export const BASE_HEADERS = (headers) => {
  headers.set("Authorization", `Bearer  ${Cookies.get("token")}`);
  return headers;
};

export const BASE_AXIOS_HEADERS = () => ({
  Authorization: `Bearer  ${Cookies.get("token")}`,
});

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: BASE_AXIOS_HEADERS(),
});
