import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Student } from "../../../assets/svg/student.svg";
import { useAppContext } from "../../../Context/AppContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Input from "../../../components/input";
import { useCreateProfileMutation } from "../../../store/admin/adminApi";
import { useGetUsersQuery } from "../../../store/admin/usersApi";
import { useEffect } from "react";
import { toast } from "react-toastify";
import congratsImage from "../../../assets/images/congrats.png";

function AddUser() {
  const context = useAppContext();
  const [gender, setGender] = useState("");
  const [indexPersona, setselectedIndexPersona] = useState(null);
  const persona = ["Student", "Student0", "Student1", "Student2", "Student3"];

  const [createProfile, { isSuccess, isError, error }] =
    useCreateProfileMutation();
  const { data: users } = useGetUsersQuery();

  const schema = yup
    .object({
      first_name: yup.string().required("User name is required"),
      last_name: yup.string().required("Last name is required"),

      email: yup
        .string()
        .email("please enter a valid email")
        .required("Please enter an email"),
      phone: yup.string().required("please enter a phone number"),
      gender: yup.string().nullable().required("Please Select gender"),
      persona: yup.string().nullable().required("Please Select persona"),
      points: yup.string().required("Please enter initial points"),
    })
    .required();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const values = getValues();
  const navigate = useNavigate();

  const submitData = async (data) => {
    if (users.results?.find((ii) => ii.user_info?.email === data.email)) {
      toast.error("Email has already exist");
    } else {
      createProfile({
        ...data,
      });
    }
  };

  const test = () => {
    context.setAndShowModalData({
      title: "User Added Successfully!!",
      subtitle: ``,
      description: "",
      image: congratsImage,
    });
    navigate("/dashboard/users", { state: "success" });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Success", "user has been added successfully", test());
    }

    if (isError)
      toast.error((error?.data && error?.data?.msg) || "something went wrong!");
  }, [isError, isSuccess]);

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <form
        onSubmit={handleSubmit(submitData)}
        className="w-full min-h-screen p-6 2lg:p-14 text-main-text"
      >
        <p className="text-xs mb-2">
          <Link to={"/dashboard/users"}>Users</Link> &gt;{" "}
          <Link to={"/dashboard/users-add"}>Add New User</Link>
        </p>
        <p className="mb-10 font-bold text-tiny lg:text-2xl">Add New User</p>
        <p className="mb-2 font-bold text-tiny lg:text-base ">
          New User Profile
        </p>
        <p className="mb-9 text-[#87949B] text-xs lg:text-tiny">
          Fill in the details of the user using the sections below
        </p>
        <div className="w-full h-[1px] mb-9 border-devider-color border-b-1"></div>
        <div className="w-full flex flex-row items-start mb-9">
          <div className="w-[20%]">
            <p className="mb-2 font-bold text-xs lg:text-tiny">User Persona</p>
            <p className=" text-[#87949B] text-xxs lg:text-xs">
              Select the user’s persona from the provided list
            </p>
          </div>
          <div className="w-[80%] pl-16 flex flex-row flex-wrap gap-4">
            {persona.map((item, index) => (
              <label
                key={index}
                onClick={() => {
                  setselectedIndexPersona(index);
                }}
                style={{
                  backgroundColor:
                    indexPersona === index ? context.mainColor : "white",
                  color: indexPersona === index ? "white" : "#0f2837",
                }}
                className="flex flex-row flex-nowrap cursor-pointer rounded-lg shadow-dark-2 w-fit p-6"
              >
                <span className="mr-2">
                  <Student />
                </span>
                <span className="select-none">{item}</span>
                <input
                  className="peer hidden"
                  type="radio"
                  {...register("persona")}
                  // id="male"
                  value={item}
                  hidden={true}
                />
              </label>
            ))}
            {errors.persona && (
              <span className="text-red p-1 text-xs">
                {errors.persona?.message}
              </span>
            )}
          </div>
        </div>
        <div className="w-full h-[1px] mb-9 border-devider-color border-b-1"></div>
        <div className="w-full flex flex-row items-start mb-9">
          <div className="w-[20%]">
            <p className="mb-2 font-bold text-xs lg:text-tiny">User Details</p>
            <p className=" text-[#87949B] text-xxs lg:text-xs">
              Fill in user details such as name and contact info
            </p>
          </div>
          <div className="w-[80%] pl-16">
            <div className="flex flex-row flex-wrap items-start gap-4 ">
              <div className="flex-initial min-w-[200px] max-w-[500px] mb-[20px]">
                <Input
                  label={"First Name"}
                  placeholder="User's First Name"
                  type="text"
                  {...register("first_name")}
                />
                {errors.first_name && (
                  <span className="text-red p-1 text-xs">
                    {errors.first_name?.message}
                  </span>
                )}
              </div>
              <div className="flex-initial min-w-[200px] max-w-[500px] mb-[20px]">
                <Input
                  label={"Last Name"}
                  placeholder="User's Last Name"
                  type="text"
                  {...register("last_name")}
                />
                {errors.last_name && (
                  <span className="text-red p-1 text-xs">
                    {errors.last_name?.message}
                  </span>
                )}
              </div>
              <div className="flex-initial min-w-[200px] max-w-[500px] mb-[20px]">
                <Input
                  label="Email"
                  placeholder="User's Email"
                  type="email"
                  {...register("email")}
                />
                {errors.email && (
                  <span className="text-red p-1 text-xs">
                    {errors.email?.message}
                  </span>
                )}
              </div>
              <div className="flex-initial min-w-[200px] max-w-[500px] mb-[20px]">
                <Input
                  label="Phone Number"
                  placeholder="User's Phone Number"
                  type="tel"
                  {...register("phone")}
                />

                {errors.phone && (
                  <span className="text-red p-1 text-xs">
                    {errors.phone?.message}
                  </span>
                )}
              </div>
            </div>
            <div>
              <p className="my-5 font-bold text-xxs lg:text-xs">Gender</p>
              <div className="flex flex-row flex-nowrap justify-start items-center">
                <label
                  onClick={() => {
                    setGender("male");
                    // setData({ ...data, gender: "male" });
                  }}
                  style={{
                    backgroundColor:
                      gender === "male" ? context.mainColor : "white",
                    color: gender === "male" ? "white" : "#0f2837",
                  }}
                  className="flex flex-row flex-nowrap justify-center items-center cursor-pointer min-w-[107px] min-h-[50px] rounded-lg p-4 text-white shadow-dark-2 font-bold text-center text-xs lg:text-tiny border-none outline-none"
                >
                  <span className="select-none">Male</span>
                  <input
                    className="peer hidden"
                    type="radio"
                    {...register("gender")}
                    id="male"
                    value="male"
                    name="gender"
                    hidden={true}
                  />
                </label>

                <label
                  onClick={() => {
                    setGender("female");
                    // setData({ ...data, gender: "female" });
                  }}
                  style={{
                    backgroundColor:
                      gender === "female" ? context.mainColor : "white",
                    color: gender === "female" ? "white" : "#0f2837",
                  }}
                  className="flex flex-row flex-nowrap justify-center items-center cursor-pointer font-bold bg-white border-none outline-none min-w-[107px] min-h-[50px] rounded-lg ml-3 shadow-dark-2 w-fit p-4 text-xs lg:text-tiny"
                >
                  <span className="select-none">Female</span>
                  <input
                    className="peer hidden"
                    type="radio"
                    {...register("gender")}
                    id="female"
                    value={"female"}
                    name="gender"
                    hidden={true}
                  />
                </label>
              </div>
              {errors.gender && (
                <span className="text-red p-1 text-xs">
                  {errors.gender?.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] mb-9 border-devider-color border-b-1"></div>
        <div className="w-full flex flex-row items-start mb-9">
          <div className="w-[20%]">
            <p className="mb-2 font-bold text-xs lg:text-tiny">Points</p>
            <p className=" text-[#87949B] text-xxs lg:text-xs">
              Select the amount of points to be granted to the user
            </p>
          </div>
          <div className="w-[80%] pl-16">
            <div className="max-w-[200px] mb-[20px]">
              <Input
                label="Initial Points"
                placeholder="Add total initial points"
                type="number"
                {...register("points")}
              />

              {errors.points && (
                <span className="text-red p-1 text-xs">
                  {errors.points?.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] mb-9 border-devider-color border-b-1"></div>
        <div className="w-full flex justify-end items-center">
          <div className="flex flex-row flex-nowrap justify-center items-center">
            <Link
              to={"/dashboard/users"}
              style={{
                border: `2px solid ${context.mainColor}`,
                color: context.mainColor,
              }}
              className="flex flex-row flex-nowrap justify-center items-center rounded-[4px] min-w-[88px] min-h-[33px] px-4 py-2 bg-transparent font-bold text-center text-xxs 2lg:text-xs lg:text-tiny outline-none"
            >
              <span>Cancel</span>
            </Link>
            <button
              type="submit"
              style={{
                border: `2px solid ${context.mainColor}`,
                backgroundColor: context.mainColor,
              }}
              className="flex flex-row flex-nowrap justify-center items-center rounded-[4px] min-w-[88px] min-h-[33px] px-4 py-2 ml-3 text-white font-bold text-center text-xxs 2lg:text-xs lg:text-tiny outline-none"
            >
              <span>Create User</span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddUser;
