import React from "react";
import frame1 from "../assets/images/frame11.png";
import frame2 from "../assets/images/frame22.png";
import frame3 from "../assets/images/frame33.png";

function ActiveUsersCard({ city, count }) {
  return (
    <div className="relative h-[72px]  w-[298px] bg-white flex-initial shadow-[0px_4px_10px_rgba(15,40,55,0.05)] rounded-b-[10px]">
      <div className="w-full h-1 bg-primary-one absolute top-0 rounded-t-[10px]" />
      <div className="flex items-center w-full h-full pl-8 pr-2">
        <div className="flex flex-col w-full mr-[10px]">
          <div className="relative ">
            <p className="text-2xl font-bold text-[#232323] absolute z-10">
              {count}
            </p>
            <div className="w-[58px] h-[9px] bg-yellow-orange absolute top-[20px] z-[0]" />
          </div>
          <div
            className={`${
              city === "dammam"
                ? "text-[#67ABDF]"
                : city === "jeddah"
                ? "text-[#BF7F16]"
                : "text-primary-one"
            } text-xs font-bold mt-[28px] w-full`}
          >
            Active Users
          </div>
        </div>
        <img
          src={
            city === "jeddah"
              ? frame2
              : city === "dammam"
              ? frame3
              : frame1
          }
          alt={city}
          className="w-[70%] h-full"
        />
      </div>
    </div>
  );
}

export default ActiveUsersCard;
