import { useEffect } from "react";
import { toast } from "react-toastify";
import { ReactComponent as XCloseCircle } from "../../../assets/svg/x-circle.svg";
import FormModal from "../../../components/FormModal";
import congratsImage from "../../../assets/images/congrats.png";
import {
  useRedeemPointsMutation,
  useStatsQuery,
} from "../../../store/user/userApi";
import placeholderImg from "../../../assets/images/mobile.png";

import { useAppContext } from "../../../Context/AppContext";
import { Button } from "@mantine/core";

export default function RewardDetailsModal({
  showModal,
  setShowModal,
  reward,
}) {
  const [redeemPoints, { isSuccess, isError, error, isLoading }] =
    useRedeemPointsMutation();
  const { data: userStats } = useStatsQuery();
  const context = useAppContext();

  useEffect(() => {
    if (isSuccess) {
      setShowModal(false);
      context.setAndShowModalData({
        title: "Congrats!! You have redeemed your points!",
        subtitle: `You have just earned the ${reward.name || "Apple phone"}`,
        description: `One of our representatives will reach out to you to deliver your reward. Your remaining points are ${
          userStats?.info?.points - reward.points
        }`,
        image: congratsImage,
      });
    }
    if (isError) toast(error.data, { autoClose: 4000, toastId: "45654654" });
  }, [isSuccess, isError, error?.data, setShowModal]);

  return (
    <>
      <FormModal showModal={showModal} setShowModal={setShowModal}>
        <div className="font-Effra">
          <div className="absolute top-[16px] right-[16px] z-10 w-[24px] h-[24px]">
            <XCloseCircle
              onClick={() => setShowModal(false)}
              className="w-full h-full cursor-pointer"
            />
          </div>
          {/*content*/}
          <div className="border-0 p-4  relative flex flex-col w-full max-w-[485px] outline-none focus:outline-none">
            <h1 className="font-bold text-2xl mb-8">
              You are about to earn the following reward:
            </h1>
            <img
              className="w-[70%] h-[70%] ml-16 rounded-[5px] mb-2"
              src={reward.icon || placeholderImg}
              alt="Redeem"
            />
            <div className="flex w-full justify-between items-center mt-2 mb-1">
              <p className="text-base font-normal text-main-text">
                {reward.title}
              </p>
              <p
                className={`text-xs font-bold ${
                  reward.status === "active" ? "text-green" : " text-[#EB5757]"
                } capitalize`}
              >
                {reward.status}
              </p>
            </div>
            <p className="text-primary-one text-base font-bold">{`${reward.points}pts`}</p>

            {userStats?.info?.points >= reward?.points ? (
              <p className="font-normal text-tiny text-main-text opacity-50">
                Your remaining points will be{" "}
                {userStats?.info?.points - reward.points}
              </p>
            ) : (
              <p className="font-normal text-tiny text-main-text opacity-50">
                You don't have sufficient points
              </p>
            )}
            <div className="flex items-center justify-center p-8 mt-8">
              <Button
                onClick={() => redeemPoints({ reward_id: reward.id })}
                isLoading={isLoading}
                disabled={userStats?.info?.points < reward?.points || isLoading}
                className="flex h-[43px] px-16  flex-row flex-nowrap justify-center items-center rounded hover:bg-gradient-primary bg-gradient-primary text-white font-bold text-center orange-shadow outline-none"
              >
                <span className="text-base font-bold">Redeem Points</span>
              </Button>
            </div>
          </div>
        </div>
      </FormModal>
    </>
  );
}
