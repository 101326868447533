import React, { useState } from "react";
import { useAppContext } from "../Context/AppContext";
import { ReactComponent as ArrowUp } from "../assets/svg/ArrowUp.svg";
import { useCardStatsQuery } from "../store/admin/adminDashboardApi";

function Card1({ icon, endpoint }) {
  const context = useAppContext();
  const [isActive, setIsActive] = useState(false);
  const { data: cardData } =
    useCardStatsQuery(endpoint);
  function getColor() {
    if (
      isActive &&
      (context.mainColor === "#5E45FF" || context.mainColor === "#FFB65E")
    ) {
      return "#FDB236";
    }

    if (!isActive) {
      return "#0f2837";
    }

    if (
      isActive &&
      (context.mainColor === "#6E1946" || context.mainColor === "#0f2837")
    ) {
      return "#fff";
    }
  }

  return (
    <div
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      className="w-[100%] sm:w-[50%]  3lg:w-[33.3333%] flex-initial px-[7px] mt-[15px]"
    >
      <div
        className="relative w-full h-full p-4 rounded-[10px] transition-transform duration-500"
        style={
          isActive
            ? {
                backgroundColor: context.mainColor,
                boxShadow: `box-shadow: 0px 4px 32px 0px #5E45FF80;

                `,
                transform: "translateY(-8px)",
              }
            : {
                backgroundColor: "#ffffff",
                boxShadow: `0 4px 32px 0px rgb(15,40,55,0.1)`,
                transform: "translateY(0px)",
              }
        }
      >
        <div className="bg-card-bg absolute inset-0 bg-no-repeat bg-right-top opacity-25"></div>

        <div
          className="flex justify-center items-center w-[50px] h-[45px] p-[10px] mb-[12px] rounded-[5px]"
          style={
            isActive
              ? {
                  backgroundColor: "#fff",
                  boxShadow: `0 0px 24px -8px ${context.shadowColor}`,
                }
              : {
                  backgroundColor: context.mainColor,
                  boxShadow: `0 0px 24px -8px ${context.shadowColor}`,
                }
          }
        >
          <span
            style={isActive ? { color: context.mainColor } : { color: "#fff" }}
          >
            {icon}
          </span>
        </div>
        <p
          className="mb-[8px] font-bold text-lg md:text-2xl"
          style={isActive ? { color: "#fff" } : { color: context.mainColor }}
        >
          {cardData?.total_from_this_month || 0}
        </p>
        <p
          className=" mb-[8px] font-bold text-base md:text-lg"
          style={{
            color: getColor(),
          }}
        >
          {cardData?.name}
        </p>

        <div className="flex">
          <ArrowUp
            className={
              isActive &&
              (context.mainColor === "#5E45FF" ||
                context.mainColor === "#FFB65E")
                ? "text-white mt-[5px] w-[12px] h-[12px]"
                : "text-[#27AE60] mt-[5px] w-[12px] h-[12px]"
            }
          />
          <div className="pl-[8px]">
            <p
              className={
                isActive &&
                (context.mainColor === "#5E45FF" ||
                  context.mainColor === "#FFB65E")
                  ? "text-white text-tiny"
                  : "text-[#27AE60] text-tiny"
              }
            >
              {(cardData?.increasing_percent_from_last_month >= 0
                ? cardData?.increasing_percent_from_last_month.toFixed(0)
                : 0) + " from last month"}
            </p>
            <p
              className={
                isActive &&
                (context.mainColor === "#5E45FF" ||
                  context.mainColor === "#FFB65E")
                  ? "text-white text-xs"
                  : "text-sub-text text-xs"
              }
            >
              {`${cardData?.total_from_last_month || 0}  user from last month`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card1;
