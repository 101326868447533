import { useEffect, useMemo, useState, useCallback, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Points } from "../../../assets/svg/points.svg";
import Dropdown from "../../../components/Dropdown";
import Modal from "../../../components/Modal";
import warning from "../../../assets/images/warning.png";
import congratsImage from "../../../assets/images/congrats.png";
import { toast } from "react-toastify";
import { reducer, initialState } from "./filtersReducer";
import { useAppContext } from "../../../Context/AppContext";
import { ReactComponent as Add } from "../../../assets/svg/add.svg";
import { StatusPill } from "../../../components/table/StatusPill";
import Table from "../../../components/table/Table";
import { Loading } from "../../../components/Loading";
import { SelectColumnFilter } from "../../../components/table/SelectColumnFilter";
import ActivityModal from "../../../components/forms/ActivityModal";
import {
  useActivitiesQuery,
  usePlatformsQuery,
  useDeleteActivityMutation,
} from "../../../store/admin/adminApi";

function Activities() {
  const context = useAppContext();
  const [itemToEdit, setItemToEdit] = useState({});
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [isOpen, setIsOpen] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);

  const onSearch = useCallback(
    (value) =>
      dispatch({
        type: "search",
        payload: { value },
      }),
    []
  );

  const onOrder = useCallback((value) => {
    dispatch({
      type: "sortBy",
      payload: { value },
    });
  }, []);
  const onFilter = useCallback((value) => {
    dispatch({
      type: "filterBy",
      payload: { value },
    });
  }, []);
  useEffect(() => {
    if (context.keyword?.length > 0) {
      onSearch(context.keyword);
    } else onSearch("");
  }, [context.keyword]);

  const {
    data: activities,
    isLoading,
    refetch,
  } = useActivitiesQuery({
    // limit: limit,
    offset: offset,
    ordering: "-created_at",
    ...state.vars,
  });
  console.log(state.vars);
  const { data: platforms } = usePlatformsQuery();
  const navigate = useNavigate();

  const [
    deleteActivity,
    { isSuccess, isError, error, isLoading: deleteActLoading },
  ] = useDeleteActivityMutation();
  function handleDelete() {
    if (itemToEdit.id) {
      deleteActivity({ id: itemToEdit.id });
    }
  }
  useEffect(() => {
    if (isSuccess) {
      setShowModalDelete(false);
      context.setAndShowModalData({
        title: "Activitity Deleted Successfully!!",
        subtitle: ``,
        description: "",
        image: congratsImage,
      });
      navigate("/dashboard/activities");
    }
    if (isError) {
      toast.error((error?.data && error?.data?.msg) || "something went wrong!");
    }
  }, [isSuccess, isError, error?.data]);
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableSortBy: true,
      },
      {
        Header: "Activity Name",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Description",
        accessor: "description",
        disableSortBy: true,
      },
      {
        Header: "Points",
        accessor: "points",
      },
      {
        Header: "Platform",
        accessor: "platform",
        Cell: ({ cell }) =>
          platforms?.results
            .filter((ii) => ii.id === cell.value)
            .map((it) => it.name),
      },
      {
        Header: "Date Created",
        accessor: "created_at",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "More",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div className="relative mx-auto flex justify-center items-center group-hover:bg-table-line-two w-[26px] h-[26px] rounded-[5px]">
              <Points
                style={{ color: context.mainColor }}
                className="cursor-pointer absolute z-10"
                onClick={() => {
                  setIsOpen(row.original.id);
                  setItemToEdit(row.original);
                }}
              />
              <Dropdown
                isOpen={isOpen === row.original.id}
                setIsOpen={setIsOpen}
                minWidth="135px"
                minHeight="30px"
                top="100%"
                right="-50px"
                className="z-20"
                items={[
                  <div
                    onClick={() => {
                      setShowModalAdd(true);
                      setIsOpen("");
                    }}
                    className="group-hover:text-main-text"
                  >
                    Edit
                  </div>,
                  <div
                    onClick={() => {
                      setShowModalDelete(true);
                      setIsOpen("");
                    }}
                    className="group-hover:text-main-text"
                  >
                    Delete{" "}
                  </div>,
                ]}
              />
            </div>
          );
        },
      },
    ],
    [platforms?.results, isOpen]
  );

  return (
    <div className="w-full min-h-screen p-6 2lg:p-14">
      <p className="text-xs mb-2">
        <Link to={"/dashboard/activities"}>Activities</Link> &gt;
      </p>
      <div className="mb-10 flex flex-row justify-between items-center">
        <p className="font-bold text-tiny lg:text-2xl">All Activities</p>
        <button
          onClick={() => {
            setItemToEdit(null);
            setShowModalAdd(true);
          }}
          style={{
            border: `2px solid ${context.mainColor}`,
            backgroundColor: context.mainColor,
          }}
          className="flex h-[44px] p-[7px] flex-row flex-nowrap justify-center items-center rounded-[4px] ml-3 text-white font-bold text-center text-xxs 2lg:text-xs lg:text-tiny outline-none"
        >
          <Add className="h-[20px] w-[20px] mr-[10px]" />
          <span className="text-[12px]">Create New Activity</span>
        </button>
      </div>

      {showModalAdd && (
        <ActivityModal
          reFetch={refetch}
          showModal={showModalAdd}
          setShowModal={setShowModalAdd}
          itemToEdit={itemToEdit}
        />
      )}
      <Modal
        isDelete={true}
        showModal={showModalDelete}
        setShowModal={setShowModalDelete}
        image={warning}
        isActive={true}
        isLoading={deleteActLoading}
        title={"Delete Activity"}
        deleteItem={handleDelete}
        subtitle={
          <span>
            Are you sure you want to delete
            <span className="text-warning font-bold">
              {" "}
              {itemToEdit?.name}
            </span>{" "}
            activity ?
          </span>
        }
      />
      <div className="-mt-4">
        {isLoading ? (
          <div className="flex justify-center w-full my-[100px]">
            <Loading />
          </div>
        ) : activities?.results?.length > 0 ? (
          <Table
            columns={columns}
            data={activities?.results}
            setLimit={setLimit}
            limit={limit}
            setOffset={setOffset}
            count={activities?.count}
            setSortBy={onOrder}
            setFilterBy={onFilter}
            stopSortFilterManually
          />
        ) : (
          <p>No Activities Found</p>
        )}
      </div>
    </div>
  );
}

export default Activities;
