import React from "react";
import SupportForm from "./components/SupportForm";

const SupportPage = () => {
  return (
    <div className="w-full h-full  flex justify-center items-center pt-10 pb-32 support-bg">
      <div className="flex flex-col justify-center items-center gap-10">
        <h2 className="text-center font-extrabold text-2xl md:text-4xl">
          Report any problems you`re Facing,
          <br />
          Our team will get back to you
          <p className="text-[#5E45FF] relative">
            As soon as Possible!{" "}
            <span className="-rotate-45 absolute right-[15%]">⌛</span>
          </p>
        </h2>
        <SupportForm />
      </div>
    </div>
  );
};

export default SupportPage;
