import Select, { components } from "react-select";
import { SortDownIcon } from "../../../components/shared/Icons";
import { ReactComponent as SearchIcon } from "../../../assets/svg/search-line.svg";

const rewardTypeOptions = [
  { value: "", label: "Rewards Types" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "In Active" },
];

const pointsOptions = [
  { value: "", label: "Points Range" },
  { value: "0-10", label: "0-10" },
  { value: "10-1000", label: "10-1000" },
  { value: "1000-5000", label: "1000-5000" },
];
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SortDownIcon className="text-[18px] text-main-text mb-[6px]" />
    </components.DropdownIndicator>
  );
};

function RewardsFilters({ setFilter, filter }) {
  return (
    <div className="flex flex-row flex-wrap gap-4 mb-6">
      <div className="shadow-select-filter rounded w-fit">
        <Select
          className="text-center text-xs font-bold min-w-[120px] text-main-text capitalize"
          name="eventType"
          defaultValue={{ value: "", label: "Rewards Types" }}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
          onChange={(e) => setFilter((prev) => ({ ...prev, type: e.value }))}
          value={
            filter.type
              ? { value: filter.type, label: filter.type }
              : {
                  value: "",
                  label: "Rewards Types",
                }
          }
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              padding: "0px 4px",
              boxShadow: "none",
            }),
          }}
          options={rewardTypeOptions}
        />
      </div>
      <div className="rounded shadow-select-filter min-w-[100px] w-fit">
        <Select
          className="text-center text-xs font-bold text-main-text"
          name="date"
          defaultValue={{ value: "", label: "Points Range" }}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              padding: "0px 4px",
              boxShadow: "none",
            }),
          }}
          value={
            filter.range
              ? {
                  value: filter.range,
                  label: filter.range.join("-") || "Point Range",
                }
              : {
                  value: "",
                  label: "Point Range",
                }
          }
          options={pointsOptions}
          onChange={(e) =>
            setFilter((prev) => ({ ...prev, range: e.value.split("-") }))
          }
        />
      </div>
      <div className="h-[38px] w-[402px] shadow-select-filter flex items-center pl-3">
        <SearchIcon className="relative w-[14px] h-[14px] mr-2" />
        <input
          className="placeholder:text-sm placeholder:font-bold placeholder:text-main-text border-none outline-none w-[90%] h-full bg-transparent"
          type="text"
          onChange={(e) =>
            setFilter((prev) => ({ ...prev, search: e.target.value }))
          }
          placeholder="Search by reward name.."
        />
      </div>
    </div>
  );
}
export default RewardsFilters;
