import { useState } from "react";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { useAppContext } from "../../../Context/AppContext";
import PaginationRow from "../../../components/PaginationRow";
import { ReactComponent as Add } from "../../../assets/svg/add.svg";
import { ReactComponent as FilterIcon } from "../../../assets/svg/filter.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { classNames } from "../../../components/shared/Utils";
import { SortDownIcon } from "../../../components/shared/Icons";
import SystemCard from "../../../components/SystemCard";
import { Loading } from "../../../components/Loading";
import {
  usePlatformsQuery,
  useUpdatePlatformMutation,
} from "../../../store/admin/adminApi";
import { useEffect } from "react";
import AddFormPlatform from "../../../components/forms/addForms/AddFormPlatform";
import FormModal from "../../../components/FormModal";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SortDownIcon className="text-[18px] text-main-text mb-[6px]" />
    </components.DropdownIndicator>
  );
};

function Systems() {
  const context = useAppContext();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filtredData, setFiltredData] = useState([]);
  const [limit, setLimit] = useState(8);
  const [offset, setOffset] = useState(0);
  const [statusFilter, setStatusFilter] = useState({
    value: "All",
    label: "All",
  });
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [itemToedit, setItemToedit] = useState(null);
  const {
    data: platforms,
    refetch,
    isLoading,
  } = usePlatformsQuery({
    limit: limit,
    offset: offset,
  });
  const [updatePlatforms] = useUpdatePlatformMutation();
  const [filteredData, setFilteredData] = useState([]);

  const statusOptions = [
    { value: "All", label: "All" },
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const resetFilter = () => {
    setStatusFilter({ value: "All", label: "All" });
  };

  useEffect(() => {
    if (platforms)
      setFilteredData(
        [...platforms.results]
          .filter((el) => {
            if (statusFilter.value === "All") return el;
            else {
              return el.status === statusFilter.value;
            }
          })
          .sort((a, b) => {
            return (
              new Date(b.date_of_integration) - new Date(a.date_of_integration)
            );
          })
      );
  }, [platforms, statusFilter]);
  useEffect(() => {
    context.keyword?.length > 0
      ? setFilteredData(
          platforms?.results?.filter((ii) =>
            ii.name.toLowerCase().includes(context.keyword.toLowerCase())
          )
        )
      : setFilteredData(platforms?.results);
  }, [platforms?.results, context?.keyword]);
  useEffect(() => localStorage.clear(), []);

  return (
    <div className="w-full min-h-screen p-6 2lg:p-14">
      <p className="text-xs mb-2">
        <Link to={"/dashboard/systems"}>Systems</Link> &gt;
      </p>
      <div className="mb-10 flex flex-row justify-between items-center">
        <p className="font-bold text-tiny lg:text-2xl">All Systems</p>
        <button
          onClick={() => {
            setItemToedit(null);
            setShowModalAdd(true);
          }}
          style={{
            border: `2px solid ${context.mainColor}`,
            backgroundColor: context.mainColor,
          }}
          className="flex h-[44px] p-[7px] flex-row flex-nowrap justify-center items-center rounded-[4px] ml-3 text-white font-bold text-center text-xxs 2lg:text-xs lg:text-tiny outline-none"
        >
          <Add className="h-[20px] w-[20px] mr-[10px]" />
          <span className="text-[12px]">Add New System</span>
        </button>
        <FormModal showModal={showModalAdd} setShowModal={setShowModalAdd}>
          <AddFormPlatform
            setShowModalAdd={setShowModalAdd}
            reFetch={refetch}
          />
        </FormModal>
        <FormModal showModal={showModalAdd} setShowModal={setShowModalAdd}>
          <AddFormPlatform
            reFetch={refetch}
            platformData={itemToedit}
            setShowModalAdd={setShowModalAdd}
          />
        </FormModal>
      </div>
      <div className="flex flex-row justify-start items-start gap-2 mb-2 -mt-5 mb-4">
        <div className="flex flex-row items-center gap-2">
          {/* <span className="text-main-text text-base font-bold">Filter:</span> */}
          <div
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
            style={{
              backgroundColor: isFiltersOpen
                ? "rgb(15,40,55)"
                : "rgb(236,239,239)",
              color: isFiltersOpen ? "#fff" : "",
            }}
            className="cursor-pointer min-w-[40px] min-h-[40px] w-[40px] h-[40px] px-2 py-1 rounded-[4px]"
          >
            <FilterIcon className="w-full h-full" />
          </div>
        </div>
        <div
          className={classNames(
            "flex-row flex-wrap items-center gap-4 px-4 py-3 rounded-md bg-white shadow-table-head-dark -mt-6 ml-2",
            isFiltersOpen ? "flex" : "hidden"
          )}
        >
          <span className="text-main-text text-base font-bold">Filters:</span>
          <div className="flex flex-row flex-wrap items-center gap-2">
            <label className="flex flex-row flex-wrap items-center">
              <span className="text-gray-700 mr-2">status: </span>
              <div className="shadow-table-head-dark rounded-lg w-fit">
                <Select
                  className="text-center text-xs font-bold"
                  value={
                    statusFilter.value
                      ? { value: statusFilter.value, label: statusFilter.label }
                      : {
                          value: "",
                          label: "Status",
                        }
                  }
                  components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setStatusFilter({ value: e.value, label: e.label });
                  }}
                  isSearchable={false}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: 0,
                      padding: "0px 4px",
                      boxShadow: "none",
                    }),
                  }}
                  options={statusOptions}
                />
              </div>
            </label>
          </div>
          <div
            onClick={() => resetFilter()}
            className="flex flew-row flex-nowrap justify-center items-center gap-1 px-2 py-1 text-white text-xs font-bold bg-primary-four hover:bg-primary-four/70 rounded-full cursor-pointer"
          >
            Clear <Close className="w-[12px] h-[12px]" />
          </div>
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-16  ml-[-15px] mr-[-15px] mt-16">
        {isLoading ? (
          <Loading />
        ) : filteredData?.length > 0 ? (
          filteredData?.map((item, index) => (
            <SystemCard
              item={item}
              key={index}
              updatePlatforms={updatePlatforms}
              setItemToedit={setItemToedit}
              setShowModalAdd={setShowModalAdd}
            />
          ))
        ) : (
          <p>No Platforms Found</p>
        )}
      </div>
      {/****** pagination ******* */}
      <PaginationRow
        count={platforms?.count}
        setLimit={setLimit}
        limit={limit}
        setOffset={setOffset}
      />
    </div>
  );
}

export default Systems;
