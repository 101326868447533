import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_HEADERS, BASE_URL } from "../../configs/DataService";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/gs/api/admin/profiles`,
    prepareHeaders: BASE_HEADERS,
  }),
  tagTypes: ["users"],
  endpoints: ({ query, mutation }) => ({
    getUsers: query({
      query: (params) => ({
        url: "",
        params: params,
      }),
      providesTags: ["Stats"],
    }),

    AddUser: mutation({
      query: (body) => ({
        url: "create_profile",
        method: "POST",
        body,
      }),
      invalidatesTags: ["users"],
    }),
    EditUser: mutation({
      query: (body) => ({
        url: "update_profile",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["users"],
    }),
    BlockUser: mutation({
      query: (body) => ({
        url: "de_activate",
        method: "POST",
        body,
      }),
      invalidatesTags: ["users"],
    }),
    sendEmail: mutation({
      query: ({ id }) => ({
        url: `https://core.verse-stg.tam.run/gs/api/admin/send_reminder_email/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["users"],
    }),
    importUsers: mutation({
      query: (body) => ({
        url: `https://core.verse-stg.tam.run/gs/api/admin/bulk_add_users`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["users"],
    }),
    presignUrl: query({
      query: () => ({
        url: "https://core.verse-stg.tam.run/gs/api/presigned_url",
      }),
      providesTags: ["Stats"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useEditUserMutation,
  useBlockUserMutation,
  useSendEmailMutation,
  useImportUsersMutation,
  usePresignUrlQuery,
} = usersApi;
