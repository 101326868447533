import Signin from "../screens/auth/Signin";
import Activities from "../screens/dashboardScreens/activities/Activities";
import Home from "../screens/dashboardScreens/Home";
import Settings from "../screens/dashboardScreens/settings/Settings";
import Systems from "../screens/dashboardScreens/systems/Systems";
import AddUser from "../screens/dashboardScreens/users/AddUser";
import EditUser from "../screens/dashboardScreens/users/EditUser";
import Users from "../screens/dashboardScreens/users/Users";

//icons import

import { ReactComponent as homeicon } from "../assets/svg/homeicon.svg";
import { ReactComponent as settings } from "../assets/svg/Settings.svg";
import { ReactComponent as rewards } from "../assets/svg/RewardsEarned.svg";
import { ReactComponent as users } from "../assets/svg/Users.svg";
import { ReactComponent as system } from "../assets/svg/IntegratedSystems.svg";
import { ReactComponent as badges } from "../assets/svg/BadgesEarned.svg";
import AdminProfile from "../screens/dashboardScreens/users/UserProfile";
import Badges from "../screens/dashboardScreens/badges/Badges";

import Rewards from "../screens/dashboardScreens/rewards/Rewards";
import Events from "../screens/usersScreens/Events";
import RewardsPage from "../screens/usersScreens/Rewards";
import UserPannel from "../screens/usersScreens/UserPannel";
import UserProfile from "../screens/usersScreens/UserProfile";
import EditMain from "../screens/usersScreens/EditProfile/EditMain";
import Tiers from "../screens/dashboardScreens/tiers/Tiers";
import EditProfile from "../screens/usersScreens/EditProfile/EditProfile";
import SupportPage from "../screens/usersScreens/support/SupportPage";

export const authRoutes = [
  {
    path: "login",
    component: <Signin />,
  },
];
export const usersRoutes = [
  {
    path: "events",
    component: <Events />,
  },
  {
    path: "rewards",
    component: <RewardsPage />,
  },
  {
    path: "support",
    component: <SupportPage />,
  },
  {
    path: "user-pannel",
    component: <UserPannel />,
  },
  {
    path: "user-profile",
    component: <UserProfile />,
  },
  {
    path: "edit-profile",
    component: <EditMain />,
  },
];

export const dashboardRoutes = [
  {
    path: "users-add",
    component: <AddUser />,
  },
  {
    path: "users-edit",
    component: <EditUser />,
  },
  // {
  //   path: "badges-grid",
  //   component: <BadgesGrid />,
  // },
  // {
  //   path: "badges-list",
  //   component: <BadgesList />,
  // },
  {
    path: "users-profile",
    component: <AdminProfile />,
  },
];

export const sidebarRoutes = [
  {
    name: "Dashboard",
    path: "home",
    component: <Home />,
    icon: homeicon,
  },

  {
    name: "Users",
    path: "users",
    component: <Users />,
    icon: users,
  },

  {
    name: "Badges",
    path: "badges",
    component: <Badges />,
    icon: badges,
  },
  {
    name: "Tiers",
    path: "tiers",
    component: <Tiers />,
    icon: badges,
  },
  {
    name: "Rewards",
    path: "rewards",
    component: <Rewards />,
    icon: rewards,
  },
  {
    name: "Activities",
    path: "activities",
    component: <Activities />,
    icon: badges,
  },
  // {
  //   name: "Tickets",
  //   path: "tickets",
  //   component: <Tickets />,
  //   icon: tickets,
  // },
  {
    name: "Systems",
    path: "systems",
    component: <Systems />,
    icon: system,
  },
  {
    name: "Settings",
    path: "settings",
    component: <Settings />,
    icon: settings,
  },
  {
    name: "edit-profile",
    path: "edit-profile",
    component: <EditProfile />,
    // icon: settings,
  },
];
