import React from "react";

function Card4({ bgImage, title, subtitle, onClick }) {
  return (
    <div
      onClick={onClick}
      className="relative cursor-pointer h-[219px] flex-initial w-[50%] sm:w-[33.3333%] 1lg:w-[50%] 2lg:w-[33.3333%] px-[7px] mt-[15px]"
    >
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
        }}
        className="w-full h-full p-4 bg-white bg-cover overflow-hidden rounded-[8px]"
      >
        <div className="text-main-text">
          <p className="font-bold text-base">{title}</p>
          <div>
            <p className="text-xs">{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card4;
