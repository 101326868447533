import React, { useState } from "react";
import { useAppContext } from "../../../Context/AppContext";
import InputText from "../../InputText";
import { toast, ToastContainer } from "react-toastify";
import Switch from "react-switch";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import congratsImage from "../../../assets/images/congrats.png";
import { useNavigate } from "react-router-dom";
import {
  useAddPlatformMutation,
  useUpdatePlatformMutation,
} from "../../../store/admin/adminApi";
import { useEffect } from "react";
import ImageUpload from "../../../utils/ImageUpload";

const schema = yup.object().shape({
  name: yup.string().required("Please Provide Platform Name"),
  // token: yup.string().required("Please Provide Platform Token"),
});

function AddFormPlatform({ setShowModalAdd, platformData, reFetch }) {
  // const [image, setImage] = useState();
  const [imageData, setImageData] = useState({
    image: platformData?.icon ? platformData?.icon : "",
  });

  const [addPlatform, { isSuccess, isError, error }] = useAddPlatformMutation();

  const [
    updatePlatform,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = useUpdatePlatformMutation();

  const context = useAppContext();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { status: false },
    mode: "all",
  });
  const navigate = useNavigate();

  const submitHandler = (data) => {
    if (platformData)
      return updatePlatform({
        ...data,
        icon: imageData.image || platformData.icon,
        status: watch("status") ? "active" : "inactive",
      });
    addPlatform({
      ...data,
      icon: imageData.image,
      status: watch("status") ? "active" : "inactive",
    });
  };

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      setShowModalAdd((prev) => !prev);
      reFetch();
      context.setAndShowModalData({
        title: `Congrats!! ${
          isSuccess ? "Your Platform Is Up & Live!!" : "Your Platform Updated"
        }`,
        subtitle: `You have just created a platform!`,
        description:
          " You can edit or deactivate this platform at any point in time.",
        image: congratsImage,
      });
      navigate("/dashboard/systems");
    }
    if (isError || isUpdateError)
      toast(error?.data || updateError?.data, {
        autoClose: 4000,
        type: "error",
      });
  }, [isSuccess, isError, isUpdateSuccess, isUpdateError]);

  useEffect(() => {
    if (platformData) {
      setImageData({ image: platformData.icon });

      reset({
        ...platformData,
        status: platformData.status === "active" ? true : false,
      });
    }
  }, [platformData, reset]);
  return (
    <div className="text-center flex flex-col   items-center ">
      <ToastContainer />
      <p className="font-bold text-[24px] text-[#0f2837]">
        {platformData
          ? "Updating An Existing Platform"
          : "Creating A New Platform"}
      </p>
      <p className="font-bold text-[14px] mt-[30px] text-[#0f2837]">
        Platform Details
      </p>
      <p className="text-[12px] mt-[8px] text-[#87949B]">
        Fill out Platform details
      </p>
      <form
        className="text-center flex flex-col   items-center "
        onSubmit={handleSubmit(submitHandler)}
      >
        <div className="flex-initial w-[245px] mt-[15px] max-w-[500px]">
          <InputText
            type="text"
            err={errors.name}
            name="name"
            labelText="Platform Name"
            id="name"
            register={register}
          />
          {errors.name && (
            <p className="text-left text-[12px] text-[#cc0000]">
              {errors.name.message}
            </p>
          )}
        </div>
        <p className="text-[12px] mt-[15px] ml-[20px] self-start text-[#87949B]">
          Status :
        </p>
        <div className="flex flex-row items-center mt-[7px] gap-[10px] justify-center">
          {watch("status") ? (
            <div className="flex flex-row items-center mt-[5px]">
              <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#9BC368]" />
              <p className="text-[#9BC368] text-[10px] font-bold">Active</p>
            </div>
          ) : (
            <div className="flex flex-row flex-nowrap items-center mt-[5px]">
              <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#A52C38] " />
              <p className="text-[#A52C38] text-[10px] font-bold">Inactive</p>
            </div>
          )}
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value, name } }) => (
              <Switch
                activeBoxShadow={""}
                uncheckedIcon={false}
                checkedIcon={true}
                checked={value}
                name={name}
                onChange={onChange}
                onColor={context.mainColor}
                offColor={context.secondaryColor}
              />
            )}
          />
        </div>

        <ImageUpload
          image={imageData.image || platformData?.icon}
          data={imageData}
          setData={setImageData}

        />
        {imageData.image && (
          <div className="mt-5 p-4 ">
            <img src={imageData.image} alt="" className="w-20 h-20" />
          </div>
        )}
        {/* <ImagesUploader image={image} setImage={setImage} /> */}

        {/* {errImage && (
          <p className="text-left text-[12px] text-[#cc0000]">
            You must upload an image
          </p>
        )} */}

        {/* {errors.token && (
          <p className="text-left text-[12px] text-[#cc0000]">
            {errors.token.message}
          </p>
        )} */}

        <div className="flex items-center justify-center mt-[30px]  p-8 border-t-1 border-solid border-color rounded-b">
          <button
            style={{
              border: `2px solid ${context.mainColor}`,
              color: context.mainColor,
            }}
            className="flex flex-row flex-nowrap justify-center items-center whitespace-nowrap min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-xs lg:text-tiny outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModalAdd(false)}
          >
            Cancel
          </button>
          <button
            style={{
              color: "#fff",
              border: `2px solid ${context.mainColor}`,
              backgroundColor: context.mainColor,
            }}
            className="flex whitespace-nowrap ml-[15px] flex-row flex-nowrap justify-center items-center min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-xs lg:text-tiny outline-none focus:outline-none ease-linear transition-all duration-150"
            type="submit"
          >
            {platformData ? "Update" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddFormPlatform;
