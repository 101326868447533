import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { selectAppOverlayState } from "../../store/app/overlaySlice";

const TransitionOverlay = ({ style }) => {
  const { isActive, top, left } = useSelector(selectAppOverlayState);
  console.log({ isActive, top, left });
  if (!isActive) return null;
  return createPortal(
    <div className="blue-overlay" style={style || { top, left }} />,
    document.getElementById("overlays-root")
  );
};

export default TransitionOverlay;
