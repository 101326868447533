import { configureStore } from "@reduxjs/toolkit";
import { adminApi } from "./admin/adminApi";
import rootReducer from "./rootReducer";
import { userApi } from "./user/userApi";
import { adminDashboardApi } from "./admin/adminDashboardApi";
import { usersApi } from "./admin/usersApi";

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const middlewares = [
  userApi.middleware,
  adminApi.middleware,
  adminDashboardApi.middleware,
  usersApi.middleware,
];

if (process.env.NODE_ENV === "development") {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  });
  middlewares.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === "development",
});

export default store;
