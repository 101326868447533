import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../Context/AppContext";
import Select, { components } from "react-select";
import { Loading } from "../../../components/Loading";
import { ReactComponent as Add } from "../../../assets/svg/add.svg";
import PaginationRow from "../../../components/PaginationRow";
import { ReactComponent as FilterIcon } from "../../../assets/svg/filter.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { classNames } from "../../../components/shared/Utils";
import { SortDownIcon } from "../../../components/shared/Icons";
import RewardCard from "../../../components/RewardCard";
import { useAdminRewardsQuery } from "../../../store/admin/adminApi";

import RewardModal from "../../../components/forms/RewardModal";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SortDownIcon className="text-[18px] text-main-text mb-[6px]" />
    </components.DropdownIndicator>
  );
};

function Rewards() {
  const [itemToEdit, setItemToEdit] = useState();
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [statusFilter, setStatusFilter] = useState({ value: "", label: "All" });
  const [limit, setLimit] = useState(8);
  const [offset, setOffset] = useState(0);
  const statusOptions = [
    { value: "", label: "All" },
    { value: "active", label: "active" },
    { value: "inactive", label: "inactive" },
  ];

  const {
    data: adminRewardsData,
    isLoading,
    refetch,
  } = useAdminRewardsQuery({
    limit: limit,
    offset: offset,
  });

  const [filteredData, setFilteredData] = useState([]);
  console.log(adminRewardsData?.count);
  const context = useAppContext();
  useEffect(() => {
    context.keyword?.length > 0
      ? setFilteredData(
          adminRewardsData?.results?.filter((ii) =>
            ii.name.toLowerCase().includes(context.keyword.toLowerCase())
          )
        )
      : setFilteredData(adminRewardsData?.results);
  }, [adminRewardsData?.results, context?.keyword]);

  useEffect(() => {
    if (adminRewardsData?.results?.length > 0) {
      if (statusFilter.value) {
        const filtered = adminRewardsData?.results?.filter((el) => {
          return el?.status === statusFilter.value;
        });
        setFilteredData(filtered);
      } else setFilteredData(adminRewardsData?.results);
    }
  }, [statusFilter.value]);

  useEffect(() => localStorage.clear(), []);

  return (
    <div className="w-full min-h-screen p-6 2lg:p-14">
      <p className="text-xs mb-2">
        <Link to={"/dashboard/rewards"}>Rewards</Link> &gt;
      </p>
      <div className="mb-7 flex flex-row justify-between items-center">
        <p className="font-bold text-tiny lg:text-2xl">All Rewards</p>
        <div className="flex flex-row flex-nowrap justify-center items-center">
          <button
            onClick={() => {
              setShowModalAdd(true);
              setItemToEdit(null);
            }}
            style={{
              border: `2px solid ${context.mainColor}`,
              backgroundColor: context.mainColor,
            }}
            className="flex h-[44px] p-[7px] flex-row flex-nowrap justify-center items-center rounded-[4px] ml-3 text-white font-bold text-center text-xxs 2lg:text-xs lg:text-tiny outline-none"
          >
            <Add className="h-[20px] w-[20px] mr-[10px]" />
            <span className="text-[12px]">Create New Reward</span>
          </button>
        </div>
      </div>

      <RewardModal
        title="Creating A New Reward"
        reFetch={refetch}
        showModal={showModalAdd}
        setShowModal={setShowModalAdd}
      />
      <RewardModal
        title="Editing Reward"
        reFetch={refetch}
        showModal={showModalEdit}
        setShowModal={setShowModalEdit}
        itemToEdit={itemToEdit}
      />

      <div className="flex flex-row justify-start items-start gap-2 mb-2 -mt-5 mb-4">
        <div className="flex flex-row items-center gap-2">
          {/* <span className="text-main-text text-base font-bold">Filter:</span> */}
          <div
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
            style={{
              backgroundColor: isFiltersOpen
                ? "rgb(15,40,55)"
                : "rgb(236,239,239)",
              color: isFiltersOpen ? "#fff" : "",
            }}
            className="cursor-pointer min-w-[40px] min-h-[40px] w-[40px] h-[40px] px-2 py-1 rounded-[4px]"
          >
            <FilterIcon className="w-full h-full" />
          </div>
        </div>
        <div
          className={classNames(
            "flex-row flex-wrap items-center gap-4 px-4 py-3 rounded-md bg-white shadow-table-head-dark -mt-6 ml-2",
            isFiltersOpen ? "flex" : "hidden"
          )}
        >
          <span className="text-main-text text-base font-bold">Filters:</span>
          <div className="flex flex-row flex-wrap items-center gap-2">
            <label className="flex flex-row flex-wrap items-center">
              {/* <span className="text-gray-700 mr-2">status: </span> */}
              <div className="shadow-table-head-dark rounded-lg w-fit">
                <Select
                  className="text-center text-xs font-bold"
                  value={
                    statusFilter.value
                      ? { value: statusFilter.value, label: statusFilter.label }
                      : {
                          value: "",
                          label: "Status",
                        }
                  }
                  components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setStatusFilter({ value: e.value, label: e.label });
                  }}
                  isSearchable={false}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: 0,
                      padding: "0px 4px",
                      boxShadow: "none",
                    }),
                  }}
                  options={statusOptions}
                />
              </div>
            </label>
          </div>
          <div
            onClick={() => setStatusFilter({ value: "", label: "All" })}
            className="flex flew-row flex-nowrap justify-center items-center gap-1 px-2 py-1 text-white text-xs font-bold bg-primary-four hover:bg-primary-four/70 rounded-full cursor-pointer"
          >
            Clear <Close className="w-[12px] h-[12px]" />
          </div>
        </div>
      </div>

      {/****** card grid ******* */}
      <div className="flex flex-wrap mx-[-8px]">
        {isLoading ? (
          <Loading />
        ) : filteredData?.length > 0 ? (
          filteredData?.map((el, index) => (
            <RewardCard
              key={index}
              setItemToEdit={setItemToEdit}
              setShowModal={setShowModalEdit}
              element={el}
            />
          ))
        ) : (
          <p>No Rewards Found</p>
        )}
      </div>
      {/****** pagination ******* */}
      <PaginationRow
        count={adminRewardsData?.count}
        setLimit={setLimit}
        limit={limit}
        setOffset={setOffset}
      />
    </div>
  );
}

export default Rewards;
