import React, { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";
import ImageUpload from "../../utils/ImageUpload";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import SelectComponent from "../select";
import Switch from "react-switch";
import Input from "../input";
import FormModalBody from "../FormModalBody";
import ModalSkeleton from "../ModalSkeleton";
import {
  useAddAdminBadgeMutation,
  useUpdateAdminBadgeMutation,
  usePlatformsQuery,
  useActivitiesQuery,
} from "../../store/admin/adminApi";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import congratsImage from "../../assets/images/congrats.png";

function BadgeModal({ reFetch, title, showModal, setShowModal, itemToedit }) {
  const schema = yup.object().shape({
    name: yup.string().required("Please Provide Badge name"),
    points: yup.number().required("points is Required"),
    description: yup.string().required("description is Required"),
    platform: yup.number().required("platform is Required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const context = useAppContext();

  const [errImage, seterrImage] = useState(false);

  const [platformOptions, setPlatformOptions] = useState([]);
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  const [mydata, setMyData] = useState({
    image: itemToedit?.icon ? itemToedit?.icon : "",
  });

  const [addBadge, { isError, isSuccess }] = useAddAdminBadgeMutation();
  const [updateBadge, { isError: isUpdateError, isSuccess: isSuccessUpdate }] =
    useUpdateAdminBadgeMutation();
  const { data: platforms } = usePlatformsQuery();
  const { data: activities } = useActivitiesQuery();
  useEffect(() => {
    if (platforms) {
      setPlatformOptions((prev) => [
        ...platformOptions,
        ...platforms?.results?.map((i) => ({
          value: i.id,
          label: i.name,
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platforms]);
  useEffect(() => {
    if (activities) {
      setActivitiesOptions((prev) => [
        ...activitiesOptions,
        { label: "no option", value: [] },
        ...activities.results?.map((i) => ({
          value: i.id,
          label: i.name,
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);
  const onSubmit = async (data) => {
    if (itemToedit) {
      return updateBadge({
        ...data,
        id: itemToedit.id,
        name: data.name,
        points: data.points,
        icon: mydata.image || itemToedit?.icon,
        description: data.description,
        platform:
          typeof data.platform === "object" ? data.platform : [data.platform],
        activity:
          typeof data.activity === "object" ? data.activity : [data.activity],
        status: watch("status") ? "active" : "inactive",
      });
    } else {
      return addBadge({
        description: data.description,
        name: data.name,
        points: data.points || 0,
        platform: [data.platform],
        activity: data.activity ? [data.activity] : [],
        icon: mydata.image,
        status: watch("status") ? "active" : "inactive",
      });
    }
  };
  useEffect(() => {
    if (itemToedit) {
      setMyData({ image: itemToedit?.icon });
      reset({
        ...itemToedit,
        status: itemToedit.status === "active" ? true : false,
      });
    }
  }, [itemToedit, reset]);

  useEffect(() => {
    if (isSuccess || isSuccessUpdate) {
      setShowModal(false);
      context.setAndShowModalData({
        title: `${
          isSuccess
            ? "Congrats!! Your Badge Is Up & Live!!"
            : `Badge ${itemToedit?.name} Edited Successfully!!`
        }`,
        subtitle: `
        ${
          isSuccess ? `You have just created a ${itemToedit?.name} badge!` : ""
        }`,
        description: `${
          isSuccess
            ? "Users will now be automatically rewarded with this badge upon reaching the specified amount of points."
            : ""
        }`,
        image: congratsImage,
      });
    }
  }, [isSuccess, isSuccessUpdate]);

  useEffect(() => {
    if (isError || isUpdateError) {
      toast.error("something went wrong!");
    }
  }, [isError, isUpdateError]);

  return (
    <ModalSkeleton showModal={showModal} setShowModal={setShowModal}>
      <FormModalBody title={title}>
        <ToastContainer />

        <form
          className="text-center flex flex-col   items-center "
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex w-full flex-col justify-center mx-auto">
            <div className="flex w-full  gap-10">
              <div className="w-1/2">
                <Input
                  className="flex-1"
                  type="text"
                  label="Badge Name"
                  placeholder="Enter the badge name..."
                  id="name"
                  register={register}
                />
                {errors.name && (
                  <p className="mt-1 text-sm text-left text-red">
                    {errors.name.message}
                  </p>
                )}
              </div>

              <div className="w-1/2">
                <Input
                  type="text"
                  label="Points to be earned"
                  placeholder="Enter the points to be earned.."
                  id="points"
                  register={register}
                />
                {errors.points && (
                  <p className="mt-1 text-sm text-left text-red">
                    {errors.points.message}
                  </p>
                )}
              </div>
            </div>
            <div className="w-full mt-4">
              <Input
                className="flex-1 w-full"
                type="textArea"
                label="Badge Description"
                placeholder="Enter the badge description..."
                id="description"
                {...register("description")}
              />
              {errors.description && (
                <p className="mt-1 text-sm text-left text-red">
                  {errors.description.message}
                </p>
              )}
            </div>
            <div className="flex flex-col items-start mt-4">
              <p className="font-medium ">Badge Platform</p>
              <SelectComponent
                id="platform"
                className="text-xs mt-[15px] w-1/2 border-1 border-input-color rounded-md "
                placeholder="Select Platform..."
                isSearchable={false}
                defaultValue={
                  itemToedit?.platform && {
                    label: platforms?.results
                      .filter((ii) => ii.id === itemToedit.platform[0])
                      .map((it) => it.name),
                    value: itemToedit.platform[0],
                  }
                }
                onChange={(e) => setValue("platform", e.value)}
                options={platformOptions}
              />
              {errors.platform && (
                <p className="text-left text-[12px] text-[#cc0000]">
                  {errors.platform.message}
                </p>
              )}
            </div>
            <div className="flex flex-col items-start mt-4">
              <p className="font-medium">Badge Activity</p>
              <SelectComponent
                id="activity"
                className="text-xs mt-[15px]  w-1/2 border-1 border-input-color rounded-md "
                placeholder="Select activity..."
                isSearchable={false}
                defaultValue={
                  itemToedit?.activity && {
                    label: activities?.results
                      .filter((ii) => ii.id === itemToedit.activity[0])
                      .map((it) => it.name),
                    value: itemToedit.activity[0],
                  }
                }
                onChange={(e) => setValue("activity", e.value)}
                options={activitiesOptions}
              />
              {errors.activity && (
                <p className="text-left text-[12px] text-[#cc0000]">
                  {errors.activity.message}
                </p>
              )}
            </div>
            <div className="flex flex-col items-start mt-4">
              <p className="font-medium mb-2">Status</p>

              <div className="flex flex-row items-center mt-[7px] gap-[10px] justify-center">
                {watch("status") ? (
                  <div className="flex flex-row items-center mt-[5px]">
                    <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#9BC368]" />
                    <p className="text-[#9BC368] text-[10px] font-bold">
                      Active
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-row flex-nowrap items-center mt-[5px]">
                    <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#A52C38] " />
                    <p className="text-[#A52C38] text-[10px] font-bold">
                      Inactive
                    </p>
                  </div>
                )}
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, value, name } }) => (
                    <Switch
                      activeBoxShadow={""}
                      uncheckedIcon={false}
                      checkedIcon={true}
                      checked={value}
                      name={name}
                      onChange={onChange}
                      onColor={context.mainColor}
                      offColor={context.secondaryColor}
                    />
                  )}
                />
              </div>
            </div>
            <div className="mt-2 w-full">
              <ImageUpload
                image={mydata.image || itemToedit?.icon}
                data={mydata}
                setData={setMyData}
                className="w-1/2"
              />
              {errImage && (
                <p className="text-left text-[12px] text-[#cc0000]">
                  You must upload an image
                </p>
              )}
            </div>
          </div>

          <div className="flex items-center justify-end mt-7 w-full pt-5 border-t-1 border-solid border-color rounded-b">
            <button
              style={{
                border: `2px solid ${context.mainColor}`,
                color: context.mainColor,
              }}
              className="flex flex-row flex-nowrap justify-center items-center whitespace-nowrap min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-xs lg:text-tiny outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              style={{
                color: "#fff",
                border: `2px solid ${context.mainColor}`,
                backgroundColor: context.mainColor,
              }}
              className="flex whitespace-nowrap ml-[15px] flex-row flex-nowrap justify-center items-center min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-xs lg:text-tiny outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
            >
              Upload
            </button>
          </div>
        </form>
      </FormModalBody>
    </ModalSkeleton>
  );
}

export default BadgeModal;
