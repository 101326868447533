import Select from "react-select";

function SelectComponent({
  onChange,
  options,
  defaultValue,
  className,
  value,
  name,
  id,
  placeholder,
  isClearable,
  isSearchable,
}) {
  return (
    <Select
      className={`text-xs mt-[15px] w-[245px] max-w-[500px] border-1 border-input-color rounded-md  ${className}`}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (base) => ({
          ...base,
          border: "none",
          boxShadow: "none",
        }),
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            paddingLeft: "10px",
            textAlign: "left",
          };
        },
        singleValue: (base) => {
          return {
            ...base,
            textAlign: "left",
          };
        },
        option: (base) => {
          return {
            ...base,
            textAlign: "left",
          };
        },
      }}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={isSearchable}
      value={value}
      name={name}
      id={id}
    />
  );
}
export default SelectComponent;
