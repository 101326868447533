import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useAppContext } from "../../Context/AppContext";
import { RegisteredUserMonths, RegisteredUserDataYears } from "./Data";

function BarChart({ datatype, monthsData, yearsData }) {
  const context = useAppContext();
  const [currentData, setCurrentData] = useState(
    monthsData || RegisteredUserMonths
  );

  useEffect(() => {
    if (datatype === "years") {
      setCurrentData(yearsData || RegisteredUserDataYears);
    } else {
      setCurrentData(monthsData || RegisteredUserMonths);
    }
  }, [datatype, monthsData, yearsData]);

  const options = {
    scales: {
      y: {
        ticks: {
          font: {
            weight: "bold",
          },
        },
      },
      x: {
        display: true,
        grid: { display: false },
        ticks: {
          display: true,
        },
      },
    },

    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const [userData, setUserData] = useState({
    labels: currentData.map((data) =>
      data.label ? data.label : data.date_joined__year
    ),
    datasets: [
      {
        display: false,
        data: currentData.map((data) => (data.value ? data.value : data.count)),
        borderRadius: 4,
        borderSkipped: false,

        backgroundColor: currentData.map((data) => context.secondaryColor),
      },
    ],
  });

  function changeHeighest() {
    const cpyy = {
      labels: currentData.map((data) =>
        data.label ? data.label : data.date_joined__year
      ),
      datasets: [
        {
          display: false,
          data: currentData.map((data) =>
            data.value ? data.value : data.count
          ),
          borderRadius: 4,
          borderSkipped: false,
          backgroundColor: currentData.map((data) => context.secondaryColor),
        },
      ],
    };
    const max = Math.max(...cpyy.datasets[0].data);
    const indexofmax = cpyy.datasets[0].data.indexOf(max);
    cpyy.datasets[0].backgroundColor[indexofmax] = context.mainColor;
    setUserData(cpyy);
  }
  useEffect(() => {
    changeHeighest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.mainColor, currentData]);

  return <Bar data={userData} options={options} />;
}

export default BarChart;
