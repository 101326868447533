import React from "react";
import { classNames } from "../shared/Utils";

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "font-bold text-xs capitalize",
        status.startsWith("active") ? "text-tip-text" : null,
        status.startsWith("inactive") ? "text-red" : null,
        status.startsWith("offline") ? "text-sub-text" : null
      )}
    >
      {status}
    </span>
  );
}
