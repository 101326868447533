import React, { useEffect, useState } from "react";
import { useAppContext } from "../../Context/AppContext";
import congratsImage from "../../assets/images/congrats.png";
import { toast } from "react-toastify";
import Input from "../input";
import ModalSkeleton from "../ModalSkeleton";
import FormModalBody from "../FormModalBody";
import { useForm, Controller } from "react-hook-form";
import Switch from "react-switch";
import SelectComponent from "../select";

import {
  useCreateActivityMutation,
  useUpdateActivityMutation,
  usePlatformsQuery,
} from "../../store/admin/adminApi";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./YupSchema";

function ActivityModal({ reFetch, showModal, setShowModal, itemToEdit }) {
  const context = useAppContext();
  const [addActivity, { isSuccess, isError }] = useCreateActivityMutation();
  const [platformOptions, setPlatformOptions] = useState([]);

  const [
    updateActivity,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = useUpdateActivityMutation();
  const { data: platforms } = usePlatformsQuery();
  useEffect(() => {
    if (platforms) {
      setPlatformOptions((prev) => [
        ...platformOptions,
        ...platforms.results?.map((i) => ({
          value: i.id,
          label: i.name,
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platforms]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (itemToEdit)
      return updateActivity({
        ...data,
        id: itemToEdit.id,
        status: watch("status") ? "active" : "inactive",
      });
    addActivity({
      ...data,
      status: watch("status") ? "active" : "inactive",
    });
  };

  useEffect(() => {
    if (itemToEdit)
      reset({
        ...itemToEdit,
        status: itemToEdit.status === "active" ? true : false,
      });
  }, [itemToEdit, reset]);

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) setShowModal(false);
    reFetch();
    context.setAndShowModalData({
      title: `${
        isSuccess
          ? "Congrats!! Your Activity Is Up & Live!!"
          : `Activity ${itemToEdit?.name} Edited Successfully!!`
      }`,
      subtitle: ` ${isSuccess ? "You have just created a activity!" : ""}`,
      description: `${
        isSuccess
          ? "Users can now earn points upon completing this activitiy. You can edit or deactivate this activity at any point in time."
          : ""
      }`,
      image: congratsImage,
    });

    if ((isError || isUpdateError, updateError))
      toast.error("something went wrong!");
  }, [isError, isSuccess, isUpdateSuccess, isUpdateError]);
  return (
    <ModalSkeleton showModal={showModal} setShowModal={setShowModal}>
      <FormModalBody
        title={itemToEdit ? "Edit Activity" : "Creat new Activity"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full gap-10">
            <div className="w-1/2">
              <Input
                type="text"
                label="Activity Name"
                placeholder="Enter the Activity name..."
                {...register("name", { required: true })}
              />

              <div>
                {errors.name && (
                  <p className="mt-1 text-sm text-center text-red">
                    {errors.name.message}
                  </p>
                )}
              </div>
            </div>
            <div className="w-1/2">
              <Input
                type="number"
                label="Points to be earned"
                placeholder="Points Earned Upon Completion.."
                {...register("points", { required: true })}
              />
              <div>
                {errors.points && (
                  <p className="mt-1 text-sm text-center text-red">
                    {errors.points.message}
                  </p>
                )}
              </div>
            </div>
            <div className="w-1/2">
              <Input
                type="text"
                label="label"
                placeholder="label.."
                {...register("label", { required: true })}
              />
              <div>
                {errors.label && (
                  <p className="mt-1 text-sm text-center text-red">
                    {errors.label.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="w-full mt-6">
            <Input
              className={"w-full"}
              type="textArea"
              label="Activity Description"
              placeholder="Enter the activity Description.."
              {...register("description", { required: true })}
            />
            <div>
              {errors.description && (
                <p className="mt-1 text-sm text-center text-red">
                  {errors.description.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-start mt-4">
            <p className="font-medium mb-2">Activitiy Platform</p>
            <SelectComponent
              id="platform"
              className="text-xs mt-[15px] w-1/2 border-1 border-input-color rounded-md "
              placeholder="Select Platform..."
              isSearchable={false}
              defaultValue={
                itemToEdit?.platform && {
                  label: platforms?.results
                    .filter((ii) => ii.id === itemToEdit.platform)
                    .map((it) => it.name),
                  value: itemToEdit.platform,
                }
              }
              onChange={(e) => setValue("platform", e.value)}
              options={platformOptions}
            />
            {errors.platform && (
              <p className="text-left text-[12px] text-[#cc0000]">
                {errors.platform.message}
              </p>
            )}
          </div>
          <div className="flex flex-col items-start mt-4">
            <p className="font-medium mb-2">Status</p>
            <div className="flex flex-row items-center mt-[7px] gap-[10px] justify-center">
              {watch("status") ? (
                <div className="flex flex-row items-center mt-[5px]">
                  <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#9BC368]" />
                  <p className="text-[#9BC368] text-[10px] font-bold">Active</p>
                </div>
              ) : (
                <div className="flex flex-row flex-nowrap items-center mt-[5px]">
                  <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#A52C38] " />
                  <p className="text-[#A52C38] text-[10px] font-bold">
                    Inactive
                  </p>
                </div>
              )}
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value, name } }) => (
                  <Switch
                    activeBoxShadow={""}
                    uncheckedIcon={false}
                    checkedIcon={true}
                    checked={value}
                    name={name}
                    onChange={onChange}
                    onColor={context.mainColor}
                    offColor={context.secondaryColor}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex items-center justify-end mt-7 w-full pt-5 border-t-1 border-solid border-color rounded-b">
            <button
              style={{
                border: `2px solid ${context.mainColor}`,
                color: context.mainColor,
              }}
              className="flex flex-row flex-nowrap justify-center items-center whitespace-nowrap min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-xs lg:text-tiny outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              style={{
                color: "#fff",
                border: `2px solid ${context.mainColor}`,
                backgroundColor: context.mainColor,
              }}
              className="flex whitespace-nowrap ml-[15px] flex-row flex-nowrap justify-center items-center min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-xs lg:text-tiny outline-none focus:outline-none ease-linear transition-all duration-150"
              type="submit"
            >
              {itemToEdit ? "Update" : "Submit"}
            </button>
          </div>
        </form>
      </FormModalBody>
    </ModalSkeleton>
  );
}

export default ActivityModal;
