import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { Button } from "../../../components/shared/Button";
import { Loading } from "../../../components/Loading";
import "react-toastify/dist/ReactToastify.css";
import { useAppContext } from "../../../Context/AppContext";
import SuccessNotif from "../../../components/SuccessNotif";
import {
  useUserConfigrationQuery,
  useAddNotificationsMutation,
  useUpdateNotificationsMutation,
} from "../../../store/user/userApi";

function EditNotifications() {
  const context = useAppContext();
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [user, setUser] = useState({
    new_events_notifications: true,
    earn_badge_notifications: true,
    new_rewards_notifications: true,
    redeem_points_notifications: true,
    earn_points_notifications: true,
    all_notifications: true,
  });
  const {
    data: notifications,
    isLoading: notificationsLoading,
    refetch,
  } = useUserConfigrationQuery();
  const [
    addNotification,
    { isError, isSuccess, error, isLoading: addNotiLoading },
  ] = useAddNotificationsMutation();

  const [
    updateNotification,
    {
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      error: updateError,
      isLoading: updateNotiLoading,
    },
  ] = useUpdateNotificationsMutation();
  useEffect(() => {
    const timeout = setTimeout(function () {
      isMessageVisible && setIsMessageVisible(false);
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isMessageVisible]);
  useEffect(() => {
    if (isSuccess || updateIsSuccess) {
      return setIsMessageVisible(true);
    }
    return setIsMessageVisible(false);
  }, [isSuccess, updateIsSuccess]);

  async function handleSubmit() {
    if (notifications?.results?.length > 0) {
      await updateNotification({ id: user.id, ...user });
      await refetch();
    } else {
      await addNotification({ ...user });
      await refetch();
    }
  }

  useEffect(() => {
    if (notifications?.results?.length > 0) {
      return setUser(notifications?.results[0]);
    }
  }, [notifications?.results?.length]);
  if (notificationsLoading) {
    return <Loading />;
  }
  return (
    <div className="w-full h-full bg-white overflow-auto p-[40px]">
      <p className="text-xs mb-2">
        <Link to={"/dashboard/activities"}>Settings</Link> &gt;
      </p>
      {isMessageVisible && (
        <SuccessNotif
          setIsMessageVisible={setIsMessageVisible}
          message={`${
            isSuccess
              ? "Your preferences have been added successfully!"
              : "Your preferences have been updated successfully!"
          }`}
        />
      )}
      <p className="font-bold text-[24px] maxPhone:text-[16px] maxSmallPhone:text-[13px] text-main-text mb-[35px]">
        Notifications
      </p>
      <p className="font-bold text-[16px] maxPhone:text-[11px] maxSmallPhone:text-[11px] text-main-text mb-[20px]">
        Types of Notification
      </p>

      <div>
        <div className="flex flex-col gap-[20px] mb-[40px] ">
          <div className="flex flex-row maxPhone:flex-col maxPhone:w-full maxPhone:items-start w-full lg:w-[600px]  justify-between items-center">
            <p className="text-[14px] maxPhone:text-[11px] maxSmallPhone:text-[11px] text-main-text">
              Receive notifications about new events
            </p>
            <div className="flex flex-row items-center gap-[2px]">
              <Switch
                className="border-1 maxPhone:scale-75 border-[#CFD4D7]"
                activeBoxShadow={""}
                uncheckedIcon={false}
                onColor={"#F9F9F9"}
                offColor={"#F9F9F9"}
                checkedIcon={false}
                offHandleColor={"#DE4949"}
                onHandleColor={"#9BC368"}
                checked={user.new_events_notifications}
                onChange={(e) =>
                  setUser({ ...user, new_events_notifications: e })
                }
              />
              {user.new_events_notifications ? (
                <p className="text-[14px] maxPhone:text-[11px] text-[#9BC368]">
                  Enabled
                </p>
              ) : (
                <p className="text-[14px] text-[#DE4949]">Disabled</p>
              )}
            </div>
          </div>
          <div className="flex flex-row maxPhone:flex-col maxPhone:w-full maxPhone:items-start w-full lg:w-[600px] justify-between items-center">
            <p className="text-[14px] maxPhone:text-[11px] text-main-text">
              Receive notifications when you earn a new badge
            </p>
            <div className="flex flex-row  items-center gap-[2px]">
              <Switch
                className="border-1 maxPhone:scale-75 border-[#CFD4D7]"
                activeBoxShadow={""}
                uncheckedIcon={false}
                onColor={"#F9F9F9"}
                offColor={"#F9F9F9"}
                checkedIcon={false}
                offHandleColor={"#DE4949"}
                onHandleColor={"#9BC368"}
                checked={user.earn_badge_notifications}
                onChange={(e) =>
                  setUser({ ...user, earn_badge_notifications: e })
                }
              />
              {user.earn_badge_notifications ? (
                <p className="text-[14px] maxPhone:text-[11px] text-[#9BC368]">
                  Enabled
                </p>
              ) : (
                <p className="text-[14px] maxPhone:text-[11px] text-[#DE4949]">
                  Disabled
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-row maxPhone:flex-col maxPhone:w-full maxPhone:items-start w-full lg:w-[600px] justify-between items-center">
            <p className="text-[14px] maxPhone:text-[11px] text-main-text">
              Receive notifications when a new reward is released
            </p>
            <div className="flex flex-row items-center gap-[2px]">
              <Switch
                className="border-1 maxPhone:scale-75 border-[#CFD4D7]"
                activeBoxShadow={""}
                uncheckedIcon={false}
                onColor={"#F9F9F9"}
                offColor={"#F9F9F9"}
                checkedIcon={false}
                offHandleColor={"#DE4949"}
                onHandleColor={"#9BC368"}
                checked={user.new_rewards_notifications}
                onChange={(e) =>
                  setUser({ ...user, new_rewards_notifications: e })
                }
              />
              {user.new_rewards_notifications ? (
                <p className="text-[14px] maxPhone:text-[11px] text-[#9BC368]">
                  Enabled
                </p>
              ) : (
                <p className="text-[14px] maxPhone:text-[11px] text-[#DE4949]">
                  Disabled
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-row maxPhone:flex-col maxPhone:w-full maxPhone:items-start w-full lg:w-[600px] justify-between items-center">
            <p className="text-[14px] maxPhone:text-[11px] text-main-text">
              Receive notifications about redeeming your points
            </p>
            <div className="flex flex-row items-center gap-[2px]">
              <Switch
                className="border-1 maxPhone:scale-75 border-[#CFD4D7]"
                activeBoxShadow={""}
                uncheckedIcon={false}
                onColor={"#F9F9F9"}
                offColor={"#F9F9F9"}
                checkedIcon={false}
                offHandleColor={"#DE4949"}
                onHandleColor={"#9BC368"}
                checked={user.redeem_points_notifications}
                onChange={(e) =>
                  setUser({ ...user, redeem_points_notifications: e })
                }
              />
              {user.redeem_points_notifications ? (
                <p className="text-[14px] maxPhone:text-[11px] text-[#9BC368]">
                  Enabled
                </p>
              ) : (
                <p className="text-[14px] maxPhone:text-[11px] text-[#DE4949]">
                  Disabled
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-row maxPhone:flex-col maxPhone:w-full maxPhone:items-start w-full lg:w-[600px] justify-between items-center">
            <p className="text-[14px] maxPhone:text-[11px] text-main-text">
              Receive notifications about how to earn more points
            </p>
            <div className="flex flex-row items-center gap-[2px]">
              <Switch
                className="border-1 maxPhone:scale-75 border-[#CFD4D7]"
                activeBoxShadow={""}
                uncheckedIcon={false}
                onColor={"#F9F9F9"}
                offColor={"#F9F9F9"}
                checkedIcon={false}
                offHandleColor={"#DE4949"}
                onHandleColor={"#9BC368"}
                checked={user.earn_points_notifications}
                onChange={(e) =>
                  setUser({ ...user, earn_points_notifications: e })
                }
              />
              {user.earn_points_notifications ? (
                <p className="text-[14px] maxPhone:text-[11px] text-[#9BC368]">
                  Enabled
                </p>
              ) : (
                <p className="text-[14px] maxPhone:text-[11px] text-[#DE4949]">
                  Disabled
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] mb-[30px] border-devider-color border-b-1"></div>
        <div className="flex flex-row maxPhone:flex-col maxPhone:w-full maxPhone:items-start w-full lg:w-[600px] justify-between items-center">
          <p className="text-[14px] maxPhone:text-[11px] text-main-text">
            Turn off all notifications
          </p>
          <div className="flex flex-row items-center gap-[2px]">
            <Switch
              className="border-1 maxPhone:scale-75 border-[#CFD4D7]"
              activeBoxShadow={""}
              uncheckedIcon={false}
              onColor={"#F9F9F9"}
              offColor={"#F9F9F9"}
              checkedIcon={false}
              offHandleColor={"#DE4949"}
              onHandleColor={"#9BC368"}
              checked={user.all_notifications}
              onChange={(e) => setUser({ ...user, all_notifications: e })}
            />
            {user.all_notifications ? (
              <p className="text-[14px] maxPhone:text-[11px] text-[#9BC368]">
                Enabled
              </p>
            ) : (
              <p className="text-[14px] maxPhone:text-[11px] text-[#DE4949]">
                Disabled
              </p>
            )}
          </div>
        </div>
        {/*********  form buttons ***** */}
        <div className="flex flex-row flex-wrap gap-[8px] mt-[60px]">
          <Link
            to={"/home/user-profile"}
            style={{
              borderColor: context.mainColor,
              color: context.mainColor,
            }}
            className="h-[38px] px-8 text-xs font-bold flex items-center justify-center rounded-[4px] border-[2px] whitespace-nowrap outline-none"
          >
            Cancel
          </Link>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            isLoading={updateNotiLoading}
            style={{
              backgroundColor: context.mainColor,
            }}
            className="h-[38px] px-5 text-xs font-bold rounded-[4px] text-white whitespace-nowrap outline-none"
          >
            Update Preferences
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditNotifications;
