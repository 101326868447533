import { useState } from "react";
import peopleRushingImage from "../../assets/images/people-rushing-3.png";
import ModalUserRedeem from "../../components/ModalUserRedeem";
import menTrophy from "../../assets/images/men-trophy.png";
import peopleRushing from "../../assets/images/people-rushing.png";
import silverBadge from "../../assets/images/silver-badge.png";
import hlel from "../../assets/images/hlel.png";
import silverHelal from "../../assets/images/silverHlel.png";
import eyes from "../../assets/images/eyes.png";
import successCup from "../../assets/images/success-cup.png";
import { Loading } from "../../components/Loading";
import ToolTip from "../../components/ToolTip";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import { classNames } from "../../components/shared/Utils";
import ToolTip2 from "../../components/ToolTip2";

import avatar from "../../assets/images/avatar2.png";
import {
  useStatsQuery,
  useActivitiesQuery,
  useBadgesToBeEarnQuery,
} from "../../store/user/userApi";

function UserProfile() {
  //   const { data: earned, isLoading: earnedLoading } = useBadgesQuery();
  const { data: tobeEarned, isLoading: tobeEarnedLoading } =
    useBadgesToBeEarnQuery();
  const { data: userStats, isLoading: usersStatsLoading } = useStatsQuery();
  const { data: userActivities, isLoading: activitiesLoading } =
    useActivitiesQuery();
  const context = useAppContext();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  if (usersStatsLoading || activitiesLoading || tobeEarnedLoading)
    return <Loading />;
  return (
    <div className="w-full h-full py-10 px-6">
      <div className="flex flex-row justify-end items-center mb-4">
        <button
          onClick={() => navigate("/home/edit-profile", { state: userStats })}
          className="flex h-[44px] px-6 py-[8px] flex-row flex-nowrap justify-center items-center rounded-[4px] ml-3 border-[#5E45FF] border-2 text-[#5E45FF] font-bold text-center outline-none"
        >
          <span className="text-[12px]">Edit Profile</span>
        </button>
        <button
          onClick={() => setShowModal(true)}
          className="flex h-[44px] px-6 py-[8px] flex-row flex-nowrap justify-center items-center rounded-[4px] ml-3 font-bold text-center outline-none bg-gradient-button text-white"
        >
          <span className="text-[12px]">Redeem Points</span>
        </button>
      </div>
      <ModalUserRedeem
        showModal={showModal}
        setShowModal={setShowModal}
        image={peopleRushingImage}
        points={1500}
      />
      {userStats ? (
        <div className="w-full flex flex-row  flex-wrap 3lg:flex-nowrap gap-5">
          <div className="flex-auto relative flex flex-row maxPhone:flex-col-reverse justify-between gap-7 min-h-[226px] maxPhone:w-full  rounded-[4px] bg-[#E9E7FA] shadow-brand">
            <div className="w-full flex-col flex justify-between sm:w-[60%] max-w-[410px] maxPhone:px-[24px] maxSmallPhone:px-[18px] px-[32px] py-6 ">
              <div className="flex flex-row">
                <img
                  className="rounded-full  maxPhone:w-[54px] maxPhone:h-[54px] w-[60px] h-[60px] maxPhone:mr-[36px] mr-[24px] "
                  src={(userStats.info && userStats.info.icon) || avatar}
                  alt="user-profile"
                />
                <div>
                  <p className="text-2xl maxPhone:text-[18px] maxSmallPhone:text-[16px] font-bold text-[#5E45FF]">
                    {userStats.info &&
                      userStats.info.first_name +
                        " " +
                        userStats.info.last_name}
                  </p>
                  <p className="text-[16px] maxPhone:text-[14px] maxSmallPhone:text-[12px]">
                    {userStats.info && userStats.info.email}
                  </p>
                  <p className="text-[12px] text-[#87949B] mb-[40px]">
                    Joined on: {userStats.info && userStats?.info?.date_joined}
                  </p>
                </div>
              </div>

              {userStats && userStats?.all_earned_badges?.length > 0 ? (
                <div className="max-w-[278px] flex flex-row">
                  {/* <div className="mr-[30px]">
                    <ToolTip
                      el={{
                        name: userStats?.current_tier?.tier_name,
                        date: userStats?.current_tier?.date,
                      }}
                      item={
                        <img
                          src={
                            (userStats?.current_tier &&
                              userStats?.current_tier?.icon) ||
                            placeHolderImg
                          }
                          alt="badge"
                          className="maxPhone:w-[45px] max-w-none maxPhone:h-[54px] w-[46px] h-[56px] ml-2"
                        />
                      }
                      left={"10px"}
                    />
                  </div> */}

                  <div className="flex flex-row flex-wrap gap-x-[10px] gap-y-[4px]">
                    {userStats &&
                      userStats?.all_earned_badges &&
                      [...userStats?.all_earned_badges]
                        ?.sort(
                          (a, b) =>
                            new Date(b.earned_date).getTime() -
                            new Date(a.earned_date).getTime()
                        )
                        .slice(0, 12)
                        .map((el, index) => (
                          <ToolTip
                            key={index}
                            el={{
                              name: el.name,
                              date: el.earned_date,
                            }}
                            item={
                              <img
                                src={el.icon || silverBadge}
                                alt="badge earned"
                                className={`drop-shadow-silver  ${
                                  index === 0
                                    ? "maxPhone:w-[45px] max-w-none maxPhone:h-[54px] w-[46px] h-[56px] mr-2"
                                    : "opacity-50 h-[25px] w-[19px]"
                                }  `}
                              />
                            }
                            left={"-10px"}
                          />
                        ))}
                  </div>
                </div>
              ) : (
                <div className="max-w-[278px] flex flex-row">
                  <div className="mr-[30px]">
                    <img
                      src={silverBadge}
                      alt="badge"
                      className="drop-shadow-silver opacity-20 maxPhone:w-[45px] max-w-none maxPhone:h-[54px] w-[46px] h-[56px] ml-2"
                    />
                  </div>
                  <div className="flex flex-row flex-wrap opacity-20 gap-x-[10px] gap-y-[4px]">
                    {Array(12)
                      .fill("")
                      .map((_, index) => (
                        <img
                          key={index}
                          src={silverBadge}
                          className="drop-shadow-silver opacity-50 h-[25px] w-[19px] "
                          alt="badge earned"
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
            {userStats.current_tier ? (
              <div className="p-2 max-w-[260px] min-h-[226px] maxPhone:min-w-[200px] maxPhone:max-w-full flex-1 items-center justify-center flex flex-col rounded-[4px] text-[#fff] profileTier bg-cover shadow-brand">
                <img
                  src={userStats.current_tier && userStats.current_tier.icon}
                  className="mb-4 w-[55px] h-[65px] drop-shadow-hlel"
                  alt="rank tier"
                />
                <p className="text-[14px]">
                  Tier{" "}
                  {userStats?.current_tier &&
                    userStats?.current_tier?.tier_name}{" "}
                  Rank
                </p>
                <p className="text-[20px] font-bold text-[#FDB236]">
                  {userStats && userStats?.user_rank}
                  {userStats?.user_rank === 1
                    ? "st "
                    : userStats?.user_rank === 2
                    ? "nd "
                    : userStats?.user_rank === 3
                    ? "rd "
                    : "th "}
                </p>
                <p className="text-[rgba(255,255,255,0.5)]">Current rank</p>
              </div>
            ) : (
              <div className="p-2 max-w-[260px] min-h-[226px] maxPhone:min-w-[200px] maxPhone:max-w-full  flex-1 items-center justify-center flex flex-col rounded-[4px] text-[#fff] bg-gradient-side-nav shadow-dark-2">
                <img
                  src={hlel}
                  className="mb-4 w-[55px] h-[65px] drop-shadow-hlel opacity-20"
                  alt="rank tier"
                />
                <p className="text-[20px] font-bold text-center">
                  You haven’t ranked anywhere yet..
                </p>
              </div>
            )}
          </div>

          <div className="flex-auto relative flex flex-col justify-center p-5 min-w-[190px] min-h-[226px] max-w-[300px] rounded-[4px] bg-[#5E45FF] shadow-brand">
            <img
              className={classNames(
                "w-[110px] h-[110px] absolute top-[26px] right-0",
                userStats?.number_of_badges > 0 ? "opacity-100" : "opacity-50"
              )}
              src={menTrophy}
              alt="trophy"
            />
            <div>
              <p className="text-lg font-bold text-white">
                You
                <br />
                {userStats?.number_of_badges > 0 ? "have" : "haven't"}
                <br />
                earned
                <br />
                {userStats?.number_of_badges > 0 ? (
                  <>
                    <span className="text-3xl text-[#FF9619]">
                      {userStats?.number_of_badges}
                    </span>
                    <br />
                    Badges!
                  </>
                ) : (
                  <>
                    a badge
                    <br />
                    yet..
                  </>
                )}
              </p>
              {userStats?.number_of_badges > 0 ? (
                <p className="text-xs text-[#FF9619]">
                  Hooray! Way to go {userStats?.info.first_name}!
                </p>
              ) : (
                <p className="text-xs text-[#FF9619]">
                  Go ahead and join your first event!
                </p>
              )}
            </div>
          </div>

          <div className="flex-auto relative flex flex-col justify-center p-5 min-w-[190px] min-h-[226px] max-w-[300px] rounded-[4px] bg-[#0028B3] shadow-brand">
            <img
              className={classNames(
                "w-[100px] h-[100px] absolute top-[18px] right-2",
                userStats?.number_of_badges > 0 ? "opacity-100" : "opacity-50"
              )}
              src={peopleRushing}
              alt="people rushing"
            />
            <div>
              <p className="text-lg font-bold text-white">
                You
                <br />
                {userStats.info && userStats.info.points > 0
                  ? "have"
                  : "haven't"}
                <br />
                earned
                <br />
                {userStats.info && userStats.info.points > 0 > 0 ? (
                  <>
                    <span className="text-3xl text-[#FAC39B]">
                      {userStats?.info?.points}
                    </span>
                    <br />
                    Points!
                  </>
                ) : (
                  <>
                    a points
                    <br />
                    yet..
                  </>
                )}
              </p>
              {userStats?.points_earned > 0 ? (
                <p className="text-xs text-[#FAC39B]">
                  Redeem and claim your rewards!
                </p>
              ) : (
                <p className="text-xs text-[#FAC39B]">
                  Go ahead and join your first event!
                </p>
              )}
            </div>
          </div>

          <div className="flex-auto relative flex flex-col justify-center p-5 min-w-[190px] min-h-[226px] max-w-[300px] rounded-[4px] bg-[#FDB236] shadow-orange-shadow">
            <img
              className={classNames(
                "w-[114px] h-[129px] absolute top-[18px] right-0",
                userStats?.number_of_rewards > 0 ? "opacity-100" : "opacity-50"
              )}
              src={successCup}
              alt="success cup"
            />
            <div>
              <p className="text-lg font-bold text-white">
                You
                <br />
                {userStats?.number_of_rewards > 0 ? "have" : "haven't"}
                <br />
                {userStats?.number_of_rewards > 0 ? "won" : "won a"}
                <br />
                {userStats?.number_of_rewards > 0 ? (
                  <>
                    <span className="text-3xl text-main-text">
                      {userStats?.number_of_rewards}
                    </span>
                    <br />
                    Rewards
                  </>
                ) : (
                  <>
                    a reward
                    <br />
                    yet..
                  </>
                )}
              </p>
              {userStats?.number_of_rewards >= 10 ? (
                <p className="text-xs text-main-text">
                  Keep the good work {userStats?.info.first_name}!
                </p>
              ) : (
                <p className="text-xs text-main-text max-w-[170px]">
                  Earn more points to be able to exchange them for rewards
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p
          style={{ color: context.mainColor }}
          className="text-center p-[10px] border-t-1  border-color"
        >
          No data to show
        </p>
      )}
      <div className="w-full h-[1px] bg-[rgba(207,212,215,0.3)] my-10"></div>

      <div className="flex flex-row flex-wrap mx-[-10px]">
        <div className="w-[100%] 3lg:w-[50%] px-[10px] mb-[20px] flex flex-col justify-between">
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            Your Activities Log
          </p>

          <div
            className={`flex-1 flex flex-col w-full min-h-[300px] max-h-[450px]  ${
              userActivities?.results?.length > 0
                ? "overflow-auto"
                : "overflow-hidden"
            } rounded-[10px] bg-white shadow-dark`}
          >
            <div className="py-2 px-[25px] shadow-blue-shadow-light text-center  text-xs flex gap-[10px] justify-between h-[54px] text-white items-center bg-gradient-button rounded-t-lg font-bold whitespace-nowrap">
              <p className="flex-[2] min-w-0 text-[14px] font-bold text-left">
                Activity name
              </p>
              <p className="flex-1 text-[14px] font-bold  min-w-0  pr-[8px]">
                Date
              </p>
              <p className="flex-1 text-[14px] font-bold  min-w-0 text-right">
                Reward
              </p>
            </div>
            {userActivities?.results?.length > 0 ? (
              [...userActivities?.results]
                .sort(
                  (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                )
                .map((el, index) =>
                  typeof el.reward !== "string" ? (
                    <div key={index}>
                      <div className="py-[15px] px-6 text-center mx-2  flex gap-[10px]  justify-between whitespace-nowrap">
                        <p className="flex-[2] text-[14px] text-main-text font-bold overflow-ellipsis whitespace-nowrap text-left overflow-hidden min-w-0 ">
                          {el.activity_name}
                        </p>
                        <p className="flex-1 overflow-ellipsis text-[14px] text-[#9FA9AF] whitespace-nowrap overflow-hidden min-w-0 ">
                          {el.date}
                        </p>
                        <p className="flex-1 overflow-ellipsis whitespace-nowrap overflow-hidden min-w-0 text-right text-[16px] text-main-text   ">
                          {el.points} pts
                        </p>
                      </div>
                      <div className="px-[20px] w-full">
                        <div className="w-full h-[1px]  bg-[rgb(207,212,215,0.3)] "></div>
                      </div>
                    </div>
                  ) : (
                    <div key={index}>
                      <div className="py-[8px] px-6 text-center mx-2  flex gap-[10px] items-center justify-between whitespace-nowrap">
                        <p className="flex-[2] text-[14px] text-main-text font-bold overflow-ellipsis whitespace-nowrap text-left overflow-hidden min-w-0 ">
                          {el.activity_name}
                        </p>
                        <p className="flex-1 overflow-ellipsis text-[14px] text-[#9FA9AF] whitespace-nowrap overflow-hidden min-w-0 ">
                          {el.date}
                        </p>
                        <div className="drop-shadow-iron flex-1 overflow-ellipsis whitespace-nowrap overflow-hidden min-w-0  flex justify-end">
                          <img
                            src={el.reward}
                            alt="rewards"
                            className="w-[40px] h-[50px]"
                          />
                        </div>
                      </div>
                      <div className="px-[20px] w-full">
                        <div className="w-full h-[1px]  bg-[rgb(207,212,215,0.3)] "></div>
                      </div>
                    </div>
                  )
                )
            ) : (
              <div className="flex flex-col w-full min-hk-[300px] my-auto px-4 1sm:px-6 md:px-10 py-5 justify-center items-center">
                <div className="flex flex-row">
                  <p className="text-[#CFD4D7] font-bold text-[24px] mr-[10px]">
                    Psst..
                  </p>
                  <img src={eyes} alt="eyes" />
                </div>
                <p
                  className="font-bold text-[24px] text-center"
                  style={{ color: context.mainColor }}
                >
                  Participate in more activities to view them here..
                </p>
                <p className="font-bold text-[16px] text-center text-main-text">
                  The more you participate in activities the more points &
                  badges you will earn and view here...
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="w-[100%] 3lg:w-[50%] px-[10px] overflow-auto mb-[20px] flex flex-col justify-between pb-3">
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            All Badges
          </p>
          <div className="flex-1 w-full pb-2 min-h-[300px] flex flex-col 1sm:flex-row gap-6 p-[20px] rounded-[10px] bg-white shadow-dark">
            <div>
              <div>
                <div>
                  <p className="text-[#232323] text-[16px] font-bold mb-[15px]">
                    Earned Badges
                  </p>
                  <div className="flex flex-row flex-wrap gap-[40px]">
                    {userStats?.all_earned_badges?.length > 0 ? (
                      userStats?.all_earned_badges?.map((el, index) => (
                        <ToolTip
                          key={index}
                          el={{ name: el.name, date: el.earned_date }}
                          item={
                            <img
                              src={el.icon || silverHelal}
                              alt="badge"
                              className="h-[50px] w-[40px]"
                            />
                          }
                        />
                      ))
                    ) : (
                      <p
                        style={{ color: context.mainColor }}
                        className="text-center p-[10px] font-bold"
                      >
                        No data to show
                      </p>
                    )}
                  </div>
                  <div className="w-full h-[1px] my-[20px] bg-[rgb(207,212,215,0.3)]"></div>
                </div>
              </div>
              <div>
                <p className="text-[#232323] text-[16px] font-bold mb-[15px]">
                  To be Earned
                </p>
                <div className="flex flex-row flex-wrap gap-[40px]">
                  {tobeEarned?.results?.length ? (
                    tobeEarned?.results?.map((el, index) => (
                      <ToolTip2 key={index} el={el} />
                    ))
                  ) : (
                    <p
                      style={{ color: context.mainColor }}
                      className="text-center p-[10px] font-bold"
                    >
                      No data to show
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] pb-2 3lg:w-[50%] px-[10px] overflow-auto mb-[20px] flex flex-col justify-between">
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            Earned Tiers
          </p>
          <div className="flex-1 w-full pb-2 min-h-[200px]  flex items-center flex-col 1sm:flex-row gap-6 p-[20px] rounded-[10px] bg-white shadow-dark">
            <div className="flex flex-row flex-wrap gap-[40px]">
              {Object.entries(userStats?.all_earned_tiers)?.length ? (
                Object.entries(userStats?.all_earned_tiers)?.map(
                  ([key, value]) => (
                    <ToolTip
                      el={{ name: key }}
                      item={
                        <img
                          src={value || silverHelal}
                          alt="badge"
                          className="h-[50px] w-[40px] rounded-md"
                        />
                      }
                    />
                  )
                )
              ) : (
                <p
                  style={{ color: context.mainColor }}
                  className="text-center p-[10px] font-bold"
                >
                  No data to show
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-[100%] pb-2 3lg:w-[50%] px-[10px] overflow-auto mb-[20px] flex flex-col justify-between">
          <p className="text-tiny lg:text-base font-bold mb-[20px]">
            Claimed Rewards
          </p>
          <div className="flex-1 w-full pb-2 min-h-[200px] flex items-center flex-col 1sm:flex-row gap-6 p-[20px] rounded-[10px] bg-white shadow-dark">
            <div className="flex flex-row flex-wrap gap-[40px]">
              {userStats?.all_earned_rewards?.length > 0 ? (
                userStats?.all_earned_rewards?.map((el, index) => (
                  <ToolTip
                    key={index}
                    el={{ name: el.name }}
                    item={
                      <img
                        src={el.icon || silverHelal}
                        alt="badge"
                        className="h-[50px] w-[60px] rounded-md"
                      />
                    }
                  />
                ))
              ) : (
                <p
                  style={{ color: context.mainColor }}
                  className="text-center p-[10px] font-bold"
                >
                  No data to show
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
