import { useMemo, useState, useEffect, useReducer, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../../Context/AppContext";
import { StatusPill } from "../../../components/table/StatusPill";
import { ReactComponent as Add } from "../../../assets/svg/add.svg";
import { ReactComponent as Crown } from "../../../assets/svg/crown-2.svg";
import { ReactComponent as Close } from "../../../assets/svg/orang-close.svg";
import ActiveUsersCard from "../../../components/ActiveUsersCard";
import Table from "../../../components/table/Table";
import { useGetUsersQuery } from "../../../store/admin/usersApi";
import {
  useTopUsersRanksQuery,
  useStatsQuery,
} from "../../../store/user/userApi";
import ImportUsers from "../../../components/users/ImportUsers";
import { Button } from "../../../components/shared/Button";
import Cookies from "js-cookie";
import blueBadge from "../../../assets/images/blueBadge.png";
import { initialState, reducer } from "./filtersReducer";

import avatar from "../../../assets/images/avatar.png";
import avatar2 from "../../../assets/images/avatar2.png";
import { Loading } from "../../../components/Loading";
import { SelectColumnFilter } from "../../../components/table/SelectColumnFilter";
import ModalSkeleton from "../../../components/ModalSkeleton";
import NoDataPsst from "../../../components/NoDataPsst";

function Users() {
  const context = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [limit, setLimit] = useState(
    localStorage.getItem("PAGE_LIMIT") ? localStorage.getItem("PAGE_LIMIT") : 10
  );
  const [offset, setOffset] = useState(0);
  const [shadowBoard, setShadowBoard] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState);

  const onSearch = useCallback(
    (value) =>
      dispatch({
        type: "search",
        payload: { value },
      }),
    []
  );

  const onOrder = useCallback((value) => {
    dispatch({
      type: "sortBy",
      payload: { value },
    });
  }, []);
  const onFilter = useCallback((value) => {
    dispatch({
      type: "filterBy",
      payload: { value },
    });
  }, []);
  const onLocationFilter = useCallback((value) => {
    dispatch({
      type: "locationFilter",
      payload: { value },
    });
  }, []);
  useEffect(() => {
    if (context.keyword?.length > 0) {
      onSearch(context.keyword);
    } else onSearch("");
  }, [context.keyword]);
  useEffect(() => {
    if (location.state?.city) {
      onLocationFilter(location.state?.city);
    } else onLocationFilter("");
  }, [location.state?.city]);

  const { data, isLoading, refetch } = useGetUsersQuery({
    limit: limit,
    offset: offset,
    sort_by: "-user__date_joined",
    ...state.vars,
  });
  const {
    data: topUsers,
    isLoading: usersLoading,
    refetch: refetchTopUsers,
  } = useTopUsersRanksQuery();
  const {
    data: userStats,
    isLoading: statLoading,
    refetch: refetchStats,
  } = useStatsQuery();

  useEffect(() => {
    if (location?.state === "success") refetch();
  }, [location?.state]);

  const downloadCsv = () => {
    fetch(
      `https://core.verse-stg.tam.run/gs/api/admin/profiles/export_all_users`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`, //the token is a variable which holds the token
        },
      }
    )
      .then((resp) => resp.blob())
      .then((blob) => {
        console.log(blob);
        const url = window.URL.createObjectURL(blob);
        console.log(url);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "users.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("download failed"));
  };

  const [showModal, setShowModal] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "user_info.id",
        disableSortBy: true,
      },
      {
        Header: "Name",
        accessor: "user_info.first_name",
        // Cell: AvatarCell,
        imgAccessor: "image",
        disableSortBy: true,
      },
      {
        Header: "UserName",
        accessor: "user_info.username",
        // Cell: AvatarCell,
        imgAccessor: "image",
        disableSortBy: true,
      },
      {
        Header: "Gender",
        accessor: "user_info.gender",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Persona",
        accessor: "persona",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Email",
        accessor: "user_info.email",
        disableSortBy: true,
      },
      {
        Header: "Phone Number",
        accessor: "user_info.phone_number",
        disableSortBy: true,
      },
      {
        Header: "City",
        accessor: "city",
        disableSortBy: true,
      },
      {
        Header: "Points",
        accessor: "accumulated_points",
      },
      {
        Header: "Rewards",
        accessor: "number_of_rewards",
        disableSortBy: true,
      },
      {
        Header: "Badges",
        accessor: "number_of_badges",
      },
      {
        Header: "Date Joined",
        accessor: "user_info.date_joined",
      },
      {
        Header: "Last Active",
        accessor: "user_info.last_login",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
    ],
    []
  );

  if (usersLoading) return <Loading />;
  return (
    <div className="w-full min-h-screen p-6 2lg:p-14">
      <div className="flex mb-2">
        <p className="text-xs mr-1">
          <Link to={"/dashboard/users"}>Users</Link> &gt;
        </p>

        <p className="text-xs">
          {location?.state?.city ? "Cities" : "All Users"}
        </p>
      </div>

      <div className="my-10 flex flex-row justify-between items-center">
        {location?.state?.city ? (
          <p className="text-lg lg:text-2xl font-bold mb-[20px]">
            {location?.state?.city.slice(0, 1).toUpperCase() +
              location?.state?.city.slice(1, location?.state?.city?.length)}
          </p>
        ) : shadowBoard ? (
          <p className="text-lg lg:text-2xl font-bold mb-[40px]">Leaderboard</p>
        ) : (
          <p className="text-lg lg:text-2xl font-bold mb-[40px]">All Users</p>
        )}
        {!location?.state?.city ? (
          <div className="flex flex-row flex-nowrap justify-center items-center">
            <button
              onClick={() => setShadowBoard((prev) => !prev)}
              className="flex border border-yellow-orange h-[44px] p-[7px] flex-row flex-nowrap justify-center items-center rounded-[4px] ml-3 bg-transparent font-bold text-center outline-none"
            >
              <Crown />
              <span className="text-[12px] text-yellow-orange mx-3">
                Leaderboard
              </span>
              {shadowBoard && <Close />}
            </button>
            <button
              onClick={() => setShowModal(true)}
              style={{
                border: `2px solid ${context.mainColor}`,
                color: context.mainColor,
              }}
              className="flex h-[44px] p-[7px] flex-row flex-nowrap justify-center items-center rounded-[4px] ml-3 bg-transparent font-bold text-center outline-none"
            >
              <span className="text-[12px]">Import Users</span>
            </button>
            <Button
              onClick={() => downloadCsv()}
              style={{
                border: `2px solid ${context.mainColor}`,
                color: context.mainColor,
              }}
              download
              className="flex h-[44px] p-[7px] flex-row flex-nowrap justify-center items-center rounded-[4px] ml-3 bg-transparent font-bold text-center outline-none"
            >
              <span className="text-[12px]">Export Users</span>
            </Button>
            <Link
              to={"/dashboard/users-add"}
              style={{
                border: `2px solid ${context.mainColor}`,
                backgroundColor: context.mainColor,
              }}
              className="flex h-[44px] p-[7px] flex-row flex-nowrap justify-center items-center rounded-[4px] ml-3 text-white font-bold text-center outline-none"
            >
              <Add className="h-[20px] w-[20px] mr-[10px]" />
              <span className="text-[12px]">Add New User</span>
            </Link>
          </div>
        ) : (
          <ActiveUsersCard city={location?.state?.city} count={data?.count} />
        )}
      </div>
      {!location?.state?.city && shadowBoard && (
        <div className="w-full">
          {topUsers?.top_users_ranks.length > 0 ? (
            <div className="flex-1 flex-col w-full min-h-[300px] flex  2lg:flex-row rounded-[10px] bg-white ">
              <div className="flex-1 items-start flex-col w-full 2lg:w-[50%] flex">
                <p className="text-tiny lg:text-lg font-bold mb-[20px]">
                  Top Performer
                </p>
                <section className="leaderBoard w-full h-full px-4 1sm:px-6 md:px-10 py-5 flex flex-col-reverse 1sm:flex-row justify-around items-center gap-4 xl:gap-10 rounded-[10px] bg-white bg-no-repeat bg-leaderBoard max-h-[271px] shadow-[0px_4px_40px_rgba(15,40,55,0.15)]">
                  {" "}
                  <div className="flex flex-row items-center">
                    <div className="w-[114px] h-[114px] relative">
                      <img
                        src={userStats?.info.icon || avatar2}
                        alt=""
                        className="h-full w-full rounded-full"
                      />
                      <img
                        src={userStats?.current_tier?.icon || blueBadge}
                        alt="prev badge"
                        className="absolute w-11 h-11 bottom-[-20px] right-1/2 translate-x-1/2 rounded-full bg-white"
                      />
                    </div>
                    <div className="ml-4 xl:ml-10">
                      <p className="text-tiny">Your Rank and Points</p>
                      <p className="text-2xl font-bold">
                        {userStats?.info?.first_name ||
                        userStats?.info?.last_name
                          ? userStats?.info?.first_name +
                            " " +
                            userStats?.info?.last_name
                          : userStats?.info?.username}
                      </p>
                      <div className="text-lg font-bold text-[#5E45FF]">
                        You have {userStats && userStats.info.points} points{" "}
                      </div>
                      <p className="text-lg">
                        You are in the{" "}
                        <span className="font-bold">
                          {userStats && userStats.user_rank}
                          {userStats?.user_rank === 1
                            ? "st "
                            : userStats?.user_rank === 2
                            ? "nd "
                            : userStats?.user_rank === 3
                            ? "rd "
                            : "th "}
                        </span>
                        rank
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row flex-nowrap items-end">
                    {topUsers?.top_users_ranks?.length > 0 && (
                      <>
                        <div className="z-30 flex flex-col items-center">
                          <div className="flex flex-col justify-between items-center py-2 w-[5.3125rem] h-[4.375rem] trapezoid-clip-path bg-gradient-to-t from-[#5E45FF26] to-transparent">
                            <img
                              className="rounded-full w-9 h-9"
                              src={
                                topUsers.top_users_ranks[0]["icon"] || avatar
                              }
                              alt="user-profile"
                            />
                            <p className="font-bold text-[10px]">
                              {topUsers.top_users_ranks[0]["first_name"]}
                            </p>
                          </div>
                          <div className="p-1 text-main-text text-center shadow-dark-6 rounded-[4px] rounded-br-[0px] w-[4.6875rem] min-h-[10.0625rem] bg-[#5E45FF]">
                            <span className="text-[10px] font-bold rounded-[2px] px-[16px] py-[1px] bg-[#BBB1FB]">
                              1st
                            </span>
                            <p className="text-white text-sm mt-1">
                              {topUsers.top_users_ranks[0]["points"]} pts
                            </p>
                          </div>
                        </div>
                        <div className="ml-[-12px] z-20 flex flex-col items-center">
                          <div className="flex flex-col justify-between items-center py-2 w-[85px] h-[70px]">
                            <img
                              className="rounded-full w-9 h-9"
                              src={
                                topUsers.top_users_ranks[1]["icon"] || avatar
                              }
                              alt="user-profile"
                            />
                            <p className="font-bold text-[10px]">
                              {topUsers.top_users_ranks[1]["first_name"]}
                            </p>
                          </div>
                          <div className="p-1 text-center shadow-dark-6 rounded-tr-[4px] w-[75px] min-h-[132px] bg-[#7D69FE]">
                            <span className="text-[10px] font-bold rounded-[2px] px-[16px] py-[1px] bg-[#BBB1FB]">
                              2nd
                            </span>
                            <p className="text-white text-sm mt-1">
                              {topUsers.top_users_ranks[1]["points"]}pts
                            </p>
                          </div>
                        </div>
                        <div className="ml-[-12px] z-0 flex flex-col items-center">
                          <div className="flex flex-col justify-between items-center py-2 w-[85px] h-[70px]">
                            <img
                              className="rounded-full w-9 h-9"
                              src={
                                topUsers.top_users_ranks[2]["icon"] || avatar
                              }
                              alt="user-profile"
                            />
                            <p className="font-bold text-[10px]">
                              {topUsers.top_users_ranks[2]["first_name"]}
                            </p>
                          </div>
                          <div className="bg-[#AC9FFC] p-1 text-center rounded-r-[4px] w-[75px] min-h-[120px]">
                            <span className="text-[10px] font-bold rounded-[2px] px-[16px] py-[1px] bg-[#5E45FF] text-white">
                              3rd
                            </span>
                            <p className="text-white text-sm mt-1">
                              {topUsers.top_users_ranks[2]["points"]}pts
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </section>
              </div>

              <div className="2lg:flex-1 w-full 2lg:w-[50%] 2lg:min-h-[300px] h-full 2lg:pt-0 pt-4 overflow-hidden flex flex-col rounded-bl-[10px] rounded-br-[10px] 2lg:rounded-bl-[0px] 2lg:rounded-r-[10px] bg-white">
                <p className="text-tiny lg:text-lg font-bold pl-[28px] mb-[10px]">
                  Top 5 Users
                </p>
                <table className="no-border-table">
                  <thead>
                    <tr>
                      <th className="w-[10%]"></th>
                      <th className="w-[10%]"></th>
                      <th className="w-[65%]"></th>
                      <th className="w-[10%]"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {topUsers &&
                      topUsers.top_users_ranks.slice(5).map((el, index) => (
                        <>
                          <tr
                            key={index}
                            className={
                              el.id === userStats?.info?.id
                                ? "bg-gradient-primary !text-white"
                                : ""
                            }
                          >
                            <td
                              style={
                                el.id === userStats?.id
                                  ? { color: "#ffffff" }
                                  : { color: "#6F7E87" }
                              }
                              className="text-center text-xs font-bold pl-6 py-[10px]"
                            >
                              {index + 4}th
                            </td>
                            <td className="py-[10px]">
                              <img
                                className="rounded-full w-6 h-6 lg:w-8 lg:h-8 mx-auto"
                                src={el.icon || avatar}
                                alt="user-profile"
                              />
                            </td>
                            <td className="text-tiny font-bold py-[10px]">
                              {el.first_name || el.last_name
                                ? ` ${el.first_name}  ${el.last_name}`
                                : el.username}
                            </td>
                            <td
                              style={
                                el.id === userStats?.id
                                  ? { color: "#ffffff" }
                                  : { color: "#6F7E87" }
                              }
                              className="text-right text-xs font-bold pr-4 py-[10px]"
                            >
                              {el.points}pts
                            </td>
                          </tr>
                          {!(index === topUsers.top_users_ranks.length - 1) && (
                            <tr>
                              <td>
                                <div className="w-[calc(100%-14px)] h-[1px] ml-[16px] bg-[rgb(207,212,215,0.3)]"></div>
                              </td>
                              <td>
                                <div className="w-[calc(100%+2px)] h-[1px] bg-[rgb(207,212,215,0.3)]"></div>
                              </td>
                              <td>
                                <div className="w-[calc(100%+2px)] h-[1px] bg-[rgb(207,212,215,0.3)]"></div>
                              </td>
                              <td>
                                <div className="w-[calc(100%-14px)] h-[1px] mr-[16px] bg-[rgb(207,212,215,0.3)]"></div>
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex flex-row flex-wrap mx-[-10px]">
              <div className="w-[100%] 2lg:w-[50%] px-[10px] mb-[20px] flex flex-col justify-between">
                <p className="text-tiny lg:text-base font-bold mb-[20px]">
                  Leaderboard
                </p>
                <NoDataPsst
                  mainText={"The leaderboard is not active yet"}
                  hasBackground={true}
                  secondText={
                    "Earn more points so that we can calculate your placing.."
                  }
                />
              </div>
              <div className="w-[100%] 2lg:w-[50%] px-[10px] mb-[20px]">
                <p className="text-tiny lg:text-base font-bold mb-[20px]">
                  All Users
                </p>
                <NoDataPsst
                  mainText={"Once we have more data we will display it here"}
                  secondText={
                    "Until then, keep on thriving and keep on earning more points!"
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}

      <div className="mt-14">
        {isLoading ? (
          <Loading />
        ) : data?.results?.length > 0 ? (
          <Table
            onClick={(user) => {
              navigate("/dashboard/users-profile", { state: user });
            }}
            columns={columns}
            setSortBy={onOrder}
            setFilterBy={onFilter}
            data={data?.results}
            setLimit={setLimit}
            limit={limit}
            stopSortFilterManually
            offset={offset}
            count={data?.count}
            setOffset={setOffset}
            initialState={{
              pageIndex:
                parseInt(localStorage.getItem("PAGE_KEY")) > 0
                  ? parseInt(localStorage.getItem("PAGE_KEY"))
                  : 0,
            }}
          />
        ) : (
          <p>No Users Found</p>
        )}
      </div>
      <ModalSkeleton showModal={showModal} setShowModal={setShowModal}>
        <ImportUsers setShowModal={setShowModal} />
      </ModalSkeleton>
    </div>
  );
}

export default Users;
