import { Avatar, Menu, UnstyledButton } from "@mantine/core";
import { Link } from "react-router-dom";
import VerseIcons from "../components/VerseIcons";
export default function UserMenu({ user }) {
  console.log(user, "user");
  return (
    <Menu
      classNames={{ dropdown: "shadow-notification" }}
      width={200}
      position="bottom-end"
    >
      <Menu.Target>
        <UnstyledButton className="flex items-center gap-1 ">
          {/* TODO: change src to user image & alt to user name*/}
          <Avatar
            className="rounded-full "
            src={user?.avatar || "/images/user.png"}
            alt="Mohamed"
          />
          <VerseIcons
            icon="ArrowDownIcon"
            className="h-6 w-6 text-dark hover:text-primary active:text-primary"
          />
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown className="p-0  ">
        <Link to="/home">
          <Menu.Item
            className="group  rounded-none border-b border-solid border-dark/10 text-sm font-bold text-dark hover:border-dark/10 hover:bg-primary/10 hover:text-primary "

          >
            Switch User
          </Menu.Item>
        </Link>
        <Link to="/dashboard/edit-profile">
          <Menu.Item
            className="group  rounded-none border-b border-solid border-dark/10 text-sm font-bold text-dark hover:border-dark/10 hover:bg-primary/10 hover:text-primary "
            icon={
              <VerseIcons
                icon="EditIcon"
                className="h-6 w-6   text-dark active:text-primary group-hover:text-primary"
              />
            }
          >
            Edit
          </Menu.Item>
        </Link>

        {/*
        <Menu.Item
          className="group rounded-none text-sm font-bold text-dark hover:bg-primary/10 hover:text-primary"
          icon={
            <VerseIcons
              icon="LogOutIcon"
              className="h-6 w-6 text-dark active:text-primary group-hover:text-primary"
            />
          }
        >
          Logout
        </Menu.Item> */}
      </Menu.Dropdown>
    </Menu>
  );
}
