import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_HEADERS, BASE_URL } from "../../configs/DataService";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/gs/api`,
    prepareHeaders: BASE_HEADERS,
  }),
  tagTypes: [
    "Stats",
    "Budges",
    "Rewards",
    "Events",
    "Ranks",
    "userInfo",
    "Activities",
  ],
  endpoints: ({ query, mutation }) => ({
    userInfo: query({
      query: () => ({
        url: "user_info",
        method: "GET",
      }),
      providesTags: ["userInfo"],
    }),
    stats: query({
      query: () => ({
        url: "stats",
        method: "GET",
      }),
      providesTags: ["Stats"],
    }),
    badges: query({
      query: () => ({
        url: "badges",
      }),
      providesTags: ["Budges"],
    }),
    badgesToBeEarn: query({
      query: () => ({
        url: "badges_to_be_earned",
      }),
      providesTags: ["Budges"],
    }),
    rewards: query({
      query: () => ({
        url: "rewards",
      }),
      providesTags: ["Rewards"],
    }),
    events: query({
      query: () => ({
        url: "events",
      }),
      providesTags: ["Events"],
    }),
    activities: query({
      query: () => ({
        url: "all_user_activities",
      }),
      providesTags: ["Activities"],
    }),
    topUsersRanks: query({
      query: () => ({
        url: "top_users_ranks",
      }),
      providesTags: ["Ranks"],
    }),
    redeemPoints: mutation({
      query: (body) => ({
        url: "redeem_points",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Stats"],
    }),
    notifications: query({
      query: () => ({
        url: "notifications",
      }),
    }),
    userConfigration: query({
      query: () => ({
        url: "https://core.verse-stg.tam.run/gs/api/user_configurations",
      }),
      providesTags: ["Configrations"],
    }),
    addNotifications: mutation({
      query: (body) => ({
        url: "https://core.verse-stg.tam.run/gs/api/user_configurations",
        method: "POST",
        body,
      }),
      providesTags: ["Configrations"],
    }),
    updateNotifications: mutation({
      query: ({ id, ...body }) => ({
        url: `https://core.verse-stg.tam.run/gs/api/user_configurations/${id}`,
        method: "PUT",
        body,
      }),
      providesTags: ["Configrations"],
    }),
    updateUser: mutation({
      query: (body) => ({
        url: "update_profile",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userInfo"],
    }),
    addPoints: mutation({
      query: (body) => ({
        url: "add_points",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userInfo"],
    }),
  }),
});

export const {
  useStatsQuery,
  useUserInfoQuery,
  useBadgesQuery,
  useEventsQuery,
  useRewardsQuery,
  useBadgesToBeEarnQuery,
  useTopUsersRanksQuery,
  useRedeemPointsMutation,
  useUpdateUserMutation,
  useActivitiesQuery,
  useNotificationsQuery,
  useUserConfigrationQuery,
  useAddNotificationsMutation,
  useUpdateNotificationsMutation,
  useAddPointsMutation,
} = userApi;
