import React, { useEffect } from "react";
import Select, { components } from "react-select";
import { SortDownIcon } from "../shared/Icons";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SortDownIcon className="text-[18px] text-main-text mb-[6px]" />
    </components.DropdownIndicator>
  );
};
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  const options = React.useMemo(() => {
    const options =
      id === "user_info.gender"
        ? [
            {
              label: "Male",
              value: "Male",
            },
            {
              label: "Female",
              value: "Female",
            },
          ]
        : id === "persona"
        ? [
            {
              label: "Student",
              value: "Student",
            },
            {
              label: "Student0",
              value: "Student0",
            },
            {
              label: "Student1",
              value: "Student1",
            },
            {
              label: "Student2",
              value: "Student2",
            },
            {
              label: "Student3",
              value: "Student3",
            },
          ]
        : [
            {
              label: "Active",
              value: "active",
            },
            {
              label: "InActive",
              value: "inactive",
            },
          ];
    return options;
  }, []);

  return (
    <label className="flex flex-row flex-wrap items-center">
      {/* <span className="text-gray-700 mr-2">{render("Header")}: </span> */}
      <div className="shadow-table-head-dark rounded-lg w-fit">
        <Select
          className="text-center text-xs font-bold"
          name={id}
          id={id}
          value={
            filterValue
              ? { value: filterValue, label: filterValue }
              : {
                  value: "",
                  label: `${render("Header")}`,
                }
          }
          defaultValue={{ value: "", label: `${render("Header")}` }}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            setFilter(e.value);
          }}
          isSearchable={false}
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              padding: "0px 4px",
              boxShadow: "none",
            }),
          }}
          options={options}
        />
      </div>
    </label>
  );
}
