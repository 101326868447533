import React from "react";
import avatar from "../assets/images/avatar.png";
function UserCard({ user }) {
  return (
    <div className="relative h-[72px]  w-full bg-white flex-initial shadow-card-shadow rounded-r-[10px]">
      <div className="w-2 h-[72px] bg-primary-one absolute left-0 rounded-l-[10px]" />
      <div className="flex items-center pl-4 h-full">
        <img src={avatar} alt="avatar" className="w-8 h-8 rounded-full mr-2" />
        <div className="flex flex-col">
          <p className="font-bold text-sm text-[#232323] mb-[11px]">
            {user.first_name || user.last_name
              ? user.first_name + " " + user.last_name
              : user.username}
          </p>
          <div className="flex items-center">
            <p className="text-primary-one text-[10px] font-bold">
              {user?.points}pt
            </p>

            {/* <p className="text-primary-one text-[10px] font-bold flex items-center">
              <div className="w-1 h-1  bg-[#232323] rounded-full mx-2" />
              {user.number_of_badges} Badges
            </p>
            <p className="text-primary-one text-[10px] font-bold flex items-center">
              <div className="w-1 h-1  bg-[#232323] rounded-full mx-2" />
              {user.number_of_rewards} Rewards
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCard;
