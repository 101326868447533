import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";

export const schema = yup.object().shape({
  name: yup.mixed().test("required", "You need to provide a name", (value) => {
    return value && value.length;
  }),

  points: yup
    .mixed()
    .test("required", "You need to provide points", (value) => {
      return value;
    }),
  description: yup
    .mixed()
    .test("required", "You need to provide a description", (value) => {
      return value && value.length;
    }),
    platform: yup.number().required("platform is Required"),

});
