import Cookies from "js-cookie";
import { createContext, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { authApi, useProfileQuery } from "../store/auth/authApi";
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(Cookies.get("access_token"));
  const login = (user) => {
    setUser(user);
  };
  const dispatch = useDispatch();

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("refresh_token");
    setTimeout(() => {
      dispatch(authApi.util.resetApiState());
      window.location.replace(
        `${process.env.REACT_APP_API}/auth/logout?next=https://verse-stg.tam.run`
      );
    }, 200);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
