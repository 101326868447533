import React, { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";
import { ReactComponent as Points } from "../../assets/svg/points.svg";
import { toast } from "react-toastify";
import warning from "../../assets/images/warning.png";
import Modal from "../Modal";
import Dropdown from "../Dropdown";
import { useNavigate } from "react-router-dom";
import congratsImage from "../../assets/images/congrats.png";

import {
  useDeleteAdminBadgeMutation,
  useDeleteAdminTeirMutation,
  usePlatformsQuery,
  useActivitiesQuery,
} from "../../store/admin/adminApi";
function BadgeCard({ element, setItemToedit, setShowModal, page }) {
  const platformId = page === "tier" ? element?.platform : element?.platform[0];

  const context = useAppContext();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [
    deleteAdminBadge,
    { isError, isSuccess, error, isLoading: badgeDelLoading },
  ] = useDeleteAdminBadgeMutation();
  const [
    deleteAdminTeir,
    {
      isError: isErrorTier,
      isSuccess: isSuccessTier,
      error: errorTier,
      isLoading: tierDelLoading,
    },
  ] = useDeleteAdminTeirMutation();
  const { data: activities } = useActivitiesQuery();
  const { data: platforms } = usePlatformsQuery();
  function handleDeleteBadge() {
    if (element.id) {
      deleteAdminBadge({ id: element.id });
    }
  }
  function handleDeleteTier() {
    if (element.id) {
      deleteAdminTeir({ id: element.id });
    }
  }
  useEffect(() => {
    if (isSuccess || isSuccessTier) {
      setIsOpen(false);
      context.setAndShowModalData({
        title: `${isSuccess ? "Badge" : "Tier"}  Deleted Successfully!!`,
        subtitle: ``,
        description: "",
        image: congratsImage,
      });
      navigate(isSuccess ? "/dashboard/badges" : "/dashboard/tiers");
    }
    if (isError || isErrorTier) {
      toast.error(
        (error?.data && error?.data?.msg) ||
          (errorTier?.data && errorTier?.data?.msg) ||
          "something went wrong!"
      );
    }
  }, [isSuccess, isError, isErrorTier, isSuccessTier]);
  return (
    <div className="min-w-[280px] min-h-[150px]">
      <div className="relative w-full h-full flex-initial flex flex-row p-4 items-center shadow-dark bg-white rounded-[16px]">
        <img
          className="h-[50px] w-[50px] rounded-full object-contain"
          src={element.icon === "" ? "/images/tam-logo.svg" : element.icon}
          alt=""
        />
        <div className="ml-[16px] flex-auto max-w-[140px]">
          <div className="flex flex-row justify-between items-center">
            <p className="text-[10px] text-[rgb(15,40,55,0.5)]">
              ID: {element.id}
            </p>
          </div>
          <div className="flex flex-row justify-between items-center">
            <p className="max-w-[120px] text-[16px] text-[#0f2837] font-bold w-full overflow-hidden text-ellipsis">
              {element.name}
            </p>
          </div>
          <p className="text-[12px] text-[#0f2837] mt-[5px]">
            Earned By: {element.earned_by}
          </p>
          <p className="text-[12px] text-[#9FA9AF] mt-[5px]">
            Created on: {element.date_created}
          </p>
          <p className="text-[12px] text-[#0F2837] mt-[5px]">
            Platform:{" "}
            {platforms?.results
              .filter((ii) => ii.id === platformId)
              .map((it) => it.name)}
          </p>

          {element?.activity?.length > 0 && (
            <p className="text-[12px] text-[#0F2837] mt-[5px]">
              Activity:{" "}
              {activities?.results
                .filter((ii) => ii.id === element.activity[0])
                .map((it) => it.name)}
            </p>
          )}
          {element.status.toLowerCase() == "active" ? (
            <div className="flex flex-row items-center mt-[5px]">
              <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#9BC368]" />
              <p className="text-[#9BC368] text-[10px] font-bold">Active</p>
            </div>
          ) : (
            <div className="flex flex-row flex-nowrap items-center mt-[5px]">
              <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#A52C38] " />
              <p className="text-[#A52C38] text-[10px] font-bold">Inactive</p>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-start items-end w-fit h-full ml-1">
          <Points
            style={{ color: context.mainColor }}
            className="cursor-pointer"
            onClick={() => setIsOpen(true)}
          />
          <Dropdown
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            minWidth="135px"
            minHeight="30px"
            top="44px"
            right="0px"
            items={[
              <div
                onClick={() => {
                  setShowModal(true);
                  setItemToedit(element);
                }}
              >
                Edit
              </div>,
              <div
                onClick={() => {
                  setShowModalDelete(true);
                  setIsOpen(false);
                }}
              >
                Delete{" "}
              </div>,
            ]}
          />
          <p
            style={{ color: context.mainColor }}
            className="text-[10px] mt-[10px] font-bold"
          >
            {element.points}pts
          </p>
        </div>
      </div>
      <Modal
        isDelete={true}
        showModal={showModalDelete}
        setShowModal={setShowModalDelete}
        image={warning}
        isActive={true}
        title={page === "tier" ? "Delete Tier" : "Delete Badge"}
        isLoading={badgeDelLoading || tierDelLoading}
        deleteItem={page === "tier" ? handleDeleteTier : handleDeleteBadge}
        subtitle={
          <span>
            Are you sure you want to delete
            <span className="text-warning font-bold"> {element.name}</span>
            {page === "tier" ? " tier" : " badge"} ?
          </span>
        }
      />
    </div>
  );
}

export default BadgeCard;
