import ModalSkeleton from "./ModalSkeleton";
import CloseIcon from "./Icons/CloseIcon";
import { useNavigate } from "react-router-dom";

export default function ModalUserRedeem({ showModal, setShowModal }) {
	const navigate = useNavigate();

	return (
		<ModalSkeleton
			setShowModal={setShowModal}
			showModal={showModal}
			className="flex flex-col"
		>
			<div className="flex justify-end w-full">
				<CloseIcon
					onClick={() => {
						setShowModal(false);
					}}
				/>
			</div>
			<div className="flex justify-center">
				<img src="/images/redeemGraphics.png" alt="redeem graphics" />
			</div>
			<div className="w-full flex justify-center mt-5">
				<p className="font-bold text-2xl md:w-[75%] lg:w-[60%] text-center">
					Redeem your <span className="text-[#5E45FF]">1500 Points</span> now
					And claim your reward
				</p>
			</div>

			<button
				className="p-3 px-6 bg-gradient-button text-white font-bold rounded-md mx-auto mt-5"
				onClick={() => {
					setShowModal(false);
					navigate("/home/rewards");
				}}
			>
				Redeem Points
			</button>
		</ModalSkeleton>
	);
}
