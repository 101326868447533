import { Dialog } from "@headlessui/react";
import PropTypes from "prop-types";

export default function FormModalBody({ children, title }) {
  return (
    <>
      <Dialog.Title
        as="h3"
        className="text-2xl font-bold text-[#0f2837] mb-6 border-b-1 pb-4 border-[#DEE5ED]"
      >
        {title}
      </Dialog.Title>

      {children}
    </>
  );
}

FormModalBody.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
