import React, { useState, useEffect } from "react";
import { useAppContext } from "../../../Context/AppContext";
import InputText from "../../InputText";
import ImageUpload from "../../../utils/ImageUpload";
import { toast } from "react-toastify";
import Switch from "react-switch";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import congratsImage from "../../../assets/images/congrats.png";
import { useNavigate } from "react-router-dom";
import {
  useAddAdminTeirMutation,
  useUpdateAdminTeirMutation,
  usePlatformsQuery,
} from "../../../store/admin/adminApi";
import SelectComponent from "../../select";

const schema = yup.object().shape({
  name: yup.string().required("Please Provide Tier name"),
  points: yup.number().required("points is Required"),
  description: yup.string().required("Please Provide Tire description"),
  platform: yup.number().required("platform is Required"),
});

function AddFormTier({ setShowModalAdd, tierData }) {
  const [image, setImage] = useState({
    image: tierData?.icon ? tierData?.icon : "",
  });
  const [platformOptions, setPlatformOptions] = useState([]);

  const [addTier, { isSuccess, isError, error }] = useAddAdminTeirMutation();

  const [
    updateTier,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = useUpdateAdminTeirMutation();
  const context = useAppContext();
  const navigate = useNavigate();
  const { data: platforms } = usePlatformsQuery();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { status: false },
    mode: "all",
  });
  useEffect(() => {
    if (platforms) {
      setPlatformOptions((prev) => [
        ...platformOptions,
        ...platforms?.results?.map((i) => ({
          value: i.id,
          label: i.name,
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platforms]);
  const submitHandler = async (data) => {
    if (tierData)
      return updateTier({
        ...data,
        icon: image.image || tierData.icon,
        status: watch("status") ? "active" : "inactive",
      });
    addTier({
      ...data,
      icon: image.image,
      status: watch("status") ? "active" : "inactive",
    });
  };

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      setShowModalAdd(false);
      context.setAndShowModalData({
        title: `Tier ${
          isSuccess ? "Added" : tierData?.name + " " + "Edited"
        } Successfully!!`,
        subtitle: ``,
        description: "",
        image: congratsImage,
      });
      navigate("/dashboard/tiers");
    }
    if (isError || isUpdateError)
      toast(error?.message || updateError.message, {
        autoClose: 1500,
        type: "error",
      });
  }, [isSuccess, isError, error, isUpdateSuccess, isUpdateError, updateError]);

  useEffect(() => {
    if (tierData) {
      setImage({ image: tierData.icon });
      reset({
        ...tierData,
        status: tierData.status === "active" ? true : false,
      });
    }
  }, [tierData, reset]);

  return (
    <div className="text-center flex flex-col items-center ">
      <p className="font-bold text-[24px] text-[#0f2837]">
        {tierData ? "Updating An Existing Tier" : "Creating A New Tier"}
      </p>
      <p className="font-bold text-[14px] mt-[30px] text-[#0f2837]">
        Tier Details
      </p>
      <p className="text-[12px] mt-[8px] text-[#87949B]">
        Fill out tier details
      </p>
      <form
        className="text-center flex flex-col   items-center "
        onSubmit={handleSubmit(submitHandler)}
      >
        <div className="flex-initial w-[245px] mt-[15px] max-w-[500px]">
          <InputText
            type="text"
            err={errors.name}
            name="name"
            labelText="Tier Name"
            id="name"
            register={register}
          />
          {errors.name && (
            <p className="text-left text-[12px] text-[#cc0000]">
              {errors.name.message}
            </p>
          )}
        </div>
        <div className="flex-initial w-[245px] mt-[15px] max-w-[500px]">
          <InputText
            type="number"
            err={errors.points}
            labelText="Points to earn the tier"
            id="points"
            name="points"
            register={register}
          />
          {errors.points && (
            <p className="text-left text-[12px] text-[#cc0000]">
              {errors.points.message}
            </p>
          )}
        </div>
        <div className="flex-initial w-[245px] mt-[15px] max-w-[500px]">
          <InputText
            type="text"
            labelText="Description"
            name="description"
            id="description"
            register={register}
          />
          {errors.description && (
            <p className="text-left text-[12px] text-[#cc0000]">
              {errors.description.message}
            </p>
          )}
        </div>
        <div className="flex flex-col justify-center w-min">
          <SelectComponent
            id="platform"
            className="text-xs mt-[15px] w-[245px] max-w-[500px] border-1 border-input-color rounded-md "
            placeholder="Select Platform..."
            isSearchable={false}
            defaultValue={
              tierData?.platform && {
                label: platforms?.results
                  .filter((ii) => ii.id === tierData?.platform)
                  .map((it) => it.name),
                value: tierData.platform,
              }
            }
            onChange={(e) => setValue("platform", e.value)}
            options={platformOptions}
          />
          {errors.platform && (
            <p className="text-left text-[12px] text-[#cc0000]">
              {errors.platform.message}
            </p>
          )}
        </div>
        <p className="text-[12px] mt-[15px] ml-[20px] self-start text-[#87949B]">
          Status :
        </p>
        <div className="flex flex-row items-center mt-[7px] gap-[10px] justify-center">
          {watch("status") ? (
            <div className="flex flex-row items-center mt-[5px]">
              <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#9BC368]" />
              <p className="text-[#9BC368] text-[10px] font-bold">Active</p>
            </div>
          ) : (
            <div className="flex flex-row flex-nowrap items-center mt-[5px]">
              <div className="h-[8px] w-[8px] rounded-full mr-[5px] bg-[#A52C38] " />
              <p className="text-[#A52C38] text-[10px] font-bold">Inactive</p>
            </div>
          )}
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value, name } }) => (
              <Switch
                activeBoxShadow={""}
                uncheckedIcon={false}
                checkedIcon={true}
                checked={value}
                name={name}
                onChange={onChange}
                onColor={context.mainColor}
                offColor={context.secondaryColor}
              />
            )}
          />
        </div>
        <ImageUpload
          image={image.image || tierData?.icon}
          data={image}
          setData={setImage}
        />

        {image.image && (
          <>
            <div className="mt-6">
              <img src={image.image} alt="" className=" w-20 h-20" />
            </div>
          </>
        )}

        <div className="flex items-center justify-center mt-[30px]  p-8 border-t-1 border-solid border-color rounded-b">
          <button
            style={{
              border: `2px solid ${context.mainColor}`,
              color: context.mainColor,
            }}
            className="flex flex-row flex-nowrap justify-center items-center whitespace-nowrap min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-xs lg:text-tiny outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModalAdd(false)}
          >
            Cancel
          </button>
          <button
            style={{
              color: "#fff",
              border: `2px solid ${context.mainColor}`,
              backgroundColor: context.mainColor,
            }}
            className="flex whitespace-nowrap ml-[15px] flex-row flex-nowrap justify-center items-center min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-xs lg:text-tiny outline-none focus:outline-none ease-linear transition-all duration-150"
            type="submit"
          >
            {tierData ? "Update" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddFormTier;
