import React from "react";
import { Outlet } from "react-router-dom";
import NavBarUsers from "../../components/NavBarUsers";

function HomePage() {
	return (
		<div className="min-h-screen realtive ">
			<NavBarUsers />
			<Outlet />
			{/* <Themes /> */}
		</div>
	);
}

export default HomePage;
