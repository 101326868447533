import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changeOverlayState } from "../store/app/overlaySlice";
import TransitionOverlay from "./transitionOverlay/TransitionOverlay";

const BackToVerse = () => {
  const dispatch = useDispatch();
  const [btnAnimate, setBtnAnimate] = useState(false);
  return (
    <>
      <div
        onClick={(e) => {
          setBtnAnimate(true);
          dispatch(
            changeOverlayState({
              isActive: true,
              top: e.pageY,
              left: e.pageX,
            })
          );
          setTimeout(() => {
            window.location.replace("https://verse-stg.tam.run/dashboard");
          }, 500);
        }}
        className="fixed font-semibold cursor-pointer select-none bg-gradient-button text-white right-0 top-[10.5%] z-50 py-2 px-5 rounded-tl-full rounded-bl-full shadow-hoverCard"
      >
        Back To Verse
      </div>
      {btnAnimate && <TransitionOverlay />}
    </>
  );
};
export default BackToVerse;
