import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Button } from "../../../components/shared/Button";
import "react-toastify/dist/ReactToastify.css";
import { useAppContext } from "../../../Context/AppContext";
import { ReactComponent as EditIcon } from "../../../assets/svg/edit.svg";
import SuccessNotif from "../../../components/SuccessNotif";
// import InputTopLabel from "../../../components/InputTopLabel";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./YupSchema";

import {
  useUserInfoQuery,
  useUpdateUserMutation,
} from "../../../store/user/userApi";

function EditProfile() {
  // upload image api
  const uploadImage = async (formData) => {
    // post image with fetch
    return await fetch("https://jovial-api.tam.run/upload/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjczMzU3MzIwLCJpYXQiOjE2NzMyNzA5MjAsImp0aSI6ImE4MGUxNjEwY2FmMTRmNDZiNjAxNGQ0NDEwNzYxYjlmIiwidXNlcl9pZCI6M30.KMI2FQIZInLFBMP9dVtlxNG7yhX4i2YlJtmCUAMlJck`,
      },
      body: formData,
    }) // get response
      .then((response) => response.json())
      .then((response) => {
        return response;
      });

    // return await axiosInstance.post('/upload/', formData);
  };

  const { data } = useUserInfoQuery();
  const alldata = data?.info;
  const [updateUser, { isSuccess, isLoading }] = useUpdateUserMutation();

  const context = useAppContext();

  const [isMessageVisible, setIsMessageVisible] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      isMessageVisible && setIsMessageVisible((prev) => !prev);
    }, 5000);
  }, [isMessageVisible]);

  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: alldata,
    resolver: yupResolver(schema),
  });

  const vals = getValues();

  console.log(vals);
  const onSubmit = (data) => {
    console.log(data);
    updateUser(data);
    setIsMessageVisible(true);
  };

  useEffect(() => {
    if (alldata) reset(alldata);
  }, [alldata, reset]);

  // function handleImageChange(e) {
  //   if (e.target.files.length > 0) {
  //     if (
  //       e.target.files[0].type === "image/png" ||
  //       e.target.files[0].type === "image/jpeg"
  //     ) {
  //       setUser({ ...user, icon: URL.createObjectURL(e.target.files[0]) });
  //     } else {
  //       toast.error("please provide png/jpeg image");
  //     }
  //   } else {
  //     toast.error("please provide an image profile");
  //   }
  // }

  // async function handleSubmit() {
  //   if (
  //     user.first_name &&
  //     user.first_name.length > 0 &&
  //     user.last_name &&
  //     user.last_name.length > 0 &&
  //     user.email &&
  //     user.email.length > 0 &&
  //     user.phone_number &&
  //     user.phone_number > 0
  //     // user.city &&
  //     // user.city.length > 0 &&
  //     // user.persona &&
  //     // user.persona.length > 0
  //   )
  //     updateUser(user);

  //   console.log(user);
  // }

  // const [user, setUser] = useState({});

  // function getUser() {
  //   setUser(alldata);
  // }

  // useEffect(() => {
  //   getUser();
  // }, []);

  const [imgSrc, setImgSrc] = useState();

  const onChangeImage = async (file) => {
    const formData = new FormData();
    formData.append("file_uploaded", file.target.files[0]);
    const url = await uploadImage(formData);
    setImgSrc(url?.url_link);
    return url?.url_link;
  };
  useEffect(() => localStorage.clear(), []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full h-full bg-white overflow-auto p-10">
        <p className="text-xs mb-4">
          <Link to={"/dashboard/activities"}>Settings</Link> &gt;
        </p>
        <div>
          <ToastContainer />
        </div>
        {isMessageVisible && isSuccess && (
          <SuccessNotif
            setIsMessageVisible={setIsMessageVisible}
            message="Your profile has been updated successfully!"
          />
        )}
        <p className="text-2xl font-bold mb-10">Edit Profile</p>
        <div>
          <div className="relative rounded-full w-[80px] h-[80px] overflow-hidden">
            <label htmlFor="icon">
              <div className="w-full h-full cursor-pointer bg-[#0f2837]/50 flex items-center justify-center z-[1] absolute">
                <EditIcon className="w-[20px] h-[20px] text-white" />
              </div>
              {/* <img
              className="rounded-full w-full h-full"
              src={user.profile_image}
              alt="user-profile"
            /> */}
            </label>
            {/* <input
            type="file"
            id="icon"
            name="icon"
            accept="image/png, image/jpeg"
            onChange={handleImageChange}
            className="hidden"
          /> */}

            <div className=" grid grid-cols-2 px-6 items-center  overflow-hidden border-b-2 border-border-color md:grid-cols-3 md:py-6 ">
              <div className="w-full col-span-2 text-main-gray">
                <div className="flex w-full flex-col items-center justify-center rounded-md border-2 border-inputColor placeholder:main-gray bg-white py-3  px-4 outline-gray-400 md:w-8/12">
                  <label htmlFor="avatar" className="cursor-pointer">
                    <img src={vals.icon} alt="icon" />
                    <input
                      {...register("icon")}
                      hidden
                      type="file"
                      name="icon"
                      id="icon"
                      accept="image/png, image/jpeg"
                      onChange={(e) => {
                        onChangeImage(e).then((data) => {
                          setValue("icon", data);
                        });
                      }}
                    />
                  </label>

                  <p className="font-HelveticaNeueLTArabicBold capitalize text-dark-gray">
                    {`${imgSrc ? "uploaded" : "Upload  image"} `}
                  </p>
                  <p className="text-sm text-main-gray">
                    Image must be less than (2MB)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[100%] 3lg:w-[50%] h-fit flex flex-row flex-wrap mx-[-14px]">
            <div className="w-[100%] md:w-[50%] mt-[40px] px-[14px]">
              <input
                {...register("first_name", { required: true })}
                className="block px-[14px] mt-[12px] w-full h-[50px] text-[#87949B] bg-white rounded-[2px] border-1 appearance-none focus:outline-none focus:ring-0 peer border-[#87949B] "
                placeholder="Enter first Name"
              />
              {errors.first_name && (
                <p className="mt-1 text-sm  text-center text-red-500">
                  {errors.first_name.message}
                </p>
              )}
            </div>
            <div className="w-[100%] md:w-[50%] mt-[40px] px-[14px]">
              <input
                {...register("last_name", { required: true })}
                defaultValue={defaultValues?.last_name}
                className="block px-[14px] mt-[12px] w-full h-[50px] text-[#87949B] bg-white rounded-[2px] border-1 appearance-none focus:outline-none focus:ring-0 peer border-[#87949B] "
                placeholder="Enter last Name"
              />
            </div>
            <div className="w-[100%] md:w-[50%] mt-[40px] px-[14px]">
              <input
                {...register("email", { required: true })}
                defaultValue={defaultValues?.email}
                className="block px-[14px] mt-[12px] w-full h-[50px] text-[#87949B] bg-white rounded-[2px] border-1 appearance-none focus:outline-none focus:ring-0 peer border-[#87949B] "
                placeholder="Enter email"
              />
            </div>
            <div className="w-[100%] md:w-[50%] mt-[40px] px-[14px]">
              <input
                type="number"
                {...register("phone", { required: true })}
                defaultValue={defaultValues?.phone_number}
                className="block px-[14px] mt-[12px] w-full h-[50px] text-[#87949B] bg-white rounded-[2px] border-1 appearance-none focus:outline-none focus:ring-0 peer border-[#87949B] "
                placeholder="Enter phone_number"
              />
            </div>
            {/* <div className="w-[100%] md:w-[50%] mt-[40px] px-[14px]">
            <InputTopLabel
              type="text"
              id="city"
              label="City"
              value={user.city}
              onChange={(e) => setUser({ ...user, city: e.target.value })}
              placeholder="City"
            />
          </div> */}
            {/* <div className="w-[100%] md:w-[50%] mt-[40px] px-[14px]">
            <InputTopLabel
              type="text"
              id="personatype"
              label="Persona Type"
              value={user.persona}
              onChange={(e) => setUser({ ...user, persona: e.target.value })}
              placeholder="Persona Type"
            />
          </div> */}
          </div>
          <div className="flex flex-row flex-wrap gap-[8px] mt-[60px]">
            <button
              type="button"
              style={{
                borderColor: context.mainColor,
                color: context.mainColor,
              }}
              onClick={() => reset()}
              className="h-[38px] px-8 text-xs font-bold flex items-center justify-center rounded-[4px] border-[2px] whitespace-nowrap outline-none"
            >
              Cancel
            </button>
            <Button
              type="submit"
              isLoading={isLoading}
              style={{
                backgroundColor: context.mainColor,
              }}
              className="h-[38px] px-5 text-xs font-bold rounded-[4px] text-white whitespace-nowrap outline-none"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EditProfile;
