import React from "react";
import { useAppContext } from "../Context/AppContext";
import tam from "../assets/images/tam.png";
import { useStatsQuery } from "../store/user/userApi";
function SystemCard({ item, updatePlatforms, setShowModalAdd, setItemToedit }) {
  const context = useAppContext();
  const { data: userStats, isLoading: statLoading } = useStatsQuery();

  return (
    <div className="relative  bg-white rounded-2xl w-[210px] h-[260px] pt-[60px] pb-[57px] px-4 text-center shadow-dark-3 hover:shadow-dark-4 transition-transform duration-500 hover:-translate-y-5">
      <div
        onClick={() => {
          setItemToedit(item);
          setShowModalAdd(true);
        }}
        className="absolute right-3 cursor-pointer active:scale-105 z-10 top-3"
      >
        <img src="/images/edit.svg" alt="" />
      </div>
      <div className="absolute -top-10 right-[50%] left-[50%] translate-x-[-50%] overflow-hidden rounded-full w-20 h-20">
        <img
          className="w-full h-full rounded-full object-contain"
          src={item.icon || tam}
          alt="system"
        />
      </div>
      <div className="flex flex-col justify-between items-center w-full h-full">
        <div>
          <p className="text-base font-bold mb-1">{item.name}</p>
          <p className="text-xs text-sub-text">ID: {item.id} </p>
        </div>
        <div>
          <p className="text-xs mb-1">
            Integrated on:{" "}
            <span className=" font-bold">{item.date_of_integration}</span>
          </p>
          <p className="text-xs">
            By:{" "}
            <span className=" font-bold">
              {userStats?.info?.first_name
                ? userStats?.info?.first_name
                : userStats?.info?.username}
            </span>
          </p>
        </div>
        <div>
          <p className="text-xs">
            Current Status:{" "}
            {item.status.toLowerCase() === "active" ? (
              <span className="text-green font-bold">Active</span>
            ) : (
              <span style={{ color: context.mainColor }} className="font-bold">
                Deactivated
              </span>
            )}
          </p>
        </div>
      </div>

      {item.status.toLowerCase() !== "active" ? (
        <button
          onClick={() => updatePlatforms({ ...item, status: "active" })}
          className="w-full h-11 rounded-b-2xl absolute bottom-0 right-0 left-0 cursor-pointer bg-green text-white text-tiny font-bold outline-none border-none"
        >
          Activate
        </button>
      ) : (
        <button
          onClick={() => updatePlatforms({ ...item, status: "inactive" })}
          style={{ backgroundColor: context.mainColor }}
          className="w-full h-11 rounded-b-2xl absolute bottom-0 right-0 cursor-pointer text-white text-tiny font-bold outline-none border-none"
        >
          Deactivate
        </button>
      )}
    </div>
  );
}

export default SystemCard;
