import React from "react";
import BadgeSilver from "../../assets/svg/badge-silver.svg";

export function AvatarCell({ value, column, row }) {
  return (
    <div className="flex items-center justify-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img
          className="h-10 w-10 rounded-full"
          src={row.original[column.imgAccessor]|| BadgeSilver}
          alt="avatar"
        />
      </div>
      <div className="ml-4">
        <div className="text-xs font-bold">{value}</div>
      </div>
    </div>
  );
}
