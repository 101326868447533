import { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import HorizontalBarChart from "../../components/charts/HorizontalBarChart";
import BarChart from "../../components/charts/BarChart";
import { ReactComponent as Users } from "../../assets/svg/Users.svg";
import { ReactComponent as UsersEngagement } from "../../assets/svg/UsersEngagement.svg";
import { ReactComponent as BadgesEarned } from "../../assets/svg/BadgesEarned.svg";
import { ReactComponent as RewardsEarned } from "../../assets/svg/RewardsEarned.svg";
import { ReactComponent as PointsEarned } from "../../assets/svg/PointsEarned.svg";
import { ReactComponent as Calendar } from "../../assets/svg/calendar.svg";
import UserCard from "../../components/UserCard";
import frame1 from "../../assets/images/Frame1.png";
import frame2 from "../../assets/images/Frame2.png";
import frame3 from "../../assets/images/Frame3.png";
import silverBadge from "../../assets/images/silver-badge.png";

import Card1 from "../../components/Card1";
import Card2 from "../../components/Card2";
import Card3 from "../../components/Card3";
import Card4 from "../../components/Card4";
import {
  useAdminStatsQuery,
  useTopEarnedBadgesQuery,
  useUsersStatsQuery,
} from "../../store/admin/adminDashboardApi";
import { useStatsQuery, useTopUsersRanksQuery } from "../../store/user/userApi";
import { set } from "lodash";

const options = [
  { value: "months", label: "Months" },
  { value: "years", label: "Years" },
];
const options2 = [
  { value: "month", label: "Months" },
  { value: "year", label: "Years" },
];
const colors = [
  { bgColor: "bg-[#DAD5FA]", txtColor: "text-[#5E45FF]" },
  { bgColor: "bg-[#FAEBD2]", txtColor: "text-[#FDB236]" },
  { bgColor: "bg-[#D5E5F2]", txtColor: "text-[#4397D8]" },
];
const cardsEndpoints = [
  { url: "points_earned_stats", icon: "points_earned" },
  { url: "rewards_earned_stats", icon: "rewards_earned" },
  { url: "badges_earned_stats", icon: "badges_earned" },
  { url: "new_users_stats", icon: "users" },
  { url: "user_engagment_stats", icon: "users_engagement" },
];
const citiesFrams = [
  { frame: frame1, shadow: "rgba(235, 90, 60, 0.35)" },
  { frame: frame3, shadow: "rgba(110, 25, 70, 0.35)" },
  { frame: frame2, shadow: "rgba(145, 185, 180, 0.35)" },
];
function Home() {
  function cardIcon(type) {
    switch (type) {
      case "users":
        return <Users className="w-full h-full" />;
      case "users_engagement":
        return <UsersEngagement className="w-full h-full" />;
      case "badges_earned":
        return <BadgesEarned className="w-full h-full" />;
      case "rewards_earned":
        return <RewardsEarned className="w-full h-full" />;
      case "points_earned":
        return <PointsEarned className="w-full h-full" />;

      default:
        break;
    }
  }
  const context = useAppContext();
  const [newUsersDateType, setNewUsersDateType] = useState("months");
  const [userstats, setUserStats] = useState("month");
  const [data, setData] = useState(null);
  const [userStateData, setUserStateData] = useState(null);
  const [usersPerforming, setUsersPerforming] = useState(null);
  const [mostAvtiveUsers, setMostAvtiveUsers] = useState(null);
  const [topEarnedBadges, setTopEarnedBadges] = useState(null);
  const [activeCity, setActiveCity] = useState("");
  const [activeCities, setActiveCities] = useState(null);
  const navigate = useNavigate();

  const { data: adminStats, isLoading: adminStatsLoading } =
    useAdminStatsQuery();
  console.log(activeCity);
  const {
    data: topUsers,
    isLoading: usersLoading,
    refetch: refetchTopUsers,
  } = useTopUsersRanksQuery();
  const { data: userStats, isLoading: statLoading } = useStatsQuery();
  const { data: usersStatsData, isLoading: usersStatsLoading } =
    useUsersStatsQuery(userstats);
  const { data: topBadgesData, isLoading: topBadgesLoading } =
    useTopEarnedBadgesQuery();
  useEffect(() => {
    if (adminStats) {
      setData(adminStats);
      setMostAvtiveUsers(adminStats.most_active_users);
      setActiveCities(adminStats.top_users_by_city);
    }
  }, [adminStats]);

  useEffect(() => {
    if (usersStatsData) {
      setUserStateData(
        userstats === "year"
          ? usersStatsData.user_stats_year
          : usersStatsData.user_stats_month
      );
    }
  }, [usersStatsData]);
  useEffect(() => {
    if (topBadgesData) {
      setTopEarnedBadges(topBadgesData.top_earned_badges);
    }
  }, [topBadgesData]);
  useEffect(() => localStorage.clear(), []);

  if (
    adminStatsLoading ||
    usersStatsLoading ||
    topBadgesLoading ||
    statLoading
  ) {
    return <Loading />;
  }

  return (
    <div className="w-full min-h-screen">
      <div className="flex flex-col 1lg:flex-row">
        <div className="w-full 1lg:w-[60%] h-full">
          <div className="w-full h-full py-5 px-6">
            <p className="font-bold text-base lg:text-2xl">
              Welcome Back,
              {userStats?.info?.first_name
                ? userStats?.info?.first_name
                : userStats?.info?.username}
              !
            </p>
            <p className="text-xs lg:text-tiny">
              Here is how your users are performing recently
            </p>
            <div className="flex flex-nowrap justify-end items-center pt-[40px] w-full">
              <div className="flex flex-nowrap justify-end items-center text-tiny text-main-text">
                <p className="text-xs lg:text-tiny mr-3">Data shown from:</p>
                <button
                  style={{ color: context.mainColor }}
                  className="flex flex-nowrap text-xs lg:text-tiny font-bold items-center justify-center outline-none border-none rounded-md bg-white shadow-dark py-1 px-2 md:py-2"
                >
                  May 20 ,2023
                  <Calendar className="w-[14px] h-[14px] lg:w-[20px] lg:h-[20px] ml-3" />
                </button>
              </div>
            </div>
            <div className="flex flex-wrap my-[32px] mx-[-15px]">
              {cardsEndpoints.map((el, index) => (
                <Card1
                  key={`card1-${index}`}
                  icon={cardIcon(cardsEndpoints[index].icon)}
                  endpoint={el.url}
                />
              ))}
              {/* <AddCardButton /> */}
            </div>
          </div>
          {/***************************Most Active Users************************* */}
          {mostAvtiveUsers?.length > 0 && (
            <div className="w-full h-full border-t-1 border-devider-color p-5 pl-6 pr-6">
              <div className="flex flex-nowrap justify-between items-center">
                <p className="font-bold text-tiny lg:text-base">
                  Most Active Users
                </p>
                <a
                  href="/home/users"
                  className="font-bold text-xs lg:text-tiny underline"
                  style={{ color: context.mainColor }}
                >
                  View All Users
                </a>
              </div>
              <div className="flex flex-wrap my-[32px] mx-[-15px]">
                {usersPerforming?.map((el, index) => (
                  <Card2
                    key={`card2-${index}`}
                    icon={el.icon}
                    name={el.first_name + " " + el.last_name}
                    points={el.total_points_earned}
                    badges={el.total_badges_earned}
                    rewards={el.total_rewards_earned}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="w-full h-full px-6 py-6 border-t-1 border-devider-color">
            <div className="flex flex-nowrap justify-between items-center">
              <p className="font-bold text-tiny lg:text-base">
                Most Active Users
              </p>

              <Link to={"/dashboard/users"}>
                <p
                  className="font-bold text-xs lg:text-tiny underline"
                  style={{ color: context.mainColor }}
                >
                  View All Users
                </p>
              </Link>
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-[19px] w-full mt-4 ">
              {topUsers?.top_users_ranks.length ? (
                topUsers.top_users_ranks
                  ?.slice(0, 6)
                  .map((user) => <UserCard user={user} key={user.id} />)
              ) : (
                <p
                  style={{ color: context.mainColor }}
                  className="text-center p-[10px] font-bold"
                >
                  No Users found
                </p>
              )}
            </div>
          </div>
          {/***************************Top Earned badges************************* */}
          {topEarnedBadges?.length > 0 ? (
            <div className="w-full h-full border-t-1 border-devider-color p-5 pl-6 pr-6">
              <div className="flex flex-nowrap justify-between items-center">
                <p className="font-bold text-tiny lg:text-base">
                  Top Earned Badges
                </p>

                <Link to={"/dashboard/badges"}>
                  <p
                    className="font-bold text-xs lg:text-tiny underline"
                    style={{ color: context.mainColor }}
                  >
                    View All Badges
                  </p>
                </Link>
              </div>
              <div className="flex flex-wrap my-[32px] mx-[-15px]">
                {topEarnedBadges?.slice(0, 3)?.map((el, index) => (
                  <Card3
                    key={`card3-${index}`}
                    icon={el.icon || silverBadge}
                    title={el.name}
                    earnedBy={el.earned_by}
                    lastEarned={"Last Earned: " + el.last_earned}
                    bgColor={colors[index]?.bgColor}
                    color={colors[index]?.txtColor}
                  />
                ))}
              </div>
            </div>
          ) : (
            <p
              style={{ color: context.mainColor }}
              className="text-center p-[10px] font-bold"
            >
              No Badges found
            </p>
          )}
        </div>
        <div className="w-full 1lg:w-[40%] flex flex-col md:border-devider-color md:border-l-1">
          <div className="w-full p-5 pl-6 pr-6">
            <p className="font-bold text-lg lg:text-2xl">General Statistics</p>
            <div className="flex flex-nowrap justify-between mt-[30px] mb-[15px] items-center">
              <p className="font-bold text-tiny lg:text-base">
                Total New Registered Users
              </p>
              <div className="shadow-dark rounded-lg">
                <Select
                  className="text-xs"
                  defaultValue={{ value: "months", label: "Months" }}
                  isSearchable={false}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: 0,
                      boxShadow: "none",
                    }),
                  }}
                  onChange={(e) => {
                    setNewUsersDateType(e.value);
                  }}
                  options={options}
                />
              </div>
            </div>
            <BarChart
              monthsData={data?.new_user_by_month}
              yearsData={data?.new_user_by_year}
              datatype={newUsersDateType}
            />
          </div>
          <div className="w-full border-t-1 border-devider-color p-5 pl-6 pr-6">
            <div className="flex flex-nowrap justify-between mt-[30px] mb-[15px] items-center">
              <p className="font-bold text-tiny lg:text-base">
                Users Statistics
              </p>
              <div className="shadow-dark rounded-lg">
                <Select
                  className="text-xs"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: 0,
                      boxShadow: "none",
                    }),
                  }}
                  defaultValue={{ value: "month", label: "Month" }}
                  onChange={(e) => {
                    setUserStats(e.value);
                  }}
                  options={options2}
                />
              </div>
            </div>

            {!usersStatsLoading && userStateData ? (
              <HorizontalBarChart
                currentData={userStateData}
                datatype={userstats}
              />
            ) : (
              <p
                style={{ color: context.mainColor }}
                className="text-center p-[10px] font-bold"
              >
                No Users found
              </p>
            )}
          </div>

          <div className="w-full border-t-1 border-devider-color p-5 pl-6 pr-6">
            <p className="font-bold text-tiny lg:text-base">Active Cities</p>
            {activeCities?.length > 0 ? (
              <div className="flex flex-wrap  mx-[-15px]">
                {activeCities
                  ?.filter(
                    (ii) =>
                      ii.city_name === "jeddah" ||
                      ii.city_name === "dammam" ||
                      ii.city_name === "riyadh"
                  )
                  .reverse()
                  .map((item, index) => (
                    <Card4
                      bgImage={citiesFrams[index]?.frame}
                      title={
                        item?.city_name?.slice(0, 1).toUpperCase() +
                        item?.city_name?.slice(1, item?.city_name?.length)
                      }
                      subtitle={item?.count + 1 + " Active Users"}
                      shadowColor={citiesFrams[index]?.shadow}
                      onClick={() => {
                        navigate("/dashboard/users", {
                          state: {
                            city: item?.city_name,
                          },
                        });
                      }}
                    />
                  ))}
              </div>
            ) : (
              <p
                style={{ color: context.mainColor }}
                className="text-center p-[10px] font-bold"
              >
                No Cities found
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
