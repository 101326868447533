import React from "react";
import { classNames } from "./Utils";
import { Oval } from "react-loader-spinner";
export function Button({ children, className, isLoading, ...rest }) {
  return (
    <button
      type="button"
      isLoading={isLoading}
      className={classNames(
        "relative inline-flex items-center px-4 py-2 border-none outline-none rounded-md",
        className
      )}
      {...rest}
    >
      {isLoading ? (
        <Oval height="16" width="16" color="grey" ariaLabel="loading" />
      ) : (
        children
      )}
    </button>
  );
}

export function PageButton({ children, className, ...rest }) {
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center px-2 py-2 bg-btn-gray border-none outline-none rounded-sm",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}
