import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJs } from "chart.js/auto";

import { useAppContext } from "../../Context/AppContext";
function HorizontalBarChart({ datatype, currentData }) {
  const [maxindex, setMaxindex] = useState(null);
  const [minindex, setMinindex] = useState(null);

  const context = useAppContext();

  const options = {
    indexAxis: "y",

    scales: {
      y: {
        ticks: {
          font: {
            weight: "bold",
          },
        },
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: function (ctx) {
          // use the same color as the border

          return ctx.dataIndex === maxindex ? "white" : "#0f2837";
        },
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
        formatter: function (value, context) {
          return context.chart.data.datasets[0].data[context.dataIndex] === 0
            ? ""
            : context.chart.data.datasets[0].data[context.dataIndex];
        },
        anchor: "end",
        align: function (ctx) {
          // use the same color as the border
          return ctx.dataIndex === minindex ? "right" : "left";
        },
      },
      title: {
        display: false,
      },
    },
  };

  const [userData, setUserData] = useState({
    labels: currentData?.map((data) => data.label),
    datasets: [
      {
        display: false,
        data: currentData?.map((data) => data.value),
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: currentData?.map((data) => context.secondaryColor),
      },
    ],
  });

  function changeHeighest() {
    const cpyy = {
      labels: currentData.map((data) => data.label),
      datasets: [
        {
          display: false,
          data: currentData.map((data) => data.value),
          borderRadius: 5,
          borderSkipped: false,
          backgroundColor: currentData.map((data) => context.secondaryColor),
        },
      ],
    };
    const max = Math.max(...cpyy.datasets[0].data);
    const indexofmax = cpyy.datasets[0].data.indexOf(max);
    const min = Math.min(...cpyy.datasets[0].data);
    const indexofmin = cpyy.datasets[0].data.indexOf(min);

    setMaxindex(indexofmax);
    setMinindex(indexofmin);

    cpyy.datasets[0].backgroundColor[indexofmax] = context.mainColor;
    cpyy.datasets[0].backgroundColor[indexofmin] = "#0f2837";
    setUserData(cpyy);
  }
  useEffect(() => {
    changeHeighest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.mainColor, currentData]);

  return (
    <div style={{ height: "100%" }}>
      <Bar plugins={[ChartDataLabels]} data={userData} options={options} />
    </div>
  );
}

export default HorizontalBarChart;
