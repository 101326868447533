import React from "react";

function Card3({ icon, title, earnedBy, lastEarned, bgColor, color }) {
  return (
    <div className="relative h-[107px] flex-initial w-[100%] sm:w-[50%] 1sm:w-[33.3333%] md:w-[50%] 1lg:w-[33.3333%] px-[7px] mt-[15px]">
      <div
        style={{
          boxShadow: `0 4px 40px 0px rgb(15,40,55,0.1)`,
        }}
        className={`${bgColor} relative flex items-center w-full h-full bg-white rounded-[8px]`}
      >
        <div className="flex flex-nowrap items-center py-[12px] px-[6px] xl:py-[24px] xl:px-[15px]">
          <div className="rounded-[5px] w-[50px] h-[50px]  overflow-hidden">
            <img className="w-full h-full object-contain" src={icon} alt="top badge" />
          </div>
          <div className="flex flex-col ml-[8px] text-main-text">
            <p className="font-bold text-tiny xl:text-base">{title}</p>
            <div>
              <p className="text-xs xl:text-tiny">
                Earned by: <span className="font-bold">{earnedBy} Users</span>
              </p>
              <p className={`text-xs xl:text-tiny ${color}`}>{lastEarned}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card3;
