import { useState } from "react";
import silverBadge from "../assets/images/silver-badge.png";

function ToolTip2({ el }) {
  const [isVisible, setVisible] = useState(false);
  const menuStyle = `
  text-base
  z-[100]
  min-w-[180px]
  min-h-[71px]
  absolute
  bottom-[calc(100%+10px)]
  left-[0px]
  py-[5px]
  px-[10px]
  pb-[20px]
  bg-[#FFCA8C]
  rounded-[3px]
  duration-500
  shadow-dark
  before:bg-[#FFCA8C]
  before:absolute
  content-['']
  before:w-[20px]
  before:z-[-5]
  before:h-[20px]
  before:bottom-[-5px]
  before:left-[10px]
  before:rotate-45`;

  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      className="relative"
    >
      <img
        src={el.icon || silverBadge}
        alt="badge"
        className="h-[50px] w-[42px]"
      />

      <div className={menuStyle} style={{ display: !isVisible ? "none" : "" }}>
        <p className="text-main-text text-[12px] font-bold">{el.reward_name}</p>
        <p className="text-main-text text-[10px]">
          Engage in {el.activity__name || "more activities"} to earn this badge
        </p>
        <p className="text-main-text mt-[4px] text-[8px]">
          Signup in{" "}
          <a
            href={el.signup_link}
            target="_blanc"
            className="font-bold underline"
          >
            {el.reward_provider}
          </a>{" "}
          and earn {el.points_earned_by_signup} points
        </p>
      </div>
    </div>
  );
}

<div></div>;

export default ToolTip2;
