import { Navigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import jwt_decode from "jwt-decode";
export const DeniedToAuthUsers = ({ children }) => {
  const auth = useAuth();
  if (auth.user && ["ADMIN", "USER"].includes(jwt_decode(auth.user).role)) {
    return <Navigate to="/dashboard/home" />;
  }
  return children;
};
