import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import { BASE_HEADERS, BASE_URL } from "../../configs/DataService";
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: BASE_HEADERS,
    validateStatus(response, body) {
      if (response.status >= 300 || response.status < 200) {
        toast.error(body.message, {
          toastId: "SAfdsdahjgj5465",
          autoClose: 1500,
        });
        return false;
      } else return true;
    },
  }),
  tagTypes: ["User"],
  endpoints: ({ query, mutation }) => ({
    profile: query({
      query: () => ({
        url: "/verse/user/",
      }),
      providesTags: ["User"],
    }),
  }),
});

export const { useProfileQuery } = authApi;
