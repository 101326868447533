import React from "react";
import { Link } from "react-router-dom";

const SuccessDialog = () => {
  return (
    <div className="w-[90%] text-center bg-white py-12 px-11 flex flex-col justify-center gap-4 items-center shadow-formShaddow rounded-lg">
      <div>
        <img src="/images/Group-like.svg" alt="" />
      </div>
      <h2 className="font-bold text-2xl">
        Your Message has been
        <br />
        sent successfully
      </h2>
      <p>
        Our team will get back to you as soon as possible,
        <br /> follow the email attached to the application!
      </p>
      <Link
        to="/home"
        className="px-8  py-4 bg-gradient-to-r from-[#5E45FF] to-[#0028B3] text-white rounded-md"
      >
        Back to Dashboard
      </Link>
    </div>
  );
};

export default SuccessDialog;
