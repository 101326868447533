import { Dialog } from "@headlessui/react";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import excel from "../../assets/images/excel.png";
import { ReactComponent as Upload } from "../../assets/svg/upload.svg";
import { useAppContext } from "../../Context/AppContext";
import { useImportUsersMutation } from "../../store/admin/usersApi";
import { useImportUsersWithActivitiyMutation } from "../../store/admin/adminApi";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import congratsImage from "../../assets/images/congrats.png";

function ImportUsers({ setShowModal, activity }) {
  const context = useAppContext();
  const [importUsers, { isError, isSuccess, isLoading, error }] =
    useImportUsersMutation();
  const [
    importUsersWithActivity,
    {
      isError: isErrorAct,
      isSuccess: isSuccessAct,
      isLoading: isLoadingAct,
      error: errorAct,
    },
  ] = useImportUsersWithActivitiyMutation();
  const navigate = useNavigate();
  const hiddeninput = useRef(null);

  const [csvFile, setCsvFile] = useState();

  const formData = new FormData();

  if (csvFile) {
    formData.append("file", csvFile);
  }

  const handleChange = (file) => {
    if (file) setCsvFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (csvFile) {
      activity ? importUsersWithActivity(formData) : importUsers(formData);
    } else return toast.error("please upload file");
  };

  useEffect(() => {
    if (isSuccess || isSuccessAct) {
      setShowModal(false);

      context.setAndShowModalData({
        title: "Users Added Successfully!!",
        subtitle: ``,
        description: "",
        image: congratsImage,
      });
      navigate("/dashboard/users", { state: "success" });
    }
    if (isError || isErrorAct) {
      toast.error(
        (error?.data && error?.data?.msg) ||
          (errorAct?.data && errorAct?.data?.msg) ||
          "something went wrong!"
      );
    }
  }, [isSuccess, isError || isErrorAct, isSuccessAct]);
  return (
    <form onSubmit={handleSubmit}>
      <Dialog.Title
        as="h3"
        className="text-2xl font-bold text-[#0f2837] mb-6 border-b-1 pb-4 border-[#DEE5ED]"
      >
        Importing New Users
      </Dialog.Title>
      <p className="text-[16px] font-bold text-[#0f2837] mb-[10px]">
        Import a List of Users
      </p>
      <p className="text-[14px]  text-[#87949B] ">
        Here you can bulk upload a list of users in one go
      </p>
      <div className="w-full h-[1px] mb-9 border-devider-color border-b-1 my-[29px]"></div>
      <p className="text-[14px] font-bold text-[#0f2837] mb-[10px]">
        File Upload
      </p>
      <p className="text-[12px]  text-[#87949B] mb-[10px] ">
        Attache a CSV File to import list of users into the rewarding system
      </p>
      <p className="text-[10px]  text-[#F5AD9E] mb-[10px]">
        Download csv. file format below
      </p>
      <div className="flex flex-row items-center mb-[30px]"></div>

      {!csvFile?.name ? (
        <span
          className="flex flex-col items-center  hover:cursor-pointer"
          onClick={() => {
            hiddeninput.current.click();
          }}
        >
          <div
            style={{ boxShadow: "0px 4px 40px 0px rgba(15, 40, 55, 0.1)" }}
            className="w-full flex-col mb-[180px] flex h-[105px] rounded-[8px] items-center justify-center "
          >
            <FileUploader
              ref={hiddeninput}
              name="file"
              types={["csv"]}
              handleChange={handleChange}
            >
              <Upload
                className="h-[26px] w-[26px] hover:cursor-pointer mx-auto"
                style={{ color: context.mainColor }}
              />
              <p className="text-[10px] text-[#9FA9AF]">
                Drag and drop the list of users using a csv. file
              </p>
            </FileUploader>
          </div>
        </span>
      ) : (
        <FileUploader
          ref={hiddeninput}
          name="file"
          types={["csv"]}
          handleChange={handleChange}
        >
          <div className="flex justify-start items-center cursor-pointer">
            <img
              alt="excel"
              src={excel}
              className="mr-[10px] h-[21px] w-[21px]"
            />
            <p className="text-[#9FA9AF] text-[10px]">{csvFile?.name}</p>
          </div>
        </FileUploader>
      )}

      <div className="w-full h-[1px] mb-9 border-devider-color border-b-1 my-[30px]"></div>
      <div className="flex items-center justify-end">
        <button
          onClick={() => setShowModal(false)}
          style={{
            border: `2px solid ${context.mainColor}`,
            color: context.mainColor,
          }}
          className="flex flex-row flex-nowrap justify-center items-center whitespace-nowrap min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center text-[12px] outline-none focus:outline-none ease-linear transition-all duration-150"
          type="button"
        >
          Cancel
        </button>
        <button
          style={{
            color: "#fff",
            border: `2px solid ${context.mainColor}`,
            backgroundColor: context.mainColor,
          }}
          className="flex text-[12px] whitespace-nowrap ml-[15px] flex-row flex-nowrap justify-center items-center min-w-[100px] rounded-[4px] p-2 bg-transparent font-bold text-center outline-none focus:outline-none ease-linear transition-all duration-150"
          type="submit"
        >
          Upload Users
        </button>
      </div>
    </form>
  );
}

export default ImportUsers;
