import Select from "react-select";
import { PageButton } from "./shared/Button";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";
import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { useEffect, useState } from "react";
function PaginationRow({ count, setOffset, limit, setLimit }) {
  const [currentPage, setCurrentPage] = useState(0);

  const maxPages = Math.ceil(count / limit);
  const cantGoNext = currentPage === maxPages - 1;
  const cantgoPrev = currentPage === 0;
  if (currentPage > maxPages) {
    setCurrentPage(maxPages);
  }
  console.log(maxPages);
  console.log(count);
  console.log(limit);
  useEffect(
    () => setOffset(currentPage * limit),
    [currentPage, limit, setOffset]
  );
  return (
    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between mt-[55px]">
      <div className="flex gap-x-2 items-baseline">
        <div className="shadow-dark rounded-lg">
          <Select
            className="text-xs"
            styles={{
              control: (base) => ({
                ...base,
                border: 0,
                boxShadow: "none",
              }),
            }}
            isSearchable={false}
            defaultValue={{ value: 8, label: "Show 8 results" }}
            onChange={(e) => {
              setLimit(e.value);
            }}
            options={[
              { value: 8, label: "Show 8 results" },
              { value: 12, label: "Show 12 results" },
              { value: 16, label: "Show 16 results" },
            ]}
          />
        </div>
      </div>
      <div>
        <span className="text-sm text-main-text">
          <span className="font-medium">{currentPage}</span> of
          <span className="font-medium"> {maxPages - 1}</span>
        </span>
        <nav
          className="relative ml-2 z-0 inline-flex rounded-md space-x-1"
          aria-label="Pagination"
        >
          <PageButton
            className={cantgoPrev ? "opacity-50" : "opacity-100"}
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={cantgoPrev}
          >
            <span className="sr-only">Previous</span>
            <ArrowLeft className="h-4 w-3" />
          </PageButton>
          <PageButton
            className={cantGoNext ? "opacity-50" : "opacity-100"}
            disabled={cantGoNext}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <span className="sr-only">Next</span>
            <ArrowRight className="h-4 w-3" />
          </PageButton>
        </nav>
      </div>
    </div>
  );
}
export default PaginationRow;
