import React from "react";
import placeholderImg from "../../../assets/images/mobile.png";

function RewardCard({ reward, setShowModal, setSelectedReward }) {
  console.log();
  const { icon, name, status, points } = reward;
  return (
    <div className="flex w-full flex-col rounded-[5px] max-w-[200px] min-h-[254px] font-Effra">
      <div className="w-full h-[150px] rounded-[5px] object-cover">
        <img
          src={icon || placeholderImg}
          alt="reward"
          className="w-full h-full rounded-[5px] object-cover"
        />
      </div>
      <div className="flex w-full justify-between items-center mt-2 mb-1">
        <p className="text-base font-normal text-main-text truncate">{name}</p>
        <p
          className={`text-xs font-bold ${
            status === "active" ? "text-green" : " text-[#EB5757]"
          } capitalize`}
        >
          {status}
        </p>
      </div>
      <p className="text-primary-one text-base font-bold">{`${points}pts`}</p>
      <button
        type="button"
        onClick={() => {
          setSelectedReward(reward);
          setShowModal(true);
        }}
        className="hover:scale-110 hover:duration-200 bg-gradient-primary w-full lg:py-3 py-1 font-bold text-xs text-white mt-4 rounded"
      >
        Redeem Points
      </button>
    </div>
  );
}

export default RewardCard;
