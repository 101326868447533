import React from "react";

function Hero() {
  return (
    <div className="flex relative p-5 w-full bg-[#E9E7FA] shadow-2xl reward-hero md:min-h-[405px] sm:min-h-[300px] min-h-[285px] font-Effra md:px-20  justify-between items-center  rounded">
      <div className=" flex flex-col items-start gap-3">
        <h2 className="font-extrabold text-4xl">
          Earn More Points To Unlock{" "}
          <p className="text-[#5E45FF] -mt-3">More Valuable Rewards</p>
        </h2>
        <p>
          Participate in more events and share your experience with your family{" "}
          <br />
          and friends on social media to earn more points..
        </p>
        <button className="px-4 py-2 bg-gradient-to-r from-[#5E45FF] to-[#0028B3] text-white rounded-md">
          Discover More
        </button>
      </div>
      <div className="absolute hidden lg:block h-full right-[12%]">
        <img className="w-full h-full" src="/images/rewardHero.svg" alt="" />
      </div>
    </div>
  );
}

export default Hero;
