import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_HEADERS, BASE_URL } from "../../configs/DataService";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/gs/api/admin`,
    prepareHeaders: BASE_HEADERS,
  }),
  tagTypes: [
    "Stats",
    "Badges",
    "Rewards",
    "Events",
    "Ranks",
    "Teirs",
    "Platforms",
    "profiles",
    "Activities",
  ],
  endpoints: ({ query, mutation }) => ({
    adminStats: query({
      query: () => ({
        url: "stats",
      }),
      providesTags: ["Stats"],
    }),
    adminTeirs: query({
      query: (params) => ({
        url: "tiers",
        params: params,
      }),
      providesTags: ["Teirs"],
    }),
    addAdminTeir: mutation({
      query: (body) => ({
        url: "tiers",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Teirs"],
    }),
    updateAdminTeir: mutation({
      query: ({ id, ...body }) => ({
        url: `tiers/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Teirs"],
    }),
    deleteAdminTeir: mutation({
      query: ({ id }) => ({
        url: `tiers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Teirs"],
    }),
    adminRewards: query({
      query: (params) => ({
        url: "rewards",
        params,
      }),
      providesTags: ["Rewards"],
    }),

    addRewards: mutation({
      query: (body) => ({
        url: "rewards",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Rewards"],
    }),
    updateAdminRewards: mutation({
      query: ({ id, ...body }) => ({
        url: `rewards/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Rewards"],
    }),

    deleteAdminReward: mutation({
      query: ({ id }) => ({
        url: `rewards/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Rewards"],
    }),
    platforms: query({
      query: (params) => ({
        url: "platforms",
        params: params,
      }),
      providesTags: ["Platforms"],
    }),
    platformsInfo: query({
      query: (body) => ({
        url: "platform_info",
        body,
      }),
      providesTags: ["Platforms"],
    }),
    addPlatform: mutation({
      query: (body) => ({
        url: "platforms",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Platforms"],
    }),
    updatePlatform: mutation({
      query: ({ id, ...body }) => ({
        url: `platforms/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Platforms"],
    }),

    createProfile: mutation({
      query: (body) => ({
        url: "profiles/create_profile",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Profiles"],
    }),

    createActivity: mutation({
      query: (body) => ({
        url: "activities",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Activities"],
    }),

    updateActivity: mutation({
      query: ({ id, ...body }) => ({
        url: `activities/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Activities"],
    }),
    activities: query({
      query: (params) => ({
        url: "activities",
        params: params,
      }),
      providesTags: ["Activities"],
    }),
    deleteActivity: mutation({
      query: ({ id }) => ({
        url: `activities/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Activities"],
    }),
    adminBadges: query({
      query: (params) => ({
        url: "badges",
        params: params,
      }),
      providesTags: ["badges"],
    }),
    addAdminBadge: mutation({
      query: (body) => ({
        url: "badges",
        method: "POST",
        body,
      }),
      invalidatesTags: ["badges"],
    }),
    updateAdminBadge: mutation({
      query: ({ id, ...body }) => ({
        url: `badges/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["badges"],
    }),
    deleteAdminBadge: mutation({
      query: ({ id }) => ({
        url: `badges/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["badges"],
    }),
    userActivitiesById: query({
      query: ({ id }) => ({
        url: `all_user_activities/${id}`,
      }),
      providesTags: ["Stats"],
    }),
    userBadgesById: query({
      query: ({ id }) => ({
        url: `badges_to_be_earned/${id}`,
      }),
      providesTags: ["Stats"],
    }),
    userStatsById: query({
      query: ({ id }) => ({
        url: `user_stats/${id}`,
      }),
      providesTags: ["Stats"],
    }),
    importUsersWithActivitiy: mutation({
      query: (body) => ({
        url: `https://core.verse-stg.tam.run/gs/api/add_points/bulk_add_activity`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Activities"],
    }),
    presignUrl: query({
      query: () => ({
        url: "https://core.verse-stg.tam.run/gs/api/presigned_url",
      }),
      providesTags: ["Stats"],
    }),
  }),
});

export const {
  useAdminStatsQuery,
  useAdminTeirsQuery,
  usePresignUrlQuery,
  useAddAdminTeirMutation,
  useUpdateAdminTeirMutation,
  useDeleteAdminTeirMutation,
  useAddPlatformMutation,
  usePlatformsQuery,
  usePlatformsInfoQuery,
  useUpdatePlatformMutation,
  useCreateProfileMutation,
  useActivitiesQuery,
  useCreateActivityMutation,
  useUpdateActivityMutation,
  useDeleteActivityMutation,
  useAdminBadgesQuery,
  useUserActivitiesByIdQuery,
  useUserBadgesByIdQuery,
  useUserStatsByIdQuery,
  useAddAdminBadgeMutation,
  useUpdateAdminBadgeMutation,
  useDeleteAdminBadgeMutation,
  useAddRewardsMutation,
  useUpdateAdminRewardsMutation,
  useAdminRewardsQuery,
  useDeleteAdminRewardMutation,
  useImportUsersWithActivitiyMutation,
} = adminApi;
