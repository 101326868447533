import { useState } from "react";

function ToolTip1({ el, item, left }) {
  const [isVisible, setVisible] = useState(false);
  const menuStyle = `
  text-base
  z-[100]
  min-w-[180px]
  h-[71px]
  absolute
  bottom-[calc(100%+10px)]
  left-[0px]
  py-[10px]
  justify-center
  flex
  flex-col
  px-[10px]
  pb-[20px]
  bg-[#0f2837]
  rounded-[3px]
  duration-500
  shadow-dark
  before:bg-[#0f2837]
  before:absolute
  content-['']
  before:w-[20px]
  before:z-[-5]
  before:h-[20px]
  before:bottom-[-5px]
  before:left-[10px]
  before:rotate-45`;

  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      className="relative"
    >
      {item}
      <div
        className={menuStyle}
        style={{ display: !isVisible ? "none" : "", left: left }}
      >
        <p className="text-white text-[12px] font-bold">{el.name}</p>
        {el.date && (
          <p className="text-white mt-[3px] text-[8px]">
            You earned this badge on: {el.date}
          </p>
        )}
      </div>
    </div>
  );
}

export default ToolTip1;
