import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../Context/AppContext";
import { classNames } from "../../../components/shared/Utils";
import { SortDownIcon } from "../../../components/shared/Icons";
import Select, { components } from "react-select";
import { ReactComponent as Add } from "../../../assets/svg/add.svg";
import { ReactComponent as ListViewIcon } from "../../../assets/svg/listview.svg";
import { ReactComponent as FilterIcon } from "../../../assets/svg/filter.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { Loading } from "../../../components/Loading";
import Table from "../../../components/table/Table";
import { SelectColumnFilter } from "../../../components/table/SelectColumnFilter";
import PaginationRow from "../../../components/PaginationRow";
import { ReactComponent as GridViewIcon } from "../../../assets/svg/gridview.svg";
import { AvatarCell } from "../../../components/table/AvatarCell";
import { StatusPill } from "../../../components/table/StatusPill";
import FormModal from "../../../components/FormModal";
import BadgeCard from "../../../components/badges/BadgeCard";
import { useAdminTeirsQuery } from "../../../store/admin/adminApi";
import AddFormTier from "../../../components/forms/addForms/AddFormTier";
import { usePlatformsQuery } from "../../../store/admin/adminApi";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SortDownIcon className="text-[18px] text-main-text mb-[6px]" />
    </components.DropdownIndicator>
  );
};
function TiersGrid() {
  useEffect(() => localStorage.clear(), []);

  const context = useAppContext();
  // eslint-disable-next-line no-unused-vars
  const [itemToedit, setItemToedit] = useState("");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState({ value: "", label: "All" });
  const [filteredData, setFilteredData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const { data: adminTier, isLoading } = useAdminTeirsQuery({
    limit: limit,
    offset: offset,
  });
  const { data: platforms } = usePlatformsQuery();

  const statusOptions = [
    { value: "", label: "All" },
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const [showModalAdd, setShowModalAdd] = useState(false);

  useEffect(() => {
    context.keyword?.length > 0
      ? setFilteredData(
          adminTier?.results?.filter((ii) =>
            ii.name.toLowerCase().includes(context.keyword.toLowerCase())
          )
        )
      : setFilteredData(adminTier?.results);
  }, [adminTier?.results, context?.keyword]);
  useEffect(() => {
    context.badgesView === "grid" && setLimit(8);
  }, [context.badgesView]);
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableSortBy: true,
      },
      {
        Header: "Tier Name",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Symbol",
        accessor: "symbol",
        Cell: AvatarCell,
        imgAccessor: "icon",
        disableSortBy: true,
      },
      {
        Header: "Points",
        accessor: "points",
      },
      {
        Header: "Platform",
        accessor: "platform",
        Cell: ({ cell }) =>
          platforms?.results
            .filter((ii) => ii.id === cell.value)
            .map((it) => it.name),
      },
      {
        Header: "Earned By",
        accessor: "earned_by",
      },
      {
        Header: "Date Created",
        accessor: "created_at",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: StatusPill,
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
    ],
    [platforms?.results]
  );

  return (
    <div className="w-full min-h-screen p-6 2lg:p-14">
      <p className="text-xs mb-2">
        <Link to={"/dashboard/tiers"}>Tiers</Link> &gt;
      </p>
      <div className="mb-10 flex flex-row justify-between items-center">
        <p className="font-bold text-tiny lg:text-2xl">All Tiers</p>

        <div className="flex flex-row flex-nowrap justify-center items-center">
          <div className="text-main-text/50 flex flex-row flex-nowrap justify-center items-center">
            <span className="text-main-text text-tiny">
              {context.badgesView === "grid" ? " List View" : "Grid View"}
            </span>
            <div
              onClick={() =>
                context.badgesView === "list"
                  ? context.setBadgesView("grid")
                  : context.setBadgesView("list")
              }
              className="w-[36px] h-[28px] ml-1"
            >
              {context.badgesView === "list" ? (
                <GridViewIcon className="w-full h-full cursor-pointer" />
              ) : (
                <ListViewIcon className="w-full h-full cursor-pointer" />
              )}{" "}
            </div>
          </div>
          <button
            onClick={() => {
              setShowModalAdd(true);
              setItemToedit(null);
            }}
            style={{
              border: `2px solid ${context.mainColor}`,
              backgroundColor: context.mainColor,
            }}
            className="flex h-[44px] p-[7px] flex-row flex-nowrap justify-center items-center rounded-[4px] ml-5 text-white font-bold text-center text-xxs 2lg:text-xs lg:text-tiny outline-none"
          >
            <Add className="h-[20px] w-[20px] mr-[10px]" />
            <span className="text-[12px]">Create New Tier</span>
          </button>
        </div>

        <FormModal showModal={showModalAdd} setShowModal={setShowModalAdd}>
          <AddFormTier
            setShowModalAdd={setShowModalAdd}
            tierData={itemToedit}
          />
        </FormModal>
      </div>
      {context.badgesView !== "list" && (
        <div className="flex flex-row justify-start items-start -mt-8 mb-4">
          <div className="flex flex-row items-center gap-2">
            {/* <span className="text-main-text text-base font-bold"></span> */}
            <div
              onClick={() => setIsFiltersOpen(!isFiltersOpen)}
              style={{
                backgroundColor: isFiltersOpen
                  ? "rgb(15,40,55)"
                  : "rgb(236,239,239)",
                color: isFiltersOpen ? "#fff" : "",
              }}
              className="cursor-pointer min-w-[40px] min-h-[40px] w-[40px] h-[40px] px-2 py-1 rounded-[4px]"
            >
              <FilterIcon className="w-full h-full" />
            </div>
          </div>
          <div
            className={classNames(
              "flex-row flex-wrap items-center gap-4 px-4 py-3 rounded-md bg-white shadow-table-head-dark -mt-6 ml-2",
              isFiltersOpen ? "flex" : "hidden"
            )}
          >
            <span className="text-main-text text-base font-bold">Filters:</span>
            <div className="flex flex-row flex-wrap items-center gap-2">
              <label className="flex flex-row flex-wrap items-center">
                <span className="text-gray-700 mr-2">status: </span>
                <div className="shadow-table-head-dark rounded-lg w-fit">
                  <Select
                    className="text-center text-xs font-bold"
                    value={
                      statusFilter.value
                        ? {
                            value: statusFilter.value,
                            label: statusFilter.label,
                          }
                        : {
                            value: "",
                            label: "All",
                          }
                    }
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setStatusFilter({ value: e.value, label: e.label });
                    }}
                    isSearchable={false}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: 0,
                        padding: "0px 4px",
                        boxShadow: "none",
                      }),
                    }}
                    options={statusOptions}
                  />
                </div>
              </label>
            </div>
            <div
              onClick={() => setStatusFilter({ value: "", label: "All" })}
              className="flex flew-row flex-nowrap justify-center items-center gap-1 px-2 py-1 text-white text-xs font-bold bg-primary-four hover:bg-primary-four/70 rounded-full cursor-pointer"
            >
              Clear <Close className="w-[12px] h-[12px]" />
            </div>
          </div>
        </div>
      )}
      {context.badgesView === "list" ? (
        <>
          {isLoading ? (
            <Loading />
          ) : filteredData?.length > 0 ? (
            <Table
              onClick={(obj) => {
                setItemToedit(obj);
                setShowModalAdd(true);
              }}
              setLimit={setLimit}
              limit={limit}
              setOffset={setOffset}
              count={adminTier?.count}
              columns={columns}
              data={[...filteredData].sort((a, b) => {
                return new Date(b.date_created) - new Date(a.date_created);
              })}
              initialState={{
                sortBy: [
                  {
                    id: "date_created",
                    desc: true,
                  },
                ],
              }}
            />
          ) : (
            <p>No Tiers Found</p>
          )}
        </>
      ) : (
        <div className="flex flex-wrap gap-8 xl:gap-12 pt-2">
          {isLoading ? (
            <Loading />
          ) : filteredData?.length > 0 ? (
            filteredData
              .filter(
                (i) =>
                  i.status === statusFilter.value.toLowerCase() ||
                  !statusFilter.value
              )
              .map((el, index) => (
                <BadgeCard
                  key={index}
                  setItemToedit={setItemToedit}
                  setShowModal={setShowModalAdd}
                  element={el}
                  page="tier"
                />
              ))
          ) : (
            <p>No Tiers Found</p>
          )}
        </div>
      )}
      {/****** pagination ******* */}
      {context.badgesView !== "list" && (
        <PaginationRow
          count={adminTier?.count}
          setLimit={setLimit}
          limit={limit}
          setOffset={setOffset}
        />
      )}
    </div>
  );
}

export default TiersGrid;
