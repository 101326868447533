import { combineReducers } from "@reduxjs/toolkit";
import { adminApi } from "./admin/adminApi";
import { userApi } from "./user/userApi";
import { adminDashboardApi } from "./admin/adminDashboardApi";
import { usersApi } from "./admin/usersApi";

import app from "./app";

const rootReducer = combineReducers({
  app,
  [userApi.reducerPath]: userApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [adminDashboardApi.reducerPath]: adminDashboardApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
});

export default rootReducer;
