/*  eslint-disable  camelcase */
export const initialState = {
  vars: {},
  fetchVars: {},
};

export function reducer(state = initialState, action) {
  const filter = ({ searchFilter, sortBy, filterBy, vars }) => {
    let formatVars = vars;
    if (searchFilter?.length > 0) {
      formatVars = {
        ...formatVars,
        name: searchFilter,
      };
    }
    if (sortBy?.length) {
      const sorting = {
        ordering: sortBy[0].desc ? "-" + sortBy[0]?.id : sortBy[0]?.id,
      };

      formatVars = {
        ...formatVars,
        ...sorting,
      };
    }

    if (filterBy?.length) {
      const object = Object.assign({}, ...filterBy);
      const filtering = { [object.id]: object.value };
      formatVars = {
        ...formatVars,
        ...filtering,
      };
    }

    return formatVars;
  };

  switch (action.type) {
    case "search": {
      const { sortBy, filterBy } = state;
      const vars = filter({
        sortBy,
        filterBy,
        searchFilter: action.payload.value,
        vars: state?.fetchVars,
      });
      return {
        ...state,
        vars,
        searchFilter: action.payload.value,
      };
    }

    case "sortBy": {
      const { searchFilter, filterBy } = state;
      const vars = filter({
        searchFilter,
        filterBy,
        sortBy: action.payload.value,
        vars: state?.fetchVars,
      });

      return {
        ...state,
        vars,
        sortBy: action.payload.value,
      };
    }

    case "filterBy": {
      const { searchFilter, sortBy } = state;
      const vars = filter({
        filterBy: action.payload.value,
        searchFilter,
        sortBy,
        vars: state?.fetchVars,
      });
      return {
        ...state,
        vars,
        filterBy: action.payload.value,
      };
    }

    default:
      return {
        state,
      };
  }
}
