import { createSlice } from "@reduxjs/toolkit";

const overlaySlice = createSlice({
  name: "overlay",
  initialState: {
    state: {
      isActive: false,
      top: 0,
      left: 0,
    },
  },
  reducers: {
    changeOverlayState: (state, { payload }) => {
      state.state = payload;
    },
  },
});

export const selectAppOverlayState = ({ app }) => app.overlay.state;
export const { changeOverlayState } = overlaySlice.actions;

export default overlaySlice.reducer;
