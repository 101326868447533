import React from "react";

export const Loading = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center bg-white">
      <img
        src={`/images/verse_loader.gif`}
        alt="loading"
        className="md:w-[206px] w-32 md:h-[206px] h-32 object-contain"
      />
    </div>
  );
};
