import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { forwardRef } from "react";

const Input = forwardRef(
  (
    {
      id,
      className,
      required,
      label,
      type,
      placeholder,
      register,
      labelClass,
      ...props
    },
    ref
  ) => {
    const mainRegister = register ? register : () => [];

    return (
      <div className="relative flex flex-col items-start gap-2">
        <label htmlFor={id} className={"font-medium " + labelClass}>
          <span className={clsx(required ? "" : "hidden", "text-red-500 ")}>
            *
          </span>
          {label}
        </label>
        {type === "textArea" ? (
          <textarea
            className={`group placeholder:font-normal w-full py-3 px-3 rounded-sm border-[#D2D2D2] border-[1px] ${className}`}
            name=""
            id=""
            cols="30"
            rows="4"
            ref={ref}
            {...props}
            placeholder={placeholder}
          ></textarea>
        ) : (
          <input
            id={id}
            required={required}
            placeholder={placeholder}
            type={type}
            ref={ref}
            {...mainRegister(id)}
            className={`group placeholder:font-normal w-full py-3 px-3 rounded-sm border-[#D2D2D2] border-[1px] ${className}`}
            {...props}
          />
        )}
      </div>
    );
  }
);

export default Input;

Input.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOfType(["text"]),
  register: PropTypes.object,
};
